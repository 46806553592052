export const philosophyData = [
	{
		count: "01",
		title: "Adaptabilidad ",
		text: "los equipos de trabajo constituidos en la compañía reconocen que el medio de la tecnología es cambiante así como las necesidades de sus clientes.",
		classNames: "",
	},
	{
		count: "02",
		title: "Ética",
		text: "El manejo de datos sensibles de pacientes y sus empresas clientes tiene que garantizar unos niveles éticos y de profesionalismo tanto en la áreas de la salud como de la ingeniería.",
		classNames: "",
	},
	{
		count: "03",
		title: "Imaginación ",
		text: "El diseño de soluciones para los problemas de transformación de los datos en sus clientes requiere de un alto nivel de creatividad e imaginación. En Soluciones en Telesalud SAS - DASENSY, la imaginación permite la ideación de nuevos productos.",
		classNames: "reverse",
	},
	{
		count: "04",
		title: "Oportunidad",
		text: "Fundamentados en metodologías de trabajo ágiles, se desarrollan ambientes de trabajo de entregas cortas funcionales en forma oportuna para las necesidades de la empresa y de sus clientes.",
		classNames: "reverse",
	},
	{
		count: "05",
		title: "Unidad",
		text: "En la empresa el trabajo colaborativo es esencial, tanto triunfos como fracasos hacen parte del todo y no de la parte.",
		classNames: "reverse",
	},
];

import React from "react";
import PlansList from "../components/PlansList";
import PreviewTCuadros from "../components/PreviewTCuadros";

export default function Preview() {
	return (
		<main className='preview'>
			<PreviewTCuadros />
			{/* <section>
				<h1 className='plans__title'>
					Tenemos diferentes planes para tener <br></br> Datos con Sentido{" "}
				</h1>
				<h3 className='plans__subtitle'>Adquierelos según crezcas</h3>
				<PlansList />
			</section> */}
		</main>
	);
}

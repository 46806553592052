import React from "react";
import { useLocation } from "react-router-dom";
import loadImg from "../assets/icons/icononly_transparent_nobuffer.png";

export default function Loading({ msg }) {
	const location = useLocation();

	return (
		<div className='loading'>
			<h3>{msg}</h3>

			<img src={loadImg} alt='Icon Load' className='loading__icon'></img>
		</div>
	);
}

import React, { useContext, useRef } from "react";
import { MdOutlineFacebook } from "react-icons/md";
import { RiTwitterFill, RiLinkedinBoxFill, RiInstagramLine } from "react-icons/ri";
import { AppContext } from "../Context/AppContext";

export default function ModalUserSocial() {
	const { setOpenModalUser, setIsError, infoLogin,userSocialInfo} = useContext(AppContext);
	const form = useRef(null);

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(form.current);
		const data = {
			uid: infoLogin.uid,
			facebook: formData.get("facebook"),
			instagram: formData.get("instagram"),
			twitter: formData.get("twitter"),
			linkedin: formData.get("linkedin"),
		};
		console.log(data)
		userSocialInfo(data)
		setOpenModalUser(false);
	};

	return (
		<div>
			<h2 className='userInfo__modal__title'>Editar Redes Sociales</h2>
			<form
				className='userSocial__modal__form'
				id='userSocial__modal_form'
				onSubmit={handleSubmit}
				ref={form}>
				<div className='userSocial__modal__linkSocial'>
					<MdOutlineFacebook className='menu__social__iconPerfil facebook' />
					<input
						type='text'
						name='facebook'
						className='userInfo__modal__input socialLink'
						// value={github}
					/>
				</div>
				<div className='userSocial__modal__linkSocial'>
					<RiInstagramLine className='menu__social__iconPerfil instagram' />
					<input
						type='text'
						name='instagram'
						className='userInfo__modal__input socialLink'
						// value={github}
					/>
				</div>

				<div className='userSocial__modal__linkSocial'>
					<RiTwitterFill className='menu__social__iconPerfil twitter' />
					<input
						type='text'
						name='twitter'
						className='userInfo__modal__input socialLink'
						id='socialTwitter'
						// value={twitter}
					/>
				</div>

				<div className='userSocial__modal__linkSocial'>
					<RiLinkedinBoxFill className='menu__social__iconPerfil linkedin' />
					<input
						type='text'
						name='linkedin'
						className='userInfo__modal__input socialLink'
						id='socialLinkedin'
						// value={linkedIn}
					/>
				</div>

				<div className='userSocial__modal__w100 userSocial__modal__boxBtn'>
					<button
						type='submit'
						value='Guardar'
						className='button-primary'
						id='SocialBtnSave'>
						Guardar
					</button>
					<button
						className='button-secondary userInfo__modal__Btn'
						onClick={() => {
							setOpenModalUser(false);
							setIsError("");
						}}>
						Cancelar
					</button>
				</div>
			</form>
		</div>
	);
}

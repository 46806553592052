import React, { useContext, useEffect, useState } from "react";
import NavItem from "../components/navBar/NavItem";
import navLogoWhite from "../assets/icons/white_transparent__nobuffer.png";
import navlogo from "../assets/icons/fulllogo_transparent_nobuffer.png";
import NavIconSocial from "../components/navBar/NavIconSocial";
import { CgMenuGridO } from "react-icons/cg";
import { AppContext } from "../Context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";


export default function NavBar() {
	const { openMenu, setOpenMenu, setLogin } = useContext(AppContext);
	const path = ["/","/aboutus", "/ourplans", "/useracount"]
	window.addEventListener("scroll", () => {
		setOpenMenu(false);
	});
	const location = useLocation();
	const navigate = useNavigate()
	const [lightMode, setLightMode]= useState(false)

	useEffect(() => {
		if (path.includes(location.pathname)) {
			setLightMode(true)
		} else {
			setLightMode(false)
		}
		
	}, [location]);

	const handleClick = () => {
		navigate("/")
	}

	return (
		<nav className={`${!lightMode ? "menu-light":"menu"}`}>
			<div className='menu__logo'>
				<img src={`${!lightMode ? navlogo:navLogoWhite}`} alt='Dasensy SAS Logo' className='menu__logo__icon' onClick={handleClick}/>
			</div>
			<NavIconSocial />

			<div className='menu__right'>
				<CgMenuGridO className={`${!lightMode ? 'menu__iconDark' :'menu__icon'}`} onClick={() => {
					setOpenMenu(!openMenu)
				setLogin(false)}} />

				<NavItem open={openMenu} />
			</div>
		</nav>
	);
}

import React,  {useContext} from "react";
import BannerImg from "../assets/img/Banner.png";
import Slider from "../components/slides/Slider";
import { AppContext } from "../Context/AppContext";
import Contact from "../containers/Contact";

export default function Home() {
	const {useScrollToTop}= useContext(AppContext);
	useScrollToTop();
	return (
		<main className='home'>
			<section className='home__banner' id='inicio'>
				<img src={BannerImg} alt='home Banner Dasensy' className='home__banner__img'/>
			</section>

			<section className='home__services' id='services'>
				<div className='service__content'>
					<article className='service__info'>
						<Slider/>
					</article>
				</div>
			</section>
			
			<Contact />
		</main>
	);
}

import React, { useContext} from 'react'
import { RiUser5Line } from 'react-icons/ri';
import { AppContext } from '../Context/AppContext';
import {MdOutlinePhotoCamera} from 'react-icons/md'

export default function UserAvatar() {

  const {infoLogin, uploadAvatar} = useContext(AppContext)

  // POST /user/avatar

    const onChangeAvatar =(e)=>{
      // const av = e.target.files
      // const f = new FormData()
      // f.append('avatar', av[0])
      // uploadAvatar(av[0])
  }

    return (
        <div className="user__avatar">
          
          {infoLogin.userinfo ? <img src={infoLogin.userinfo?.photoURL} className="user__iconAvatar" alt="Avatar"/> : <RiUser5Line className="user__iconAvatar"/>}
          
        <label for="saveAvatar" className="user__uploadBox">
          <MdOutlinePhotoCamera className=" user__iconAvatarUpload"/>
            <span className="user__textAvatar">Cambiar Imagen</span>
          </label>
            <input
              type="file"
              name="avatar"
              id="saveAvatar"
              onChange={onChangeAvatar}
            />
        </div>
    )
}

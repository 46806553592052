import React from "react";
import FAQList from "../components/FAQList";
import FAQMenu from "../components/FAQMenu";
import backgroundImg from "../assets/icons/icononly_transparent_nobuffer.png";
import backgroundImgend from "../assets/img/AboutUs/icononly_aboutus.png";

export default function FAQContainer() {
	return (
		<section className='faq__content'>
			<img src={backgroundImg} className='faq__img__start' alt='background img' />
            <img src={backgroundImgend} className='faq__img__end' alt='background img' />
			<FAQMenu/>
			<FAQList />
		</section>
	);
}

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import dasensyLogo from "../assets/icons/fulllogo.png";
import { AppContext } from "../Context/AppContext";
import { RiCloseCircleLine } from "react-icons/ri";


export default function Welcome() {
	const { welcomeMsg, setWelcomeMsg, infoLogin, savelogConfig, logConfig } =
		useContext(AppContext);
	const [checked,setchecked]= useState(false)
	return (
		<article className='welcome'>
			<RiCloseCircleLine
				className='modal__closeIcon'
				onClick={() => setWelcomeMsg(!welcomeMsg)}
			/>
			<img src={dasensyLogo} alt='Dasensy Logo' className='welcome__img'></img>
			<h1 className='welcome__title'>Bienvenido/a a Dasensy</h1>
			<h1 className='welcome__title'>
				Nos alegra tenerte aquí {infoLogin.userinfo?.firstName}{" "}
			</h1>
			<p className='welcome__text'>Empecemos a darle vida y sentido a tus datos</p>
			<div className='welcome__controls'>
				<Link
					to='/'
					className='button-primary welcome__btn1'
					onClick={() => {
						setWelcomeMsg(!welcomeMsg)
						savelogConfig({...logConfig,  startMsg: !checked })
					}}>
					Continuar en Inicio
				</Link>
				<Link
					to='/useracount'
					className='button-secondary welcome__btn2'
					onClick={() => {
						setWelcomeMsg(!welcomeMsg)
						savelogConfig({...logConfig,  startMsg: !checked })
					}}>
					Ir a mi perfil
				</Link>
				<div className='input__checkbox'>
					<input
						type='checkbox'
						className='checkbox__input'
						id='checkbox'
						checked={checked}
						onClick={()=> setchecked(!checked)}
					/>
					<label className='checkbox__label'>No volver a mostrar</label>
				</div>
			</div>
		</article>
	);
}

import React, { useContext, useState, useEffect } from "react";
import { RiCloseCircleLine, RiEmotionHappyLine } from "react-icons/ri";
import { FiAlertCircle } from "react-icons/fi";
import cuadroT from "../assets/img/service.png";
import ModalCuadroT from "../containers/ModalCuadroT";
import { AppContext } from "../Context/AppContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { GoAlert } from "react-icons/go";
import ModalsPlan from "../containers/ModalsPlan";
import { ModalLog } from "../containers/ModalLog";

export default function CuadroTurnos() {
	const {
		setNextModal,
		nextModal,
		useScrollToTop,
		infoLogin,
		setLogin,
		login,
		isError,
		setIsError,
		saveInfoLogin,
		authlogsesion,
		errorLog
	} = useContext(AppContext);
	useScrollToTop();
	const [reminder, setReminder] = useState(false);
	const [error, setError] = useState(false);
	const navigate = useNavigate();
	const location = useLocation()

	useEffect(() => {
		setNextModal("")
		const userDoc = async () => {
			const docRef = doc(db, "dasUsers", `${infoLogin.uid}`);
			const docSnap = await getDoc(docRef);
			const { dasPlanCount, subscription } = docSnap.data();
			
			if (dasPlanCount === 0 && subscription === "Incrédulo") {
				setIsError("Lo sentimos tu plan actual no te permite crear más Datos con Sentido");
				navigate("/ourplans");
			} else if (dasPlanCount === 0){
				setError(true);
			}
		};
		userDoc();
	}, []);

	const handleAdquirir = async () => {
		const docRef = doc(db, "dasUsers", infoLogin.uid);
		await updateDoc(docRef, {
			dasPlanCount: 1,
		})
			.then((response) => {
				setError("");
				saveInfoLogin({
					...infoLogin,
					cuadroAdicional: true,
				});
				console.log("Cantidad de cuadros actualizada");
			})
			.catch((error) => console.log(error));
	};

	const handleClick = () => {
		const data = {
			uid: infoLogin.uid
		}
		authlogsesion(data)
	}

	return (
		<main className='CuadroTurnos'>
			<section className='tutorial'>
				<h2 className='tutorial__title'> Aprende a crear tu cuadro de turnos</h2>
				<iframe
					className='tutorial__video'
					src='https://www.youtube.com/embed/19Ibh5ml3Ic'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen></iframe>{" "}
			</section>

			<section className='getCuadro'>
				<h3 className='getCuadro__title'>Construimos tu cuadro de turnos</h3>
				<img src={cuadroT} alt='Gif Cuadro' className='getCuadro__gif'></img>
				<div className='getCuadro__info'>
					<p className='getCuadro__info__text'>
						A través de nuestro sistema de inteligencia artificial construiremos un cuadro
						de turnos según tus reglas y necesidades en minutos. Haz clic abajo para
						comenzar
					</p>
					<button
						className='button-primary getCuadro__info__btn'
						//TODO Activar cuando estemos en producción
						onClick={() => {
							if (!infoLogin.uid) {
								setLogin(!login);
								setReminder(!reminder);
							} else {
								handleClick()
							}
						}}>
						{/* Crear Cuadro */}
						Proximamente...
					</button>
				</div>

				{login && reminder && (
					<div className='reminder'>
						<div className='reminder__content'>
							<h3 className='reminder__title'>
								<FiAlertCircle className='reminder__alertIcon' /> Información
							</h3>
							<RiCloseCircleLine
								className='features__closeIcon'
								onClick={() => setReminder(!reminder)}
							/>
							<p className='reminder__text'>
								Por favor. logueate o registrate para crear tu cuadro de turnos
							</p>
							<p className='reminder__text'>
								Esto nos permite personalizar tus opciones, gracias!{" "}
								<RiEmotionHappyLine className='reminder__iconHappy' />
							</p>
						</div>
					</div>
				)}
			</section>
			{nextModal && <ModalCuadroT></ModalCuadroT>}
			{error && location.pathname === "/dasensycuadroturnos" && (
				<ModalsPlan>
					<div className='paymentError'>
						<GoAlert className='paymentError__Icon' />
						<p className='paymentError__text'>
							Recuerda, ya has consumido todos tus cuadros de turnos
						</p>
						<p className='paymentError__text'>¿Deseas adquirir un cuadro adicional?</p>
						<div className='btn-group'>
							<input
								type='submit'
								className='button form__btn'
								value='Adquirir'
								onClick={handleAdquirir}
							/>
							<input
								type='button'
								className='button form__btn form__btn--cancel'
								value='Cancelar'
								onClick={() => {
									navigate("/");
									setError("");
								}}
							/>
						</div>
					</div>
				</ModalsPlan>
			)}
			{errorLog && <ModalLog>
				<p className='reminder__text'>
					{errorLog}
				</p>
			</ModalLog>}
		</main>
	);
}

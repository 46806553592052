import React from "react";
import ReactDOM from "react-dom";

export default function ModalsPlan({ children }) {

	return ReactDOM.createPortal(
		<section className='modal'>
			<article className='modal__content'>

				{children}

			</article>
		</section>,
		document.getElementById("modals"),
	);
}

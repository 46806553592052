import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../assets/icons/fulllogo_transparent_nobuffer.png";
import FooterLogo2 from "../assets/icons/textonly_nobuffer.png";
import { MdOutlineFacebook } from "react-icons/md";
import {
	RiYoutubeLine,
	RiTwitterFill,
	RiLinkedinBoxFill,
	RiInstagramLine,
} from "react-icons/ri";
import { AppContext } from "../Context/AppContext";

import Politics from "./Politics";
import Terms from "./Terms";
import AvisoPrivacidad from "./AvisoPrivacidad";

export default function Footer() {
	const {  setOpenModal, setModal } = useContext(AppContext);
	

	let currentTime = new Date();
	let year = currentTime.getFullYear();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	window.onresize = resize;

	function resize() {
		setWindowWidth(window.innerWidth);
	}

	return (
		<footer className='footer'>
			<section className='footer__content'>
				<div className='footer__content__info'>
					{windowWidth > 728 ? (
						<h3 className='footer__info__title'>Dasensy SAS</h3>
					) : (
						<img src={FooterLogo2} alt='Dasensy SAS Logo' className='footer__logo' />
					)}

					<div
						
						className='footer__info__link'
						onClick={() => {
							setOpenModal(true);
							setModal(<AvisoPrivacidad />);
						}}>
						Aviso de privacidad
					</div>
					<div
						
						className='footer__info__link'
						onClick={() => {
							setOpenModal(true);
							setModal(<Politics />);
						}}>
						Politicas de uso
					</div>
					{/* <div
						
						className='footer__info__link'
						onClick={() => {
							setOpenModal(true);
							setModal(<Terms />);
						}}>
						Terminos de uso
					</div> */}
					<Link  to="/faq" className='footer__info__link'>
						Preguntas frecuentes
					</Link>
				</div>

				<div className='footer__content__social'>
					<h3 className='footer__social__title'>Nuestras Redes</h3>
					<div className='social__content'>
						<a
							rel='noreferrer'
							href='https://www.linkedin.com/company/dasensy-sas'
							target='_blank'
							className='social__link'>
							<RiLinkedinBoxFill className='social__icon linkedin' />
							<label className='social__link__label'>@Dasensy</label>
						</a>
						<a
							rel='noreferrer'
							href='https://web.facebook.com/profile.php?id=100076965574049'
							target='_blank'
							className='social__link'>
							<MdOutlineFacebook className='social__icon facebook' />
							<label className='social__link__label'>@Dasensy</label>
						</a>
						<a rel='noreferrer' href='https://twitter.com/dasensy/' className='social__link' target='_blank'>
							<RiTwitterFill className='social__icon twitter' />
							<label className='social__link__label'>@Dasensy</label>
						</a>
						<a rel='noreferrer' href='https://www.youtube.com/channel/UCxdkTXg-iLZrV0uvPk2VCuw' className='social__link' target='_blank'>
							<RiYoutubeLine className='social__icon youtube' />
							<label className='social__link__label'>@Dasensy</label>
						</a>
						<a
							rel='noreferrer'
							href='https://www.instagram.com/dasensy_sas/'
							target='_blank'
							className='social__link'>
							<RiInstagramLine className='social__icon instagram' />
							<label className='social__link__label'>@Dasensy_SAS</label>
						</a>
					</div>
				</div>

				<div className='footer__content__logo'>
					<img src={FooterLogo} alt='Dasensy SAS Logo' className='footer__logo__img' />
				</div>

				<div className='footer__content__copyright'>
					<h3 className='copyright__text'>
						{" "}
						&#169; {year} Todos los derechos reservados | Dasensy SAS
					</h3>
				</div>
			</section>
		</footer>
	);
}

import React, { useEffect, useState } from "react";
import {
	signInWithPopup,
	GoogleAuthProvider,
	sendPasswordResetEmail,
} from "firebase/auth";

import { auth, db } from "../firebase/firebaseConfig";
import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { useLocalStorage } from "../Hooks/useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { faqData } from "../assets/FAQData";

const provider = new GoogleAuthProvider();
export const AppContext = React.createContext();

function AppProvider(props) {
	/**
	 * Declaracion de variables
	 */
	const apiKey = process.env.REACT_APP_DASENSY_API_GOOGLESHEET;
	const idSheet = "1xWO_H5onKgfq6Trs78tToI69TkCTRnhIlPouee6kowA";
	const [TRM, setTRM] = useState([]);
	const { item: infoLogin, saveItem: saveInfoLogin } = useLocalStorage(
		"dasensySAS_kajdfklj3423jajsd#45-log",
		[],
	);
	const { item: logConfig, saveItem: savelogConfig } = useLocalStorage(
		"dasensySAS_kajdfklj3423jajsd#45-loggoogleLenght",
		[{ logbefore: false }],
	);
	const { item: tCuadros, saveItem: saveTCuadros } = useLocalStorage(
		"dasensySAS_$566asdasdjkj(/9877ADS!$%-Tcuadros",
		[],
	);
	const { item: authToken, saveItem: saveauthToken } = useLocalStorage(
		"dasensySAS_$566asdasdjkj(/9877ADS!$%-authToken",
		[],
	);
	const { item: datosFactura, saveItem: saveDatosFactura } = useLocalStorage(
		"dasensySAS_$566asdasdjkj(/9877ADS!$%-dasFacturacion",
		[],
	);
	const [openMenu, setOpenMenu] = useState(false);
	const [openSubmenu, setOpenSubmenu] = useState(false);
	const [submenu, setSubmenu] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [openModalUser, setOpenModalUser] = useState(false);
	const [modal, setModal] = useState(null);
	const [login, setLogin] = useState(false);
	const [resetPassword, setResetPassword] = useState(false);
	const [sendMail, setSendMail] = useState(false);
	const [openSignUp, setOpenSignUp] = useState(false);
	const [isError, setIsError] = useState("");
	const [error, setError] = useState(false);
	const [errorLog, setErrorLog] = useState("");
	const [welcomeMsg, setWelcomeMsg] = useState(false);
	const [userLogin, setUserLogin] = useState(null);
	const [nextModal, setNextModal] = useState("");
	const [editModal, setEditModal] = useState(false);
	const [redirect, setRedirect] = useState("");
	const [free, setFree] = useState(false);
	const [currentList, setCurrentList] = useState(faqData.flujoTrabajo);
	const useScrollToTop = () => {
		const location = useLocation();
		useEffect(() => {
			window.scrollTo({ top: 0 });
			// scroll to the top of the browser window when changing route
			// the window object is a normal DOM object and is safe to use in React.
		}, [location]);
	};

	/**
	 * Funcion de llamado al API authlogsesion del server side que verifica el estado del token del usuario
	 * @param {*} e
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const authlogsesion = async (userData) => {
		// const url = "http://127.0.0.1:5005/user/signup";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyloguser/user/authlogsesion";
		const access_dastoken = infoLogin.access_dasToken;
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			// console.log(data);
			setNextModal("");
			// setNextModal("caracteristicas");
		} else {
			// console.log(data)
			setErrorLog("Tu sesión ha caducado, por favor ingresa nuevamente");
			userLogOut()
		}
	};
	/**
	 * Funcion de llamado al API signup del server side que hace el registro de usuarios
	 * @param {*} e
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const userSignUp = async (e, userData, email) => {
		e.preventDefault();
		// const url = "http://127.0.0.1:5005/user/signup";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyloguser/user/signup";
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, success } = await response.json();
		if (success) {
			setOpenSignUp(!openSignUp);
			alert("Usuario creado exitosamente");
			return saveInfoLogin(data);
		} else {
			setIsError("Error registro no creado, intentalo nuevamente");
			setTimeout(() => {
				setIsError("");
			}, 5005);
		}
	};

	/**
	 * Funcion de llamado al API login del server side
	 * @param {*} e
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const userLogIn = async (e, userData) => {
		e.preventDefault();
		// const url = "http://127.0.0.1:5005/user/login";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyloguser/user/login";
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, success } = await response.json();
		if (success) {
			setLogin(!login);

			// userState(data);
			return saveInfoLogin(data);
		} else {
			setIsError(
				"No reconocemos el Usuario, intentalo nuevamente o registrate en nuestra plataforma",
			);
			setTimeout(() => {
				setIsError("");
			}, 5005);
		}
	};

	/**
	 * Funcion de llamado al API login del server side
	 * @param {*} e
	 * @returns {array} data (infouser)
	 */
	const [getToken, setGetToken] = useState(false);
	const [logdata, setLogData] = useState({});
	useEffect(() => {
		if (getToken === true) {
			// console.log("Generando token");
			getLogToken(logdata);
		}
	}, [getToken]);

	const userLogInGoogle = async (e, { checkTerms }) => {
		e.preventDefault();
		const terms = checkTerms;
		signInWithPopup(auth, provider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				// userState(user);
				const nombre = user.providerData[0].displayName;
				const firstName = nombre.slice(0, /(?:\s)/.exec(nombre).index);
				const lastName = nombre.slice(/(?:\s)/.exec(nombre).index + 1, nombre.length);
				const email = user.providerData[0].email;
				const admin = false;
				const sociales = {};
				const tipoDeCuadros = null;
				const ubicacion = null;
				const activo = true;
				const photoURL = user.providerData[0].photoURL;
				const checkTerms = terms;
				const subscription = "Incrédulo";
				const dasPlanCount = 1;

				if (!logConfig.logbefore) {
					setDoc(
						doc(db, "dasUsers", `${user.uid}`),
						{
							firstName,
							lastName,
							email,
							admin,
							sociales,
							tipoDeCuadros,
							ubicacion,
							activo,
							photoURL,
							checkTerms,
							subscription,
							dasPlanCount,
						},
						{ merge: true },
					);
					setLogData({
						uid: user.uid,
						subscription: subscription,
						admin: admin,
					});

					return (
						savelogConfig({ logbefore: true }),
						saveInfoLogin({
							userinfo: {
								firstName,
								lastName,
								email,
								sociales,
								tipoDeCuadros,
								ubicacion,
								activo,
								photoURL,
								checkTerms,
								subscription,
							},
							uid: user.uid,
							token: token,
						}),
						setLogin(!login),
						setGetToken(true)
					);
				} else {
					const docRef = doc(db, "dasUsers", `${user.uid}`);
					const userDoc = async () => {
						const docSnap = await getDoc(docRef);
						// console.log(docSnap.data())
						const {
							firstName,
							lastName,
							email,
							sociales,
							tipoDeCuadros,
							ubicacion,
							activo,
							photoURL,
							checkTerms,
							subscription,
							secondaryInfo,
							admin,
						} = docSnap.data();
						setLogData({
							uid: user.uid,
							subscription: subscription,
							admin: admin,
						});
						// console.log("Entre");

						return (
							saveInfoLogin({
								userinfo: {
									firstName,
									lastName,
									email,
									sociales,
									tipoDeCuadros,
									ubicacion,
									activo,
									photoURL,
									checkTerms,
									subscription,
									secondaryInfo,
								},
								uid: user.uid,
								token: token,
							}),
							setLogin(!login),
							setGetToken(true)
						);
					};
					userDoc();
				}
			})
			.catch((error) => {
				// Handle Errors here.
				const errorMessage = error.message;
				console.log(errorMessage);
				// ...
			});
	};

	/**
	 * Funcion para generar el token que validara el plan de subscripcion del usuario
	 * @param {*} userdata
	 * @returns  token de acceso
	 */
	const getLogToken = async (userData) => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyloguser/user/getlogtoken";
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, success } = await response.json();
		if (success) {
			saveInfoLogin({ ...infoLogin, access_dasToken: data });
			// console.log("Token Generado exitosamente");
			// console.log(data);
		} else {
			// console.log(data);
		}
	};

	/**
	 * Funcion para reestablecer la contraseña
	 * @param {*} e
	 * @returns {void} arreglo vacio
	 */
	const handleResetPassword = async (email) => {
		sendPasswordResetEmail(auth, email)
			.then(() => {
				// Password reset email sent!
				// ..
				setSendMail(!sendMail);
			})
			.catch((error) => {
				const errorMessage = error.message;
				console.log(errorMessage);
				setIsError("Lo sentimos no reconocemos tu correo, verificalo por favor");
				setTimeout(() => {
					setIsError("");
				}, 5000);
				// ..
			});
	};

	/**
	 * Funcion de llamado al API signout del server side
	 * @param {*} e
	 * @returns {void} arreglo vacio
	 */
	const userLogOut = async () => {
		// e.preventDefault();
		// const url = "http://127.0.0.1:5005/user/logout";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyloguser/user/logout";
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, success } = await response.json();
		if (success) {
			// userState(data);
			setGetToken(false);
			return (
				saveInfoLogin([]),
				savelogConfig({ ...logConfig, startMsg: true }),
				saveDatosFactura([]),
				saveTCuadros({
					...tCuadros,
					caracteristicas: {},
					flujoTrabajo: {},
					restricciones: {},
				}),
				saveFacturacion([]),
				saveauthToken([])
			);
		} else {
			setIsError(data.error?.errorMessage);
			setTimeout(() => {
				setIsError("");
			}, 5000);
		}
	};

	/**
	 * Peticion para consultar la bd dasNCuadros
	 * @param {*} uid
	 * @returns {void}
	 */
	const getCuadrosdeTurnos = async () => {
		// const url = "http://127.0.0.1:5006/ntcuadros";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensytcuadros/nTCuadros";
		const userData = {
			uid: infoLogin.uid,
			// docId: `0${month}${year}`,
			docId: "012022",
			subs: infoLogin.userinfo?.subscription,
		};
		const access_dastoken = infoLogin.access_dasToken;
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log(data);
		} else {
			console.log(data);
		}
	};

	/**
	 * Funcion de llamado al API dasCaracteristicas y que hace el ingreso de datos a la base de datos
	 * @param {*} e
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const addFeatures = async (e, userData) => {
		e.preventDefault();
		// const url = "http://127.0.0.1:5006/dasCaracteristicas";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensytcuadros/dasCaracteristicas";
		const access_dastoken = infoLogin.access_dasToken;

		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log("RefDoc dasTeam");
			console.log(data);
		} else {
			console.log(data.err);
		}
	};

	/**
	 * Funcion de llamado al API dasFlow y que hace el ingreso de datos a la base de datos
	 * @param {*} e
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const addWorkFlow = async (e, userData) => {
		e.preventDefault();
		// const url = "http://127.0.0.1:5006/dasFlow";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensytcuadros/dasFlow";
		const access_dastoken = infoLogin.access_dasToken;
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log("RefDoc dasFlow");
			console.log(data);
		} else {
			console.log(data);
		}
	};

	/**
	 * Funcion de llamado al API dasRestricciones y que hace el ingreso de datos a la base de datos
	 * @param {*} e
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const addRestrictions = async (e, userData) => {
		e.preventDefault();
		// const url = "http://127.0.0.1:5006/dasRestrictions";
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensytcuadros/dasRestrictions";
		const access_dastoken = infoLogin.access_dasToken;
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log("RefDoc dasRestriction");
			console.log(data);
		} else {
			console.log(data);
		}
	};

	/**
	 * Funcion de llamado al API dasCreate,
	 * @param {*} e
	 * @param {array}
	 * @returns {array} data
	 */
	const addDasCreate = async (e, userData) => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensytcuadros/dasCreate";
		const access_dastoken = infoLogin.access_dasToken;
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log("RefDoc dasCreate");
			console.log(data);
			saveDatosFactura({
				...datosFactura,
				idDasCreate: data,
			});
		} else {
			console.log(data);
		}
	};

	/**
	 *
	 * Funcion de envio de correos con el cuadro de turnos
	 * @param {array} {firstName, email, file, subs}
	 * @returns {array} data (infouser)
	 */
	const sendMailCuadro = async (userData) => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensytcuadros/dasSendMailCuadroTurnos";
		const access_dastoken = infoLogin.access_dasToken;
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log(data);
		} else {
			console.log(data);
		}
	};

	/**
	 *
	 * Funcion de llamado al API dasgetAuthToken, retorna el token de verificación para hacer uso de api que requieran mayor seguridad
	 * @param {*} e
	 * @param {array} user_Id
	 * @returns {array} data (infouser)
	 */
	const getAuthToken = async () => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyfacturacion/dasFacturacion/generateauthtoken";
		const userData = {
			uid: infoLogin.uid,
		};
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, success } = await response.json();
		if (success) {
			saveauthToken(data);
			// console.log(data)
		} else {
			console.log(data);
		}
	};

	const [openFactura, setOpenFactura] = useState(false);
	/**
	 * Funcion de llamado al API dasFacturación y que hace el ingreso de datos de facturación a la base de datos a la base de datos
	 * @param {*} e
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const getDatosFacturación = async (userData, tokenAuth) => {
		// const url = "http://127.0.0.1:5007/dasFacturacion/wompidatareference";

		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyfacturacion/dasFacturacion/wompidatareference";
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${tokenAuth}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			saveInfoLogin({
				...infoLogin,
				datosFactura: data,
				planpago: { subscription: userData.subscription, redirect: userData.redirect },
			});
		} else {
			console.log(data);
		}
	};

	const [estadoPago, setEstadoPago] = useState(true);

	const getDatosPagos = async (id) => {
		const url = `https://sandbox.wompi.co/v1/transactions/${id}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, meta } = await response.json();
		if (data.status === "APPROVED") {
			saveDatosFactura({
				...datosFactura,
				numeroFactura: data.id,
				timestamp: data.created_at,
				valorTotal: data.amount_in_cents / 100,
				referencia: data.reference,
				descripcion: "Pago a Soluciones en telesalud S.A.S., ref: " + data.reference,
				tipoMoneda: data.currency,
				medioPago: data.payment_method_type,
				formaPago: "Wompi",
				confirmacionPasarela: data.status,
				iva: data.taxes[0].amount_in_cents / 100,
			});
			saveFacturacion(datosFactura);
			setEstadoPago(true);

			//Actualiza el estado de pago en el documento correspondiente en la colecction dasCreate en BD
			if (infoLogin.planpago?.redirect === "dasensyusercuadroturnos") {
				const docRef = doc(db, "dasCreate", datosFactura.idDasCreate);
				await updateDoc(docRef, {
					pagado: true,
				})
					.then((response) => {
						console.log("dasCreate actualizado");
					})
					.catch((error) => console.log(error));
			}
			if (infoLogin.planpago?.redirect === "useracount") {
				const docRef = doc(db, "dasUsers", infoLogin.uid);
				const docSnap = await getDoc(docRef);
				const { dasPlanCount } = docSnap.data();
				await updateDoc(docRef, {
					subscription: infoLogin.planpago?.subscription,
					dasPlanCount:
						infoLogin.planpago?.subscription === "Urgencia"
							? 1
							: infoLogin.planpago?.subscription === "Tiempo libre"
							? dasPlanCount + 12
							: infoLogin.planpago?.subscription === "Gestión múltiple" &&
							  dasPlanCount + 36,
				})
					.then((response) => {
						userDocUpdate();
					})
					.catch((error) => console.log(error));
			}
			// console.log(data);
		} else {
			setIsError(
				`Lo sentimos, ha ocurrido un error con el pago, por favor intentalo nuevamente`,
			);
			setEstadoPago(false);
			setRedirect("/dasensycuadroturnospreview");
		}
	};

	const userDocUpdate = async () => {
		const docRefuser = doc(db, "dasUsers", `${infoLogin.uid}`);
		const docSnap = await getDoc(docRefuser);
		const {
			firstName,
			lastName,
			email,
			sociales,
			tipoDeCuadros,
			ubicacion,
			activo,
			photoURL,
			checkTerms,
			subscription,
			secondaryInfo,
			admin,
		} = docSnap.data();
		setLogData({
			uid: infoLogin.uid,
			subscription: subscription,
			admin: admin,
		});
		saveInfoLogin({
			...infoLogin,
			userinfo: {
				firstName,
				lastName,
				email,
				sociales,
				tipoDeCuadros,
				ubicacion,
				activo,
				photoURL,
				checkTerms,
				subscription,
				secondaryInfo,
			},
		});
		setGetToken(true);
		console.log("Perfil actualizado");
	};

	/**
	 * Funcion para actualizar la cuenta de creacion disponible de cuadro de turnos
	 * @param {array} userData
	 * @returns {array} data (infouser)
	 */
	const updateDasPlanCount = async (estado) => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensytcuadros/updatePlanCount";
		const access_dastoken = infoLogin.access_dasToken;
		const userData = {
			uid: infoLogin.uid,
			subs: infoLogin.userinfo?.subscription,
			estado,
		};
		const response = await fetch(url, {
			method: "PUT",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${access_dastoken}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log("Cuenta de plan actualizada");
		} else {
			console.log(data);
		}
	};

	const saveFacturacion = async (dataFacturacion) => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyfacturacion/dasFacturacion/savedasfacturacion";
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(dataFacturacion),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${authToken.access_token}`,
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log("Factura generada");
			// console.log(data);
		} else {
			// console.log(data);
		}
	};

	/* ------------ Funcion para hacer la conversion de Dolares a COP ------------- */
	const handleTRM = async () => {
		const url = `https://sheets.googleapis.com/v4/spreadsheets/${idSheet}/values:batchGet?ranges=A1&majorDimension=ROWS&key=${apiKey}`;
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const { valueRanges } = await response.json();
			setTRM(valueRanges[0].values[0]);
		} catch (error) {
			console.log(error);
		}
	};
	const [valorPagar, setValorPagar] = useState(0);

	/* ------------ Funcion para subir el avatar ------------- */

	const uploadAvatar = async (avatar) => {
		console.log("uploadAvatar");
		console.log(avatar);
		// saveInfoLogin({ ...infoLogin, avatar });

		// const url = "http://127.0.0.1:5000/user/avatar";
		// const token = Login.data?.token;
		// const response = await fetch(url, {
		// 	method: "POST",
		// 	body: avatar,
		// 	headers: {
		// 		// "Content-Type": "multipart/form-data",
		// 		Authorization: `Bearer ${token}`,
		// 	},
		// });
		// const { data: users, success } = await response.json();
		// if (success) {
		// 	return saveInfoUser(users);
		// } else {
		// 	return setError("No se pudo Cargar la imagen");
		// }
	};

	const userSecondaryInfo = async (userData) => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyloguser/user/secondaryInfo";
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log(data);
			return saveInfoLogin({
				...infoLogin,
				userinfo: { ...infoLogin.userinfo, secondaryInfo: userData },
			});
		} else {
			console.log(data);
		}
	};
	const userSocialInfo = async (userData) => {
		const url =
			"https://us-central1-dasensy-94a04.cloudfunctions.net/dasensyloguser/user/socialInfo";
		const response = await fetch(url, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				"Content-Type": "application/json",
			},
		});
		const { data, success } = await response.json();
		if (success) {
			console.log(data);
			return saveInfoLogin({
				...infoLogin,
				userinfo: { ...infoLogin.userinfo, sociales: userData },
			});
		} else {
			console.log(data);
		}
	};

	return (
		<AppContext.Provider
			value={{
				useScrollToTop,
				openMenu,
				setOpenMenu,
				openSubmenu,
				setOpenSubmenu,
				submenu,
				setSubmenu,
				openModal,
				setOpenModal,
				modal,
				setModal,
				login,
				setLogin,
				userSignUp,
				openSignUp,
				setOpenSignUp,
				isError,
				setIsError,
				userLogIn,
				handleResetPassword,
				resetPassword,
				setResetPassword,
				sendMail,
				setSendMail,
				userLogOut,
				userLogin,
				setUserLogin,
				userLogInGoogle,
				infoLogin,
				saveInfoLogin,
				logConfig,
				savelogConfig,
				welcomeMsg,
				setWelcomeMsg,
				getCuadrosdeTurnos,
				nextModal,
				setNextModal,
				tCuadros,
				saveTCuadros,
				addFeatures,
				addWorkFlow,
				addRestrictions,
				addDasCreate,
				editModal,
				setEditModal,
				getAuthToken,
				getDatosFacturación,
				openFactura,
				setOpenFactura,
				authToken,
				getDatosPagos,
				datosFactura,
				saveDatosFactura,
				estadoPago,
				handleTRM,
				TRM,
				setTRM,
				valorPagar,
				setValorPagar,
				uploadAvatar,
				openModalUser,
				setOpenModalUser,
				userSecondaryInfo,
				userSocialInfo,
				sendMailCuadro,
				error,
				setError,
				free,
				setFree,
				redirect,
				setRedirect,
				updateDasPlanCount,
				currentList,
				setCurrentList,
				errorLog,
				setErrorLog,
				authlogsesion,
			}}>
			{props.children}
		</AppContext.Provider>
	);
}

export { AppProvider };

import React, { useContext, useRef } from "react";
import { AppContext } from "../Context/AppContext";
import { BiHappyBeaming } from "react-icons/bi";

export default function ResetPassword() {
	const {
		isError,
		resetPassword,
		setResetPassword,
		handleResetPassword,
		sendMail,
		setSendMail,
	} = useContext(AppContext);

	const form = useRef(null);
	const handleSubmit = (e) => {
		const formData = new FormData(form.current);
		const email = formData.get("email");
		console.log(email);
		e.preventDefault();
		handleResetPassword(email);
	};
	return (
		<>
			<div className='modalIngreso modalResetPassword' id='modalIngreso'>
				<form
					className='modalIngreso__form modalResetPassword__form'
					onSubmit={handleSubmit}
					ref={form}>
					<div className='form__input-box'>
						<label className='form__label'>
							<i className='form__icon fas fa-user '></i>
							Ingresa tu Correo
						</label>
						<input type='text' className='form__input' required id='email' name='email' />
					</div>
					{isError !== "" && <div className='msg__error'>{isError}</div>}
					<div className='btn-group reset__btn-group'>
						<input
							type='submit'
							className='button form__btn reset__btn'
							value='Recuperar contraseña'
						/>
						<input
							type='button'
							className='button form__btn form__btn--cancel'
							value='Cancelar'
							onClick={() => setResetPassword(!resetPassword)}
						/>
					</div>
				</form>

				{sendMail && (
					<div className='resetSuccess'>
						<div className='resetSuccess__content'>
							<BiHappyBeaming className='resetSuccess__icon' />
							<p className='resetSuccess__text'>
								Hemos enviado un correo de recuperacion a tu email.
							</p>
							<p className='resetSuccess__text'>
								Recuerda verificar también en tu carpeta de correos no deseados o bandeja
								Spam.
							</p>
							<button
								className='button-primary'
								onClick={() => {
									setSendMail(!sendMail);
									setResetPassword(!resetPassword);
								}}>
								Aceptar
							</button>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

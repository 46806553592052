import React, { useContext, useEffect, useState } from "react";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import Loading from "./Loading";

export default function DatosTCuadro() {
	const {
		setNextModal,
		tCuadros,
		addFeatures,
		addWorkFlow,
		addRestrictions,
		addDasCreate,
		editModal,
		setEditModal,
		saveTCuadros,
		getAuthToken,
		infoLogin,
		errorLog,
		userLogOut
	} = useContext(AppContext);
	const navigate = useNavigate();
	const hourContract = tCuadros.caracteristicas?.sameHours
		? Object.assign(tCuadros.caracteristicas?.hoursContract.horasMes).toString()
		: Object.values(tCuadros.caracteristicas?.hoursContract);

	const [fechas, setFechas] = useState([]);
	const [Cfechas, setCFechas] = useState([]);
	const [anyT, setAnyT] = useState([]);
	const [horasM, sethorasM] = useState([]);
	const [nColab, setNColab] = useState([]);
	const [onlyA, setOnlyA] = useState([]);
	const [onlyP, setOnlyP] = useState([]);
	const [onlyN, setOnlyN] = useState([]);
	const [link, setLink] = useState("/dasensycuadroturnospreview");

	const [load, setLoad] = useState(false);
	const [message, setMessage] = useState("");

	useEffect(() => {
		const horasMes = [];
		const colabHorasMes = [];
		if (tCuadros.caracteristicas?.sameHours) {
			setFechas(hourContract);
		} else {
			for (var i = 0; i < hourContract.length; i++) {
				anyT.push(hourContract[i]?.anyTime);
				horasM.push(hourContract[i]?.horasMes);
				nColab.push(hourContract[i]?.nColaboradores);
				onlyA.push(hourContract[i]?.onlyAM);
				onlyP.push(hourContract[i]?.onlyPM);
				onlyN.push(hourContract[i]?.onlyNight);
				// i % 2 === 0
				// 	// ? horasMes.push(hourContract[i])
				// 	// : colabHorasMes.push(hourContract[i]);
			}
		}
		setFechas(horasMes);
		setCFechas(colabHorasMes);
	}, []);

	var options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

	const fecha = tCuadros.restricciones?.fechaTCuadro.startDate;
	const fecha2 = tCuadros.restricciones?.fechaTCuadro.endDate;
	const date = new Date(fecha);
	const date2 = new Date(fecha2);
	const fechaAssign = tCuadros.restricciones?.assignDates;
	const lengthAssing = Object.keys(tCuadros.restricciones?.assignDates);
	const fechaRestrict = tCuadros.restricciones?.restrictedDates;
	const lengthRestrict = Object.keys(tCuadros.restricciones?.restrictedDates);

	const handleEdit = (modal) => {
		setEditModal(!editModal);
		setNextModal(modal);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Datos enviados");
		const idCuadro = Date.now();
		const date = new Date(idCuadro);
		const fecha = date.toISOString().slice(0, 7).replace("-", "");
		const dataC = tCuadros.caracteristicas;
		const dataF = tCuadros.flujoTrabajo;
		const dataR = tCuadros.restricciones;
		const carpeta =
			fecha + tCuadros.caracteristicas?.idCuadro + tCuadros.caracteristicas?.nombreEquipo;
		const ai = Date.parse(tCuadros.restricciones?.fechaTCuadro?.startDate);
		const aai = new Date(ai);
		const startYear = aai.getFullYear();
		const startMonth = parseInt(aai.toISOString().slice(5, 7));
		const starDay = aai.getDate();
		const af = Date.parse(tCuadros.restricciones?.fechaTCuadro?.endDate);
		const aaf = new Date(af);
		const endYear = aaf.getFullYear();
		const endMonth = parseInt(aaf.toISOString().slice(5, 7));
		const endDay = aaf.getDate();
		if (
			infoLogin.userinfo?.subscription !== "Incrédulo" ||
			infoLogin.userinfo?.subscription !== "Urgencia"
		) {
			var datadC = {
				uid: tCuadros.caracteristicas?.uid,
				createdAt: tCuadros.caracteristicas?.createdAt,
				nombreEquipo: tCuadros.caracteristicas?.nombreEquipo,
				idCuadro: tCuadros.caracteristicas?.idCuadro,
				carpeta,
				startYear,
				endYear,
				starDay,
				endDay,
				startMonth,
				endMonth,
				subs: infoLogin.userinfo?.subscription,
				pay: false,
				colaboradores: tCuadros.caracteristicas?.total,
				sameHours: tCuadros.caracteristicas?.sameHours,
			};
		} else {
			datadC = {
				uid: tCuadros.caracteristicas?.uid,
				createdAt: tCuadros.caracteristicas?.createdAt,
				nombreEquipo: tCuadros.caracteristicas?.nombreEquipo,
				idCuadro: tCuadros.caracteristicas?.idCuadro,
				carpeta,
				startYear,
				endYear,
				starDay,
				endDay,
				startMonth,
				endMonth,
				subs: infoLogin.userinfo?.subscription,
				pay: true,
				colaboradores: tCuadros.caracteristicas?.total,
				sameHours: tCuadros.caracteristicas?.sameHours,
			};
		}
		saveTCuadros({
			...tCuadros,
			dasCreate: datadC,
		});
		addFeatures(e, dataC);
		addWorkFlow(e, dataF);
		addRestrictions(e, dataR);
		setTimeout(() => {
			addDasCreate(e, datadC);
		}, 2000);

		getAuthToken();
		setMessage("Estamos creando Datos con Sentido");
		setLoad(true);
		setTimeout(() => {
			setLoad(false);
			setMessage("");
			setNextModal("");
			setEditModal(false);
			// if (
			// 	infoLogin.userinfo.subscription !== "Incrédulo" &&
			// 	infoLogin.userinfo.subscription !== "Urgencia"
			// ) {
			// 	navigate("/dasensyusercuadroturnos");
			// } else {
			// }
			navigate(link);

		}, 8000);
	};

	useEffect(() => {
		if (errorLog !== "") {
			userLogOut();
		}
	}, [errorLog]);

	return (
		<>
			<form className='confirm' onSubmit={handleSubmit}>
				<h2 className='confirm__title'>
					Estos son los datos que ingresaste
					<RiCloseCircleLine
						className='confirm__closeIcon'
						onClick={() => setNextModal("")}
					/>
				</h2>

				<section className='confirm__caract'>
					<section className='confirm__dateTCuadro'>
						<h1 className='confirm__subtitle'>
							Fecha Cuadro de Turnos
							<RiEdit2Fill
								className='editIcon'
								onClick={() => handleEdit("restricciones")}
							/>
						</h1>

						<label className='confirm__label'>
							<strong>Fecha inicio:</strong>
							{` ${date.toLocaleDateString("es-ES", options)}`}
						</label>
						<label className='confirm__label'>
							<strong>Fecha Fin:</strong>
							{` ${date2.toLocaleDateString("es-ES", options)}`}
						</label>
					</section>

					<h1 className='confirm__subtitle'>
						Caracteristicas
						<RiEdit2Fill
							className='editIcon'
							onClick={() => handleEdit("caracteristicas")}
						/>
					</h1>
					<label className='confirm__label'>
						<strong>Colaboradores:</strong>
						{` ${tCuadros.caracteristicas.total}`}
					</label>
					<label className='confirm__label'>
						<strong>Contrato de horas iguales:</strong>
						{` ${tCuadros.caracteristicas.sameHours ? "Si" : "No"}`}
					</label>

					{tCuadros.caracteristicas.sameHours === true ? (
						<>
							<div className='horasContrato__info'>
								<strong>Horas Mes: {` `}</strong>
								<span>{hourContract}</span>
							</div>
							<label className='confirm__label'>
								<strong>Colaboradores en jornada completa:</strong>
								{` ${tCuadros.caracteristicas.anyTime}`}
							</label>
							<label className='confirm__label'>
								<strong>Colaboradores en turno mañana:</strong>
								{` ${tCuadros.caracteristicas.onlyAM}`}
							</label>
							<label className='confirm__label'>
								<strong>Colaboradores en turno tarde:</strong>
								{` ${tCuadros.caracteristicas.onlyPM}`}
							</label>
							<label className='confirm__label'>
								<strong>Colaboradores en turno noche:</strong>
								{` ${tCuadros.caracteristicas.onlyNight}`}
							</label>
						</>
					) : (
						anyT.map((item, index) => {
							return (
								<>
									<h3 className='confirm__flow__infoTitle'>Contrato{`${index + 1}`}</h3>
									<div className='confirm__label'>
										<strong>Horas Mes: {` `}</strong>
										<span>{horasM[index]}</span>
									</div>
									<label className='confirm__label'>
										<strong>Número Colaboradores:</strong>
										{` ${nColab[index]}`}
									</label>
									<label className='confirm__label'>
										<strong>Colaboradores en jornada completa:</strong>
										{` ${anyT[index]}`}
									</label>
									<label className='confirm__label'>
										<strong>Colaboradores en turno mañana:</strong>
										{` ${onlyA[index]}`}
									</label>
									<label className='confirm__label'>
										<strong>Colaboradores en turno tarde:</strong>
										{` ${onlyP[index]}`}
									</label>
									<label className='confirm__label'>
										<strong>Colaboradores en turno noche:</strong>
										{` ${onlyN[index]}`}
									</label>
								</>
							);
						})
					)}
					{/* 
					<label className='confirm__label'>
						<strong>Colaboradores en turno mañana:</strong>
						{` ${tCuadros.caracteristicas.onlyAM}`}
					</label>
					<label className='confirm__label'>
						<strong>Colaboradores en turno tarde:</strong>
						{` ${tCuadros.caracteristicas.onlyPM}`}
					</label>
					<label className='confirm__label'>
						<strong>Colaboradores en turno noche:</strong>
						{` ${tCuadros.caracteristicas.onlyNight}`}
					</label>
					<label className='confirm__label horasContrato'>
						<strong>Horas Contratadas:</strong>
						{!tCuadros.caracteristicas.sameHours ? (
							fechas.map((item, index) => {
								return (
									<>
										<div className='horasContrato__info contrato' key={index}>
											<strong>
												Horas Mes {` C${index + 1}`} : {` `}
											</strong>
											<span>{item}</span>
										</div>
										<div className='horasContrato__info ' key={`C${index}`}>
											<strong>No. Colaboradores: {` `}</strong>
											<span>{Cfechas[index]}</span>
										</div>
									</>
								);
							})
						) : (
							<div className='horasContrato__info'>
								<strong>Horas Mes: {` `}</strong>
								<span>{hourContract}</span>
							</div>
						)}
					</label> 
					*/}
				</section>
				<section className='confirm__flow'>
					<h1 className='confirm__subtitle'>
						Flujo de trabajo
						<RiEdit2Fill className='editIcon' onClick={() => handleEdit("flujo")} />
					</h1>
					<h3 className='confirm__flow__infoTitle'>En días habiles</h3>
					<div className='confirm__flow__content'>
						<label className='confirm__label'>
							<strong>Colaboradores en Corrido: </strong>
							{` ${tCuadros.flujoTrabajo.numberCorridosHabil}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en tiempo completo: </strong>
							{` ${tCuadros.flujoTrabajo.numberFullTimeHabil}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno mañana: </strong>
							{` ${tCuadros.flujoTrabajo.numberMananaHabil}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno tarde: </strong>
							{` ${tCuadros.flujoTrabajo.numberTardeHabil}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno noche: </strong>
							{` ${tCuadros.flujoTrabajo.numberNocheHabil}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en medio tiempo: </strong>
							{` ${tCuadros.flujoTrabajo.numberPartTimeHabil}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno de 10 horas: </strong>
							{` ${tCuadros.flujoTrabajo.numberTenHourHabil}`}
						</label>
					</div>
					<h3 className='confirm__flow__infoTitle'>En días Fines de semana</h3>
					<div className='confirm__flow__content'>
						<label className='confirm__label'>
							<strong>Colaboradores en Corrido: </strong>
							{` ${tCuadros.flujoTrabajo.numberCorridosHoliday}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en tiempo completo: </strong>
							{` ${tCuadros.flujoTrabajo.numberFullTimeHoliday}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno mañana: </strong>
							{` ${tCuadros.flujoTrabajo.numberMananaHoliday}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno tarde: </strong>
							{` ${tCuadros.flujoTrabajo.numberTardeHoliday}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno noche: </strong>
							{` ${tCuadros.flujoTrabajo.numberNocheHoliday}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en medio tiempo: </strong>
							{` ${tCuadros.flujoTrabajo.numberPartTimeHoliday}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores en turno de 10 horas: </strong>
							{` ${tCuadros.flujoTrabajo.numberTenHourHoliday}`}
						</label>
						<label className='confirm__label'>
							<strong>Colaboradores que deben estar disponibles: </strong>
							{` ${tCuadros.flujoTrabajo.numberOnCallHoliday}`}
						</label>
					</div>
				</section>
				<h1 className='confirm__subtitle'>
					Restricciones
					<RiEdit2Fill className='editIcon' onClick={() => handleEdit("restricciones")} />
				</h1>
				{infoLogin.userinfo?.subscription !== "Incrédulo" && (
					<>
						<section className='confirm__restrict'>
							<div>
								<h3 className='confirm__flow__infoTitle'>
									Asignar estas fechas (Programar fechas)
								</h3>
								{lengthAssing.map((item, index) => {
									let foo = "selection" + (index + 1);
									const date1 = new Date(fechaAssign[foo].startDate);
									const date2 = new Date(fechaAssign[foo].endDate);
									return (
										<label className='confirm__label fechasRestricted' key={index}>
											<span>Colaborador A{index + 1}</span>
											<span>
												<strong>Fecha inicio:</strong> {date1.toDateString()}
											</span>
											<span>
												<strong>Fecha fin:</strong> {date2.toDateString()}
											</span>
										</label>
									);
								})}
							</div>
							<div>
								<h3 className='confirm__flow__infoTitle'>
									Respetar estas fechas (Restringir fechas)
								</h3>

								{lengthRestrict.map((item, index) => {
									let foo = "selection" + (index + 1);
									const date1 = new Date(fechaRestrict[foo].startDate);
									const date2 = new Date(fechaRestrict[foo].endDate);
									return (
										<label className='confirm__label fechasRestricted' key={index}>
											<span>Colaborador R{index + 1}</span>
											<span>
												<strong>Fecha inicio:</strong> {date1.toDateString()}
											</span>
											<span>
												<strong>Fecha fin:</strong> {date2.toDateString()}
											</span>
										</label>
									);
								})}
							</div>
						</section>
					</>
				)}

				<h3 className='confirm__flow__infoTitle'>Restricciones Generales</h3>
				<div className='confirm__flow__content'>
					<label className='confirm__label'>
						<strong>Programar dos fines de semana consecutivos </strong>
						{` ${tCuadros.restricciones?.restrictweekend ? "Si" : "No"}`}
					</label>
					<label className='confirm__label'>
						<strong>Limite de turnos nocturnos a la semana por colaborador </strong>
						{` ${tCuadros.restricciones?.restrictNightshift ? "Si" : "No"}`}
					</label>
					<label className='confirm__label'>
						<strong>Limite de horas a la semana por colaborador </strong>
						{` ${tCuadros.restricciones?.restricthours ? "Si" : "No"}`}
					</label>
					<label className='confirm__label'>
						<strong>Limite de turnos de 12 horas diurnas a la semana </strong>
						{` ${tCuadros.restricciones?.restrictcorridos ? "Si" : "No"}`}
					</label>
				</div>
				<div className='confirm__btn'>
					<button className='button-primary'>Enviar Datos</button>
				</div>
			</form>
			{load && <Loading msg={message }/>}
		</>
	);
}

import React from "react";
import { BsTable } from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Link } from "react-router-dom";

export default function ServiceList() {
	return (
		<>
			<li className='submenu__list'>
				<Link to='/dasensycuadroturnos' className='submenu__list__link'>
					<BsTable className='submenu__icon' />
					Cuadros de Turno
				</Link>
			</li>
			<li className='submenu__list'>
				<a href="https://forms.gle/ZLSCzoXxnbks6mRB6" target="_blank" rel="noreferrer"  className='submenu__list__link'>
					<GiTeacher className='submenu__icon' />
                        Consultorias
				</a>
			</li>
			<li className='submenu__list'>
				<a href="https://forms.gle/ZLSCzoXxnbks6mRB6" target="_blank" rel="noreferrer" className='submenu__list__link'>
					<HiOutlineDocumentReport className='submenu__icon' />
					Ciencia de datos
				</a>
			</li>
			
		</>
	);
}

import React, {useState, useEffect} from "react";
import { MdOutlineFacebook } from "react-icons/md";
import {
	RiYoutubeLine,
	RiTwitterFill,
	RiLinkedinBoxFill,
	RiInstagramLine,
} from "react-icons/ri";
import { useLocation } from "react-router-dom";

export default function NavIconSocial() {

	const location = useLocation();
	const path = ["/","/aboutus", "/ourplans", "/useracount"]
	const [Mode, setMode]= useState("menu__social__icon")

	useEffect(() => {
		if (path.includes(location.pathname)) {
			setMode("menu__social__icon")
		} else {
			setMode("menu__social__iconDark")
		}
		
	}, [location]);

	return (
		<div className='menu__social__content'>
			<a
				rel='noreferrer'
				href='https://www.linkedin.com/company/dasensy-sas'
				target='_blank'
				className={'menu__social__link'}>
				<RiLinkedinBoxFill className={`${Mode} linkedin`}/>
			</a>
			<a
				rel='noreferrer'
				href='https://web.facebook.com/profile.php?id=100076965574049'
				target='_blank'
				className={'menu__social__link'}>
				<MdOutlineFacebook className={`${Mode} facebook`}/>
			</a>
			<a rel='noreferrer' href='https://www.twitter.com/dasensy/' className={'menu__social__link'} target='_blank'>
				<RiTwitterFill className={`${Mode} twitter`}/>
			</a>
			<a rel='noreferrer' href='https://www.youtube.com/channel/UCxdkTXg-iLZrV0uvPk2VCuw' className={'menu__social__link'} target='_blank'>
				<RiYoutubeLine className={`${Mode} youtube`}/>
			</a>
			<a
				rel='noreferrer'
				href='https://www.instagram.com/dasensy_sas/'
				target='_blank'
				className={'menu__social__link'}>
				<RiInstagramLine className={`${Mode} instagram`}/>
			</a>
		</div>
	);
}

import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsPersonBadge } from "react-icons/bs";
import { RiHomeSmile2Line } from "react-icons/ri";
import {
	MdOutlineAttachMoney,
	MdOutlineSupportAgent,
	MdOutlineRoomService,
} from "react-icons/md";
import { scroller, animateScroll as scroll } from "react-scroll";
import { AppContext } from "../../Context/AppContext";
import Submenu from "./Submenu";

/*-----------Funcion smooth scroll ------------------*/
const scrollType = {
	duration: 400,
	delay: 20,
	smooth: true,
	offset: -135,
};
const onClickDown = (id) => {
	scroller.scrollTo(id, scrollType);
};
const onClickUp = () => {
	scroll.scrollToTop();
};

export default function NavList() {
	const { setOpenMenu, setSubmenu, submenu } = useContext(AppContext);

	const location = useLocation();
	const navigate = useNavigate();

	const path = ["/", "/aboutus", "/ourplans", "/useracount"];
	const [Mode, setMode] = useState("");

	useEffect(() => {
		if (path.includes(location.pathname)) {
			setMode("");
		} else {
			setMode("menu__list__linkDark");
		}
	}, [location]);

	const handleServices = () => {
		
		if (location.pathname === "/") {
			onClickDown("services");
		} else {
			// setOpenSubmenu(!openSubmenu);
			setSubmenu("services");
		}
	};
	const handleContact = () => {
		
		if (location.pathname === "/") {
			
			onClickDown("contact");
		} else {
			
			navigate("/contactus")
		}
	};

	return (
		<>
			<li className='menu__list'>
				<Link
					to='/'
					className={`menu__list__link ${Mode}`}
					onClick={() => {
						onClickUp();
						setOpenMenu(false);
					}}>
					<RiHomeSmile2Line className='link__icon' />
					Inicio
				</Link>
			</li>
			<li className='menu__list'>
				<div className={`menu__list__link ${Mode}`} onClick={handleServices}>
					<MdOutlineRoomService className='link__icon' />
					Servicios
				</div>
				{submenu === "services" ? <Submenu /> : null}
			</li>
			<li className='menu__list'>
				<div
					className={`menu__list__link ${Mode}`}
					onClick={handleContact}>
					<MdOutlineSupportAgent className='link__icon' />
					Contacto
				</div>
			</li>
			<li className='menu__list'>
				<Link
					to='/aboutus'
					className={`menu__list__link ${Mode}`}
					onClick={() => {
						setOpenMenu(false);
					}}>
					<BsPersonBadge className='link__icon' />
					Nosotros
				</Link>
			</li>
			<li className='menu__list'>
				<Link to='/ourplans' className={`menu__list__link ${Mode}`}>
					<MdOutlineAttachMoney className='link__icon' />
					<span className='login__label'>Nuestros planes</span>
				</Link>
			</li>
		</>
	);
}

import React, {useContext} from "react";
import  FAQContainer from "../containers/FAQContainer"
import { AppContext } from "../Context/AppContext";

export default function FAQ() {
	const { useScrollToTop } = useContext(AppContext);
	useScrollToTop();
	return <main className="faq">
		<h1 className="faq__title">Preguntas Frecuentes</h1>
		<FAQContainer/>
	</main>
}

import React from "react";

export default function Philosophy({ count, title, text, className }) {
	return (
		<div className={`philosophy__content ${className}`}>
			<h2 className='philosophy__count'>{count}</h2>
			<h4 className='philosophy__subtitle'>{title}</h4>
			<p className='philosophy__text'>{text}</p>
		</div>
	);
}

import React, { useState, useEffect, useContext } from "react";
import { plansData } from "../assets/TiposPlanesData";
import { BsCheck2Circle, BsInfoCircleFill } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import { FiAlertCircle } from "react-icons/fi";
import { AppContext } from "../Context/AppContext";
import ModalDatosFacturacion from "../containers/ModalDatosFacturacion";
import { useNavigate } from "react-router-dom";

export default function PlansList() {
	const {
		getAuthToken,
		openFactura,
		infoLogin,
		getDatosFacturación,
		authToken,
		setLogin,
		login,
		handleTRM,
		TRM,
		setValorPagar,
		setRedirect,
		setOpenFactura
	} = useContext(AppContext);
	const IVA = 0.19;
	const navigate = useNavigate()

	const [reminder, setReminder] = useState(false);

	const handleSubmit = (value, plan) => {

		if (plan === "Grandes administradores") {
			setOpenFactura(false);
			setValorPagar(null);
			navigate("/contactus")
		} else {
			if (!infoLogin.uid) {
				setLogin(!login);
				setReminder(!reminder);
			} else {
				setOpenFactura(!openFactura);
				setValorPagar(plan.precio*TRM[0]);
				const valor = value * TRM[0];
				const valorCuadro = Math.round(valor + valor * IVA);
				const data = {
					uid: infoLogin.uid,
					monto: valorCuadro,
					moneda: "COP",
					subscription: plan,
					redirect: "useracount"
				};
				setValorPagar(valorCuadro);
				setRedirect("useracount")
				getDatosFacturación(data, authToken.access_token);
			}
		}

	};

	useEffect(() => {
		handleTRM();
		getAuthToken();
	}, []);

	return (
		<>
			<div className='plans__tab'>
				{plansData.map((plan) => {
					return (
						<div className='plans__content' key={plan.count}>
							<div>
								{infoLogin.userinfo?.subscription === plan.title && (
									<h5 className='plans__content__planActual'>Este es tu plan actual</h5>
								)}
								<h3 className='plans__content__title'>
									{plan.title}
									<span className='asterisco'>*</span>
								</h3>
								<div className='plans__content__price'>
									{plan.precio}{" "}
									{plan.precio !== "Gratis" && plan.precio !== "Contáctanos"
										? " USD"
										: null}{" "}
									{plan.precio * TRM[0] ? (
										<div className='controls'>
											<BsInfoCircleFill className='price__infoIcon' />
											<div className='info'>
												<small className='info__value'>
													{new Intl.NumberFormat("de-DE").format(
														Math.round(plan.precio * TRM[0] + plan.precio * TRM[0] * IVA),
													)}
													{" COP"}
												</small>
												<small className='info__value small'>IVA incluido</small>
											</div>
										</div>
									) : null}
								</div>
								<div className='plans__content__descripcion'>{plan.descripcion}</div>
								<div className='plans__content__info'>
									<label className='plans__content__label'>Cuadro de turnos</label>{" "}
									<span className='plans__content__data'>{plan.cuadros}</span>
								</div>
								<div className='plans__content__info'>
									<label className='plans__content__label'>Equipos de trabajo</label>{" "}
									<span className='plans__content__data'>{plan.equipos}</span>
								</div>
								<div className='plans__content__info'>
									<label className='plans__content__label'>Personas por cuadro</label>{" "}
									<span className='plans__content__data'>{plan.númeroPersonas}</span>
								</div>
								<h3 className='plans__content__subtitle'>Caracteristicas claves</h3>
								<div className='plans__content__subinfo'>
									<label className='plans__content__label2'>
										{plan.incluyeHorarios === "X" ? (
											<BsCheck2Circle className='plans__content__checkIcon' />
										) : (
											<RiCloseCircleLine className='plans__content__notIcon' />
										)}
										Personalizar horas contratadas
									</label>{" "}
								</div>
								<div className='plans__content__subinfo'>
									<label className='plans__content__label2'>
										{plan.definirFechas === "X" ? (
											<BsCheck2Circle className='plans__content__checkIcon' />
										) : (
											<RiCloseCircleLine className='plans__content__notIcon' />
										)}
										Definir restricciones de fechas
									</label>
								</div>
								<div className='plans__content__subinfo'>
									<label className='plans__content__label2'>
										{plan.tablaResumen === "X" ? (
											<BsCheck2Circle className='plans__content__checkIcon' />
										) : (
											<RiCloseCircleLine className='plans__content__notIcon' />
										)}
										Resumen de asignación
									</label>
								</div>
								<div className='plans__content__subinfo'>
									<label className='plans__content__label2'>
										{plan.descarga === "X" ? (
											<BsCheck2Circle className='plans__content__checkIcon' />
										) : (
											<RiCloseCircleLine className='plans__content__notIcon' />
										)}
										Descarga
									</label>
								</div>
							</div>
							{plan.title !== "Incrédulo" &&
							plan.title !== infoLogin.userinfo?.subscription ? (
								<button
									className='button-secondary plans__content__btn'
									// onClick={() => {
									// 	handleSubmit(plan.precio, plan.title);
									// 	}}
									>
									Lo Quiero
								</button>
							) : null}
						</div>
					);
				})}
			</div>
			{openFactura && <ModalDatosFacturacion />}
			{login && reminder && (
				<div className='reminder'>
					<div className='reminder__content'>
						<h3 className='reminder__title'>
							<FiAlertCircle className='reminder__alertIcon' /> Información
						</h3>
						<RiCloseCircleLine
							className='features__closeIcon'
							onClick={() => setReminder(!reminder)}
						/>
						<p className='reminder__text'>
							Por favor. logueate o registrate para adquirir tu plan Dasensy
						</p>
					</div>
				</div>
			)}
		</>
	);
}

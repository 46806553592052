import React from "react";
import { SiWhatsapp } from "react-icons/si";
import { MdOutlineMail } from "react-icons/md";

export default function Contact() {
	return (
		<section className='contact' id='contact'>
			<form className='contact__form'>
				<h2 className='contact__title'>Contactanos</h2>
				<div className='contact__input__group'>
					<label className='contact__input__label'>Nombre</label>
					<input
						className='contact__input'
						type='text'
						placeholder='Escribenos tu nombre'
					/>
				</div>
				<div className='contact__input__group'>
					<label className='contact__input__label'>Correo</label>
					<input
						className='contact__input'
						type='email'
						placeholder='example@example.com'
					/>
				</div>
				<div className='contact__input__group'>
					<label className='contact__input__label'>Dejanos tu mensaje</label>
					<textarea
						className='contact__input__text'
						placeholder='Aqui esperamos tu mensaje'></textarea>
				</div>
				<button className='button-primary contact__form__Btn'>Enviar</button>
			</form>
			<article className='contact__info'>
				<h4 className='contact__info__title'>También nos puedes contactar en</h4>
				<label className='contact__info__label'>
					<MdOutlineMail className='contact__info__icon' />
					webmasters@dasensy.com
				</label>
				<label className='contact__info__label'>
					<SiWhatsapp className='contact__info__icon' />
					+573043195648
				</label>
			</article>
		</section>
	);
}

import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { RiCloseCircleLine } from "react-icons/ri";
import { AppContext } from "../Context/AppContext";

export default function ModalsUserAccount({ children }) {
	const { setOpenModalUser, setIsError,setError } = useContext(AppContext);

	return ReactDOM.createPortal(
		<section className='modal'>
			<article className='modal__content'>
				<RiCloseCircleLine
					className='modal__closeIcon'
					onClick={() => {
						setOpenModalUser(false)
						setIsError("")
						setError(false)
					}}
				/>
				{children}

			</article>
		</section>,
		document.getElementById("modals"),
	);
}

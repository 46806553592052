import React from "react";
import PlansList from "../components/PlansList";



export default function PlansContainer() {
	
	return (
        <section className='plans_container'>
            <h1 className="plans__title">Tenemos diferentes planes para tener <br></br> Datos con Sentido </h1>
            <h3 className="plans__subtitle">Adquierelos según crezcas</h3>
			<PlansList></PlansList>
			<p className="plans__subtitle"><span className='asterisco'>*</span>Todos nuestros planes estan en Dolares de manera informativa, al momento de realizar el pago el sistema generará la conversión a pesos colombianos (COP) de forma automatica</p>
		</section>
	);
}

export const plansData = [
	{
		count: "01",
		title: "Incrédulo",
		descripcion:"Para personas que quieren probar algo nuevo",
		cuadros: 1,
		equipos: 1,
		númeroPersonas: "<= 5",
		incluyeHorarios: "",
		definirFechas: "",
		tablaResumen: "",
		descarga: "X",
		precio: "Gratis",
	},
	{
		count: "02",
		title: "Urgencia",
		descripcion:"Para particulares y pequeñas clínicas que necesitan datos con sentido.",
		cuadros: 1,
		equipos: 1,
		númeroPersonas: "Ilimitado",
		incluyeHorarios: "X",
		definirFechas: "X",
		tablaResumen: "",
		descarga: "X",
		precio: 9.99,
	},
	{
		count: "03",
		title: "Tiempo libre",
		descripcion:"Para quienes deseen darle mayor sentido a sus datos, durante todo un año",
		cuadros: "1 por mes (12 en total)",
		equipos: 2,
		númeroPersonas: "Ilimitado",
		incluyeHorarios: "X",
		definirFechas: "X",
		tablaResumen: "X",
		descarga: "X",
		precio: 109.99,
	},
	{
		count: "04",
		title: "Gestión múltiple",
		descripcion:"Para que gestiones tus equipos durante todo el año",
		cuadros: "3 por mes (36 en total)",
		equipos: 3,
		númeroPersonas: "Ilimitado",
		incluyeHorarios: "X",
		definirFechas: "X",
		tablaResumen: "X",
		descarga: "X",
		precio: 299.99,
	},
	{
		count: "05",
		title: "Grandes administradores",
		descripcion:"Para grandes empresas que buscan un plan personalizado y asistencia prioritaria.",
		cuadros: "Contáctanos",
		equipos: "Contáctanos",
		númeroPersonas: "Ilimitado",
		incluyeHorarios: "X",
		definirFechas: "X",
		tablaResumen: "X",
		descarga: "X",
		precio: "Contáctanos",
	},
];

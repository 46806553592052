import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { RiCloseCircleLine } from "react-icons/ri";
import PasarelaPago from "../components/PasarelaPago";
import { AppContext } from "../Context/AppContext";

export default function ModalDatosFacturacion() {
	const {openFactura, setOpenFactura,valorPagar} = useContext(AppContext)
	return ReactDOM.createPortal(
		<div className='modalCuadroT'>
			<section className="wompi">
				<h1 className="wompi__title">Datos de facturación
				<RiCloseCircleLine
					className='features__closeIcon'
					onClick={() => setOpenFactura(!openFactura)}
				/>
				</h1>
				<PasarelaPago valor={valorPagar}/>
			</section>
		</div>,
		document.getElementById("wompi"),
	);
}

import React from 'react';
import backgroundImg from "../assets/icons/icononly_transparent_nobuffer.png";

const Notfound = () => {
    return (
        <section className="notFound">
            <div className="notFound__info">
                <h1  className="notFound__title">
                    Lo sentimos algo ha sucedido 
                </h1>
                <img src={backgroundImg} className='notFound__img' alt='background img' />
            </div>
        </section>
    );
}

export default Notfound;

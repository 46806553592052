import React from "react";

export default function Politics() {
	return (
		<>
			<h1 className='advice__title'>Política de tratamiento de datos personales</h1>
			<h3 className='advice__subtitle'>Soluciones en Telesalud SAS - DASENSY SAS</h3>
			<h3 className='advice__subtitle'>OBJETIVO</h3>
			<p className='advice__text'>
				Establecer los criterios para la recolección, almacenamiento, uso, circulación,
				análisis, transformación y supresión de los datos personales tratados por
				Soluciones en Telesalud S.A.S.
			</p>
			<h3 className='advice__subtitle'>ALCANCE</h3>
			<p className='advice__text'>
				Esta política aplica para toda la información personal registrada en las bases de
				datos de Soluciones en Telesalud S.A.S - DASENSY, quien actúa en calidad de
				responsable del tratamiento de los datos personales.
			</p>
			<h3 className='advice__subtitle'>OBLIGACIONES</h3>
			<p className='advice__text'>
				Esta política es de obligatorio y estricto cumplimiento para Soluciones en
				Telesalud S.A.S - DASENSY, sus colaboradores y aliados estratégicos.
			</p>
			<h3 className='advice__subtitle'>RESPONSABLE DEL TRATAMIENTO</h3>
			<p className='advice__text'>
				Soluciones en Telesalud S.A.S - DASENSY SAS, sociedad comercial legalmente
				constituida, identificada con el NIT 901552656, con domicilio principal en la
				ciudad de Envigado, República de Colombia. Página www.dasensy.com.
			</p>
			<h3 className='advice__subtitle'>TRATAMIENTO Y FINALIDAD</h3>
			<p className='advice__text'>
				El tratamiento que realizará Soluciones en Telesalud S.A.S - DASENSY SAS con la
				información personal será el siguiente: La recolección, almacenamiento, uso,
				circulación, procesamiento, transformación y análisis para optimización de los
				productos de la compañía a ofrecer a sus clientes y en particular al titular de
				los datos personales, creación de nuevos modelos de negocio, diseño de estrategia
				de mercadeo personalizadas.
			</p>
			<h3 className='advice__subtitle'>TRATAMIENTO DE DATOS SENSIBLES</h3>
			<p className='advice__text'>
				Los datos sensibles recolectados serán tratados con las siguientes finalidades:
				transformación para ocultamiento de los datos sensibles y posterior procesamiento
				para generación de modelos de intelingencia artifical que ayuden a los procesos de
				atención en salud.
			</p>
			<h3 className='advice__subtitle'>DERECHOS DE LOS TITULARES</h3>
			<p className='advice__text'>
				Como titular de sus datos personales Usted tiene derecho a: (i) Acceder de forma
				gratuita a los datos proporcionados que hayan sido objeto de tratamiento. (ii)
				Conocer, actualizar y rectificar su información frente a datos parciales,
				inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo
				tratamiento esté prohibido o no haya sido autorizado. (iii) Solicitar prueba de la
				autorización otorgada. (iv) Presentar ante la Superintendencia de Industria y
				Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente.
				(v) Revocar la autorización y/o solicitar la supresión del dato, siempre que no
				exista un deber legal o contractual que impida eliminarlos. (vi) Abstenerse de
				responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las
				respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y
				adolescentes.
			</p>
			<h3 className='advice__subtitle'>ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS</h3>
			<p className='advice__text'>
				El área de dirección de operaciones es la dependencia que tiene a cargo dar
				trámite a las solicitudes de los titulares para hacer efectivos sus derechos los
				cuales se podrán ejercer a través de correo electrónico dasensy@dasensy.com y de
				la página web www.dasensy.com en el formulario correspondiente.
			</p>
			<h3 className='advice__subtitle'>
				PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA
			</h3>
			<p className='advice__text'>
				En cumplimiento de las normas sobre protección de datos personales, Soluciones en
				Telesalud S.A.S - DASENSY SAS presenta el procedimiento y requisitos mínimos para
				el ejercicio de sus derechos: Para la radicación y atención de su solicitud le
				solicitamos suministrar la siguiente información: Nombre completo y apellidos
				Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto),
				Medios para recibir respuesta a su solicitud, Motivo(s)/hecho(s) que dan lugar al
				reclamo con una breve descripción del derecho que desea ejercer (conocer,
				actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla,
				suprimir, acceder a la información) Firma (si aplica) y número de identificación.
				El término máximo previsto por la ley para resolver su reclamación es de quince
				(15) días hábiles, contados a partir del día siguiente a la fecha de su recibo.
				Cuando no fuere posible atender el reclamo dentro de dicho término, Soluciones en
				Telesalud S.A.S - DASENSY SAS informará al interesado los motivos de la demora y
				la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los
				ocho (8) días hábiles siguientes al vencimiento del primer término. Una vez
				cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la
				reglamenten o complementen, el Titular al que se deniegue, total o parcialmente,
				el ejercicio de los derechos de acceso, actualización, rectificación, supresión y
				revocación, podrá poner su caso en conocimiento de la Superintendencia de
				Industria y Comercio –Delegatura para la Protección de Datos Personales-.
			</p>
			<h3 className='advice__subtitle'>VIGENCIA</h3>
			<p className='advice__text'>
				La presente Política para el Tratamiento de Datos Personales rige a partir del 3
				de enero de 2022. Las bases de datos en las que se registrarán los datos
				personales tendrán una vigencia igual al tiempo en que se mantenga y utilice la
				información para las finalidades descritas en esta política. Una vez se cumplan
				esas finalidades y siempre que no exista un deber legal o contractual de conservar
				su información, sus datos serán eliminados de nuestras bases de datos.
			</p>
		</>
	);
}

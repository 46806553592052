import React , { useContext } from "react";
import { Modals } from "../containers/Modals";
import { GoAlert } from "react-icons/go";
import PlansContainer from "../containers/PlansContainer";
import { AppContext } from "../Context/AppContext";

export default function PlansPage() {
    const { useScrollToTop , isError} = useContext(AppContext);
	useScrollToTop();
    return <main className='plans'>
        <PlansContainer />
        {isError && (
				<Modals>
					<div className='paymentError'>
						<GoAlert className='paymentError__Icon' />
						<p className='paymentError__text'>{isError}</p>
					</div>
				</Modals>
			)}
    </main>;
}

import React from 'react'

export default function AvisoPrivacidad() {
    return (
        <>
			<h1 className='advice__title'>Aviso de privacidad</h1>
			<h3 className='advice__subtitle'>Soluciones en Telesalud SAS - DASENSY SAS</h3>
			<p className='advice__text'>
				El presente Aviso de Privacidad (en adelante el “Aviso”) establece los términos y
				condiciones en virtud de los cuales Soluciones en Telesalud S.A.S - DASENSY SAS,
				identificado con NIT 901552656, con domicilio principal en la ciudad de Envigado,
				República de Colombia. Página www.dasensy.com, realizará el tratamiento de sus
				datos personales.
			</p>
			<h3 className='advice__subtitle'>TRATAMIENTO Y FINALIDAD</h3>
			<p className='advice__text'>
				El tratamiento que realizará Soluciones en Telesalud S.A.S - DASENSY SAS con la
				información personal será el siguiente: La recolección, almacenamiento, uso,
				circulación, procesamiento, transformación y análisis para optimización de los
				productos de la compañía a ofrecer a sus clientes y en particular al titular de
				los datos personales, creación de nuevos modelos de negocio, diseño de estrategia
				de mercadeo personalizadas.
			</p>
			<h3 className='advice__subtitle'>DERECHOS DEL TITULAR</h3>
			<p className='advice__text'>
				Como titular de sus datos personales Usted tiene derecho a: (i) Acceder de forma
				gratuita a los datos proporcionados que hayan sido objeto de tratamiento. (ii)
				Conocer, actualizar y rectificar su información frente a datos parciales,
				inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo
				tratamiento esté prohibido o no haya sido autorizado. (iii) Solicitar prueba de la
				autorización otorgada. (iv) Presentar ante la Superintendencia de Industria y
				Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente.
				(v) Revocar la autorización y/o solicitar la supresión del dato, siempre que no
				exista un deber legal o contractual que impida eliminarlos. (vi) Abstenerse de
				responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las
				respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y
				adolescentes.
			</p>
			<h3 className='advice__subtitle'>
				MECANISMOS PARA CONOCER LA POLÍTICA DE TRATAMIENTO
			</h3>
			<p className='advice__text'>
				El Titular puede acceder a nuestra Política de Tratamiento de información, la cual
				se encuentra publicada en https://www.dasensy.com
			</p>
		</>
    )
}

import React, { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import {RiCloseCircleLine} from 'react-icons/ri'
import UserList from "./UserLIst";
import ServiceList from "./ServiceList";

export default function Submenu() {
    const { submenu,setSubmenu } = useContext(AppContext)
    
	return (
		<ul className='submenu'>
			<RiCloseCircleLine
				className='modal__closeIcon'
				onClick={() => setSubmenu("")}
			/>
            {submenu === "userAcount" && <UserList />}
            {submenu === "services" && <ServiceList />}
		</ul>
	);
}

import React, { useContext,useEffect,useState } from "react";
import { BsCheck2Circle, BsInfoCircleFill } from "react-icons/bs";
import { RiCloseCircleLine, RiEmotionHappyLine } from "react-icons/ri";
import { FiAlertCircle } from "react-icons/fi";
import { AppContext } from "../Context/AppContext";
import { plansData } from "../assets/TiposPlanesData";
import { Link } from "react-router-dom";



export default function Subscription() {
    const { infoLogin,TRM } = useContext(AppContext);
    const IVA=0.19
    const [plan, setPlan] = useState({})
    
    useEffect(() => {
        plansData.map((item) => item.title === infoLogin.userinfo.subscription && setPlan(item));
    }, [infoLogin.userinfo.subscription]);
	return (
		<div className='plans__content userPlan'>
			<h3 className='plans__content__title'>
				{plan.title}
				<span className='asterisco'>*</span>
			</h3>
			<div className='plans__content__price'>
				{plan.precio}{" "}
				{plan.precio !== "Gratis" && plan.precio !== "Contáctanos" ? " USD" : null}{" "}
				{plan.precio * TRM[0] ? (
					<div className='controls'>
						<BsInfoCircleFill className='price__infoIcon' />
						<div className='info'>
							<small className='info__value'>
								{new Intl.NumberFormat("de-DE").format(
									Math.round(plan.precio * TRM[0] + plan.precio * TRM[0] * IVA),
								)}
								{" COP"}
							</small>
							<small className='info__value small'>IVA incluido</small>
						</div>
					</div>
				) : null}
			</div>
			<div className='plans__content__descripcion'>{plan.descripcion}</div>
			<div className='plans__content__info'>
				<label className='plans__content__label'>Cuadro de turnos</label>{" "}
				<span className='plans__content__data'>{plan.cuadros}</span>
			</div>
			<div className='plans__content__info'>
				<label className='plans__content__label'>Equipos de trabajo</label>{" "}
				<span className='plans__content__data'>{plan.equipos}</span>
			</div>
			<div className='plans__content__info'>
				<label className='plans__content__label'>Personas por cuadro</label>{" "}
				<span className='plans__content__data'>{plan.númeroPersonas}</span>
			</div>
			<h3 className='plans__content__subtitle'>Caracteristicas claves</h3>
			<div className='plans__content__subinfo'>
				<label className='plans__content__label2'>
					{plan.incluyeHorarios === "X" ? (
						<BsCheck2Circle className='plans__content__checkIcon' />
					) : (
						<RiCloseCircleLine className='plans__content__notIcon' />
					)}
					Diferentes contratos horarios
				</label>{" "}
			</div>
			<div className='plans__content__subinfo'>
				<label className='plans__content__label2'>
					{plan.definirFechas === "X" ? (
						<BsCheck2Circle className='plans__content__checkIcon' />
					) : (
						<RiCloseCircleLine className='plans__content__notIcon' />
					)}
					Definir restricciones de fechas
				</label>
			</div>
			<div className='plans__content__subinfo'>
				<label className='plans__content__label2'>
					{plan.tablaResumen === "X" ? (
						<BsCheck2Circle className='plans__content__checkIcon' />
					) : (
						<RiCloseCircleLine className='plans__content__notIcon' />
					)}
					Resumen de asignación
				</label>
			</div>
			<div className='plans__content__subinfo'>
				<label className='plans__content__label2'>
					{plan.descarga === "X" ? (
						<BsCheck2Circle className='plans__content__checkIcon' />
					) : (
						<RiCloseCircleLine className='plans__content__notIcon' />
					)}
					Descarga
				</label>
			</div>
			<Link
				className='button-secondary plans__content__btn'
				to="/ourplans">
				Ver planes
			</Link>
		</div>
	);
}

import React, { useContext } from "react";
import { philosophyData } from "../assets/philosophyData";
import misionmedic from "../assets/img/AboutUs/medicmision.png";
import visionmedic from "../assets/img/AboutUs/medicvision.png";
import valuenmedic from "../assets/img/AboutUs/medic.png";
import historymedic from "../assets/img/AboutUs/medichistory.png";
import filomedic from "../assets/img/AboutUs/medicfilo.png";
import Filosofy from "../components/Filosofy";
import backgroundImg from "../assets/icons/icononly_transparent_nobuffer.png";
import backgroundImgend from "../assets/img/AboutUs/icononly_aboutus.png";
import { MdOutlineCheck } from "react-icons/md";
import { AppContext } from "../Context/AppContext";

export default function AboutUs() {
	const { useScrollToTop } = useContext(AppContext);
	useScrollToTop();
	return (
		
		<main className='aboutus'>
			<img src={backgroundImg} className='background__img__start' alt='background img' />
			<img src={backgroundImg} className='background__img__middle' alt='background img' />
			<img src={backgroundImgend} className='background__img__end' alt='background img' />

			<section className='mision' id='mision'>
				<h3 className='mision__title'>Nuestra Misión</h3>
				<p className='mision__text'>
					Dar sentido a tus datos, desarrollando grupos de trabajo, software y
					consultorías que respondan a las necesidades de transformación de los datos en
					salud en información, conocimiento y sabiduría, con énfasis en la innovación, la
					creatividad y el respeto por el paciente.
				</p>
				<img src={misionmedic} className='mision__img' alt='Mision medic'></img>
			</section>
			<section className='vision' id='vision'>
				<h3 className='vision__title'>Como nos vemos</h3>
				<p className='vision__text'>
					Para el año 2027 Soluciones en Telesalud SAS - DASENSY SAS será reconocida en el
					medio local y nacional como empresa líder en el desarrollo de soluciones en
					informática en salud y telesalud que dan sentido a los datos de sus clientes.
				</p>
				<img src={visionmedic} className='vision__img' alt='vision medic'></img>
			</section>
			<section className='value' id='value'>
				<h3 className='value__title'>Porque nosotros ...</h3>
				<div className='value__text'>
					<p className='value__subtext'>
						<MdOutlineCheck className='value__text__icon' />
						Datos con sentido.
					</p>
					<p className='value__subtext'>
						<MdOutlineCheck className='value__text__icon' />
						<span>
							Vas a completar el ciclo de vida de los datos, información, conocimiento y
							sabiduria en su institución.
						</span>
					</p>
					<p className='value__subtext'>
						<MdOutlineCheck className='value__text__icon' />
						Alta ética corporativa
					</p>
					<p className='value__subtext'>
						<MdOutlineCheck className='value__text__icon' />
						Despreocuparse por la infraestructura para analizar sus datos.
					</p>
					<p className='value__subtext'>
						<MdOutlineCheck className='value__text__icon' />
						Flexibilidad contractual con condiciones laborales dignas.
					</p>
				</div>
				<img src={valuenmedic} className='value__img' alt='value medic'></img>
			</section>
			<section className='philosophy' id='philosophy'>
				<h3 className='philosophy__title'>Nuestra Filosofía</h3>
				{philosophyData.map((philosophy) => (
					<Filosofy
						key={philosophy.count}
						count={philosophy.count}
						title={philosophy.title}
						text={philosophy.text}
						className={philosophy.classNames}
					/>
				))}
				<img src={historymedic} className='philosophy__img1' alt='philosophy medic'></img>
				<img src={filomedic} className='philosophy__img2' alt='philosophy medic'></img>
			</section>
		</main>
	);
}

import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ImEye, ImEyeBlocked, ImGoogle } from "react-icons/im";
import { FcGoogle } from "react-icons/fc";
import { AppContext } from "../Context/AppContext";
import FormTerms from "./FormTerms";

export default function Login() {
	const {
		login,
		setLogin,
		openSignUp,
		setOpenSignUp,
		userLogIn,
		userLogInGoogle,
		isError,
		logConfig,
		resetPassword,
		setResetPassword,
	} = useContext(AppContext);

	const [seepass, setSeepass] = useState(false);
	const [terms, setTerms] = useState(false);

	const form = useRef(null);

	const handleSubmit = (e) => {
		const formData = new FormData(form.current);
		const data = {
			email: formData.get("email"),
			password: formData.get("password"),
		};
		userLogIn(e, data);
	};

	const handleTerms = (e) => {
		const formData = new FormData(form.current);
		const data = {
			checkTerms: formData.get("check-terms") && true,
		};
		userLogInGoogle(e, data);
	};

	const handleLoginGoogle = (e) => {
		e.preventDefault();
		setTerms(!terms);
	};


	return (
		<>
			<div className='modalIngreso' id='modalIngreso'>
				<form className='modalIngreso__form' onSubmit={handleSubmit} ref={form}>
					<div className='form__input-box'>
						<label className='form__label'>
							<i className='form__icon fas fa-user '></i>
							Correo
						</label>
						<input type='text' className='form__input' required id='email' name='email' />
					</div>

					<div className='form__input-box'>
						<label className='form__label'>
							<i className='form__icon fas fa-lock'></i>
							Contraseña
						</label>
						<input
							type={seepass ? "text" : "password"}
							className='form__input'
							required
							id='password'
							name='password'
						/>
						{!seepass ? (
							<ImEye
								className='login__password__seeIcon'
								onClick={() => {
									setSeepass(!seepass);
								}}
							/>
						) : (
							<ImEyeBlocked
								className='login__password__seeIcon'
								onClick={() => {
									setSeepass(!seepass);
								}}
							/>
						)}
					</div>

					<div className='btn-group'>
						<input type='submit' className='button form__btn' value='Ingresar' />
						<input
							type='button'
							className='button form__btn form__btn--cancel'
							value='Cancelar'
							onClick={() => setLogin(!login)}
						/>
					</div>
				</form>

				{isError !== "" && <div className='msg__error'>{isError}</div>}

				<div className='formLinks'>
					<h4 className='formLinks__title'>O también ingresa con </h4>

					<button
						type='button'
						className='login__google'
						onClick={
							logConfig.logbefore
								? (e) => userLogInGoogle(e, true)
								: (e) => handleLoginGoogle(e)
						}>
						{/* <ImGoogle className='login__google__icon' /> */}
						<FcGoogle className='login__google__icon' />
						<span className="login__google__text">Entra con Google</span>
					</button>

					{terms && (
						<section className='terms'>
							<form className='terms__content' onSubmit={handleTerms} ref={form}>
								<h3 className='terms__title'>
									Antes de ingresar por favor acepta terminos y condiciones
								</h3>
								<FormTerms />
								<input type='submit' className='button form__btn' value='Aceptar' />
								<button
									type='button'
									className='button form__btn form__btn--cancel'
									onClick={() => setTerms(!terms)}>
									Cancelar
								</button>
							</form>
						</section>
					)}

					<div
						className='formLinks__registrar'
						onClick={() => {
							setLogin(!login);
							setOpenSignUp(!openSignUp);
						}}>
						Registrate
					</div>
					<p
						className='formLinks__forgot'
						onClick={() => {
							setResetPassword(!resetPassword);
							setLogin(!login);
						}}>
						¿Olvidaste tu contraseña?
					</p>
				</div>
			</div>
		</>
	);
}

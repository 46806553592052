import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../containers/Layout";
import { AppContext } from "../Context/AppContext";
import AboutUs from "../pages/AboutUs";
import CuadroTurnos from "../pages/CuadroTurnos";
import ContactUs from "../pages/ContactUs";
import FAQ from "../pages/FAQ";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import PlansPage from "../pages/PlansPage";
import Preview from "../pages/Preview";
import UserAcount from "../pages/UserAcount";
import UserTCuadros from "../pages/UserTCuadros";

export default function AppUI() {

    const {infoLogin} = useContext(AppContext)

    const validRoute = (routes) => {
		return infoLogin.uid ? routes : <NotFound />;
    };
    
    return (
        
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/" caseSensitive={false} element={<Home/>}/>
                    <Route path="/aboutus" caseSensitive={false} element={<AboutUs/>}/>
                    <Route path="/ourplans" caseSensitive={false} element={<PlansPage/>}/>
                    <Route path="/contactus" caseSensitive={false} element={<ContactUs/>}/>
                    <Route path="/faq" caseSensitive={false} element={<FAQ/>}/>
                    {/* <Route path="/dasensycuadroturnos" caseSensitive={false} element={validRoute(<CuadroTurnos/>)}/> */}
                    <Route path="/dasensycuadroturnos" caseSensitive={false} element={<CuadroTurnos/>}/>
                    <Route path="/dasensycuadroturnospreview" caseSensitive={false} element={validRoute(<Preview/>)}/>
                    <Route path="/dasensyusercuadroturnos" caseSensitive={false} element={validRoute(<UserTCuadros/>)}/>
                    <Route path="/useracount" caseSensitive={false} element={validRoute(<UserAcount/>)}/>
                    <Route path="*" element={<NotFound />} />   
                </Routes>
            </Layout>
        </BrowserRouter>
    )
}

import React, { useContext, useEffect, useRef, useState } from "react";
// import previaTCuadro from "../assets/img/previaTCuadro.png";
import ModalDatosFacturacion from "../containers/ModalDatosFacturacion";
import { AppContext } from "../Context/AppContext";
import { AiOutlineReload } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Modals } from "../containers/Modals";
import { GoAlert } from "react-icons/go";
import { BsInfoCircleFill } from "react-icons/bs";
import PreviewPlanList from "./PreviewPlanList";
import loadImg from "../assets/icons/icononly_transparent_nobuffer.png";
import { Link, useNavigate } from "react-router-dom";
import ModalsUserAccount from "../containers/ModalsUserAccount";
import { db } from "../firebase/firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";

// import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
const captchaKey = process.env.REACT_APP_DASENSY_CAPTCHA_PUBLIC_V2;
const IVA = 0.19;
let cont = 1;
// const valorCuadro = Math.round(39990 + 39990 * IVA);
// const valorCuadroAnual = Math.round(419990 + 419990 * IVA);

export default function PreviewTCuadros() {
	const {
		getAuthToken,
		getDatosFacturación,
		infoLogin,
		openFactura,
		setOpenFactura,
		tCuadros,
		authToken,
		isError,
		TRM,
		valorPagar,
		setValorPagar,
		error,
		setError,
		free,
		setRedirect,
		updateDasPlanCount,
		handleTRM,
		datosFactura,
		saveInfoLogin
	} = useContext(AppContext);

	const form = useRef(null);
	const captcha = useRef(null);
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(true);
	const [isVerified, setIsVerified] = useState(true);
	const [previewUrl, setPreviewUrl] = useState(null);
	const storage = getStorage();
	const navigate = useNavigate();

	useEffect(() => {
		handleTRM();
	}, []);

	const updatePay = async () => {
		const docRef = doc(db, "dasCreate", datosFactura.idDasCreate);

		await updateDoc(docRef, {
			pagado: true,
		})
			.then((response) => {
				console.log("dasCreate actualizado");
			})
			.catch((error) => console.log(error));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(form.current);
		const plan = formData.get("planPago");
		if (plan !== "Gratis") {
			const valor = parseInt(formData.get("planPago")) * TRM[0];
			const valorCuadro = Math.round(valor + valor * IVA);
			setValorPagar(valorCuadro);
			console.log(plan);
			const data = {
				uid: infoLogin.uid,
				monto: formData.get("planPago"),
				moneda: "COP",
				subscription:
					plan === "9.99"
						? "Urgencia"
						: plan === "109.99"
						? "Tiempo libre"
						: plan === "299.99" && "Gestión múltiple",
				redirect: "dasensyusercuadroturnos",
			};

			setData(data);
			setLoading(true);
			setRedirect("dasensyusercuadroturnos");
			getDatosFacturación(data, authToken.access_token);
			
			updateDasPlanCount(true);
			setTimeout(() => {
				setLoading(false);
				setOpenFactura(!openFactura);
				saveInfoLogin({...infoLogin, cuadroAdicional:false})
			}, 1500);
		} else {
			updateDasPlanCount(true);

			updatePay();
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				navigate("/dasensyusercuadroturnos");
			}, 1500);
		}
	};

	const handleDescarga = () => {
		updatePay();
		updateDasPlanCount(true);
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			navigate("/dasensyusercuadroturnos");
		}, 1500);
		
	};

	useEffect(() => {
		const carpeta = tCuadros.dasCreate?.carpeta;
		const userUid = infoLogin.uid;

		const getDownloadCuadro = () => {
			const gsReference = ref(
				storage,
				`gs://dasensy-94a04.appspot.com/tcuadro/${userUid}/${carpeta}/preliminar.html`,
			);
			getDownloadURL(gsReference)
				.then((url) => {
					// `url` is the download URL for 'images/stars.jpg'
					setLoadingData(false);
					setPreviewUrl(url);
				})
				.catch((error) => {
					console.log(error);

					if (cont < 2) {
						cont = cont + 1;

						setTimeout(() => {
							getDownloadCuadro();
						}, 2000);
					} else {
						setError(true);
					}
				});
		};

		setTimeout(() => {
			getDownloadCuadro();
		}, 4000);
	}, []);

	const HandleOnChange = () => {
		if (captcha.current.getValue()) {
			getAuthToken();
			setIsVerified(false);
		} else {
			setIsVerified(true);
		}
	};

	return (
		<section className='preview__content'>
			<h1 className='preview__title'>Este sería su Cuadro de Turnos</h1>
			<div className='preview__imgContainer'>
				<p className='preview__infoText'>
					Ten en cuenta que esta es una imagen de referencia, toma un tiempo darle sentido
					a tus datos. A tu correo llegará la notificación cuando el cuadro de turnos esté
					listo.
				</p>
				{loadingData ? (
					<img
						src={loadImg}
						alt='Vista previa de cuadro'
						className='loading__icon load'></img>
				) : (
					<iframe className='preview__img' src={previewUrl} title='Previa'></iframe>
				)}
			</div>

			<form className='preview__tipoPago' ref={form} onSubmit={handleSubmit}>
				<h3 className='preview__subtitle'>Lo quiero</h3>

				{infoLogin.cuadroAdicional === false ? (
					<PreviewPlanList />
				) : (
					<div className={"preview__input-group"}>
						<label className='preview__input-group__label'>
							<input
								type='radio'
								name='planPago'
								className='preview__input-group__input'
								value={9.99}
								required></input>
							<span className='preview__input-group__label__info'>
								<span className='preview__input-group__label__infoData'>
									9.99 USD
									{9.99 * TRM[0] ? (
										<div className='controls'>
											<BsInfoCircleFill className='price__infoIcon' />
											<div className='info'>
												<small className='info__value'>
													{new Intl.NumberFormat("de-DE").format(
														Math.round(9.99 * TRM[0] + 9.99 * TRM[0] * IVA),
													)}
													{" COP"}
												</small>
												<small className='info__value small'>IVA incluido</small>
											</div>
										</div>
									) : null}
								</span>
							</span>
						</label>
					</div>
				)}

				<ReCAPTCHA
					className='preview__captcha'
					sitekey={captchaKey}
					onChange={HandleOnChange}
					theme={"dark"}
					ref={captcha}
				/>

				<div className='preview__input-group'>
					{infoLogin.userinfo?.subscription === "Incrédulo" ||
					infoLogin.userinfo?.subscription === "Urgencia" ||
					infoLogin.cuadroAdicional ? (
						<button className='button-primary preview__btn' disabled={isVerified}>
							{loading ? (
								<AiOutlineReload className='preview__loading__icon' />
							) : !free ? (
								"Descargar"
							) : (
								"Continuar con el pago"
							)}
						</button>
					) : (
						<button
							className='button-primary preview__btn'
							disabled={isVerified}
							onClick={handleDescarga}>
							{loading ? (
								<AiOutlineReload className='preview__loading__icon' />
							) : (
								"Descargar"
							)}
						</button>
					)}
				</div>
				{infoLogin.userinfo?.subscription === "Incrédulo" ||
				infoLogin.userinfo?.subscription === "Urgencia" ? (
					<p className='preview__note'>
						<span className='asterisco'>*</span> Todos nuestros precios incluyen IVA del
						19%
					</p>
				) : null}
			</form>
			{openFactura && <ModalDatosFacturacion valor={valorPagar} datos={infoLogin} />}

			{isError && (
				<Modals>
					<div className='paymentError'>
						<GoAlert className='paymentError__Icon' />
						<p className='paymentError__text'>{isError}</p>
					</div>
				</Modals>
			)}
			{error && (
				<ModalsUserAccount>
					<div className='paymentError'>
						<GoAlert className='paymentError__Icon' />
						<p className='paymentError__text'>
							Lo sentimos, ha ocurrido un error dandole sentido a tus datos
						</p>
						<p className='paymentError__text'>
							Por favor rectifícalos y vuelve a intentarlo
						</p>
						<Link to={"/dasensycuadroturnos"} className='button-primary' onClick={()=>setError(false)}>
							Volver a crear cuadro de turnos
						</Link>
					</div>
				</ModalsUserAccount>
			)}
		</section>
	);
}

import React, { useContext } from "react";
import ReactDOM from "react-dom";
import CaracterisiticasColaboradores from "../components/CaracterisiticasColaboradores";
import DatosTCuadro from "../components/DatosTCuadro";
import FlujoTrabajo from "../components/FlujoTrabajo";
import Restricciones from "../components/Restricciones";
import { AppContext } from "../Context/AppContext";

export default function ModalCuadroT() {
	const { nextModal } = useContext(AppContext);
	return ReactDOM.createPortal(
		<div className='modalCuadroT'>
			{nextModal === "caracteristicas" && <CaracterisiticasColaboradores />}
			{nextModal === "flujo" && <FlujoTrabajo />}
			{nextModal === "restricciones" && <Restricciones />}
			{nextModal === "confirm" && <DatosTCuadro />}
		</div>,
		document.getElementById("modalsTCuadros"),
	);
}

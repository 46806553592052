// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_DASENSY_CUADROTURNOS_AUTH_APIKEY,
	authDomain: process.env.REACT_APP_DASENSY_CUADROTURNOS_AUTH_AUTHDOMAIN,
	projectId: process.env.REACT_APP_DASENSY_CUADROTURNOS_AUTH_PROJECTID,
	storageBucket: process.env.REACT_APP_DASENSY_CUADROTURNOS_AUTH_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_DASENSY_CUADROTURNOS_AUTH_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_DASENSY_CUADROTURNOS_AUTH_APPID,
	measurementId: process.env.REACT_APP_DASENSY_CUADROTURNOS_AUTH_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const auth = getAuth(app)
export const db = getFirestore(app);
export default app;

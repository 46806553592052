
import AppUI from './routes/AppUI';
import { AppProvider } from './Context/AppContext';

function App() {
  return (
    <AppProvider>
      <AppUI />
		</AppProvider>

  );
}

export default App;

import React, { useContext, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import AvisoPrivacidad from "./AvisoPrivacidad";
import Politics from "./Politics";

export default function FormTerms() {
	const { setOpenModal, setModal } = useContext(AppContext);

	
	useLayoutEffect(() => {
		const term = document.getElementById("checkTerms");
		
		term.addEventListener("invalid", function (e) {
			e.target.setCustomValidity("");
			if (!e.target.validity.valid) {
				e.target.setCustomValidity("Por favor acepta los Terminos y Condiciones");
			}
		});

	}, []);

	return (
		<div className='form__terms'>
			<input
				type='checkbox'
				className='form__terms__checkbox'
				name='check-terms'
				required
				id='checkTerms'></input>
			<p className='form__terms__text'>
				Acepto los{" "}
				<Link
					to='#'
					className='form__terms__link'
					onClick={() => {
						setOpenModal(true);
						setModal(<AvisoPrivacidad />);
					}}>
					{" "}
					Terminos de Uso{" "}
				</Link>
				y las{" "}
				<Link
					to='#'
					className='form__terms__link'
					onClick={() => {
						setOpenModal(true);
						setModal(<Politics />);
					}}>
					{" "}
					Politicas de tratamiento de datos
				</Link>
			</p>
		</div>
	);
}

import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { FiAlertCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../Context/AppContext";

function ModalLog({ children }) {
	const { setOpenModal, setErrorLog } = useContext(AppContext);
	const navigate = useNavigate();

	return ReactDOM.createPortal(
		<section className='modal'>
			<article className='modal__content'>
				<h3 className='reminder__title'>
					<FiAlertCircle className='reminder__alertIcon' /> Información
				</h3>

				{children}

				<button
					className='button-primary'
					onClick={() => {
						setOpenModal(false);
						setErrorLog("");
						navigate("/")
					}}>
					Aceptar
				</button>
			</article>
		</section>,
		document.getElementById("modals"),
	);
}

export { ModalLog };

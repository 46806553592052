import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RiHomeSmile2Line } from "react-icons/ri";
import { VscTelescope } from "react-icons/vsc";
import { MdOutlineSportsScore, MdStarBorder, MdOutlineBook } from "react-icons/md";
import { scroller, animateScroll as scroll } from "react-scroll";
import { AppContext } from "../../Context/AppContext";

/*-----------Funcion smooth scroll ------------------*/
const scrollType = {
	duration: 500,
	delay: 50,
	smooth: true,
	offset: -135,
};
const onClickDown = (id) => {
	scroller.scrollTo(id, scrollType);
};
const onClickUp = () => {
	scroll.scrollToTop();
};


export default function NavListAboutUs() {

    const { setOpenMenu } = useContext(AppContext);
	return (
		<>
			<li className='menu__list'>
				<Link
					to='/'
					className='menu__list__link'
					onClick={() =>{
						onClickUp()
						setOpenMenu(false)}
					}>
					<RiHomeSmile2Line className='link__icon' />
					Inicio
				</Link>
			</li>
			<li className='menu__list'>
				<div className='menu__list__link' onClick={() => onClickDown("mision")}>
					<MdOutlineSportsScore className='link__icon' />
					Mision
				</div>
			</li>
			<li className='menu__list'>
				<div className='menu__list__link' onClick={() => onClickDown("vision")}>
					<VscTelescope className='link__icon' />
					Vision
				</div>
			</li>
			<li className='menu__list'>
				<div
					to='/aboutus'
					className='menu__list__link'
					onClick={() => onClickDown("value")}>
					<MdStarBorder className='link__icon' />
					Propuesta de valor
				</div>
			</li>
			<li className='menu__list'>
				<div className='menu__list__link' onClick={() => onClickDown("philosophy")}>
					<MdOutlineBook className='link__icon' />
					<span className='login__label'>Filosofía</span>
				</div>
			</li>
		</>
	);
}

import React, {useContext} from 'react'
import Contact from '../containers/Contact'
import { AppContext } from '../Context/AppContext';

export default function ContactUs() {
  const { useScrollToTop } = useContext(AppContext);
	useScrollToTop();
  return (
    <Contact />
  )
}

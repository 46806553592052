import React, { useContext, useEffect} from 'react'
import Portada from '../components/Portada';
import UserAvatar from '../components/UserAvatar';
import UserInfo from '../components/UserInfo';
import { AppContext } from '../Context/AppContext';
import backgroundImgend from "../assets/img/AboutUs/icononly_aboutus.png";
import backgroundImg from "../assets/icons/icononly_transparent_nobuffer.png";
import { useLocation, useNavigate } from 'react-router-dom';

export default function UserAcount() {
	const {
		getDatosPagos,
		infoLogin,
		estadoPago,
		tCuadros,
		sendMailCuadro,
		redirect,
		updateDasPlanCount,
		setEstadoPago,
		datosFactura,
	} = useContext(AppContext);
	const location = useLocation();
	const navigate = useNavigate()
    useEffect(() => {
		if (location.search !== "") {
			//Creamos la instancia
			const urlParams = new URLSearchParams(location.search);
			
			//Accedemos a los valores
			const id = urlParams.get("id");
			console.log(id)
			getDatosPagos(id);
			navigate("/useracount")
		}
	}, [location]);
    
    const { getCuadrosdeTurnos,useScrollToTop, sendVerificationEmail } = useContext(AppContext);
    useScrollToTop();
    return (
        <main className='userAccount'>
            <button onClick={sendVerificationEmail}>Consultar</button>
            <img src={backgroundImg} className='backgroundUser__img__start' alt='background img' />
            <img src={backgroundImgend} className='backgroundUser__img__end' alt='background img' />
            <Portada/>
            <UserAvatar/>
            <UserInfo />
        </main>
    )
}

import React, { useState } from "react";
import { SliderData } from "./SliderData";
import { BsTable } from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";

const Slider = ({ slides }) => {
	const [current, setCurrent] = useState(0);
	const length = SliderData.length;

	const nextSlide = () => {
		setCurrent(current === length - 1 ? 0 : current + 1);
	};

	const prevSlide = () => {
		setCurrent(current === 0 ? length - 1 : current - 1);
	};

	if (!Array.isArray(SliderData) || SliderData.length <= 0) {
		return null;
	}

	return (
		<section className='slider'>
			<IoIosArrowDropleft className='left-arrow' onClick={prevSlide} />
			<IoIosArrowDropright className='right-arrow' onClick={nextSlide} />
			<div className='slider__content'>
				{SliderData.map((slide, index) => {
					return (
						<div className={index === current ? "slide__active" : "slide"} key={index}>
							{index === current && (
								<>
									<h4 className='service__info__title'>{slide.service}</h4>
									<img src={slide.image} className='home__service__img' alt='Service' />
									<p className='service__info__description'>{slide.info}</p>
									{slide.service === "Cuadro de turnos" ?
									<Link to={slide.link} className='button-primary'>
										{slide.button}
									</Link>
										:
									<a href={slide.link} target={"_blank"} rel="noreferrer" className='button-primary'>
										{slide.button}
									</a>
									}
								</>
							)}
						</div>
					);
				})}
			</div>
			<div className='slider__bullets'>
				<div
					className={`service__badge ${current === 0 && "badge__active"}`}
					onClick={() => setCurrent(0)}>
					<BsTable className='service__badge__icon' />
					<span className='service__badge__text'>Cuadros de turnos</span>
				</div>
				<div
					className={`service__badge ${current === 1 && "badge__active"}`}
					onClick={() => setCurrent(1)}>
					<GiTeacher className='service__badge__icon' />
					<span className='service__badge__text'>Consultoria especializada</span>
				</div>
				<div
					className={`service__badge ${current === 2 && "badge__active"}`}
					onClick={() => setCurrent(2)}>
					<HiOutlineDocumentReport className='service__badge__icon' />
					<span className='service__badge__text'>Ciencia de datos</span>
				</div>
			</div>
		</section>
	);
};

export default Slider;

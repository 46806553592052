import React, { useContext, useEffect, useRef, useState } from "react";
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";
import { RiCloseCircleLine } from "react-icons/ri";
import { AppContext } from "../Context/AppContext";

const list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const listIncrédulo = [0, 1, 2, 3, 4, 5];

export default function CaracterisiticasColaboradores() {
	const {
		setNextModal,
		saveTCuadros,
		infoLogin,
		isError,
		setIsError,
		editModal,
		setEditModal,
		// addFeatures,
		tCuadros,
	} = useContext(AppContext);
	const form = useRef(null);
	const carDatos = tCuadros.caracteristicas;

	const [horasContrato, sethorasContrato] = useState();
	const [listaHContrato, setlistaHContrato] = useState([1, 1]);
	const [listNcolaborators, setlistNcolaborators] = useState([]);
	const [permiso, setPermiso] = useState();
	useEffect(() => {
		infoLogin.userinfo?.subscription === "Incrédulo"
			? setlistNcolaborators(listIncrédulo)
			: setlistNcolaborators(list);
		if (infoLogin.userinfo?.subscription === "Incrédulo") {
			setPermiso(false)
			sethorasContrato("si")
		}else { setPermiso(true); }
	}, [infoLogin.userinfo?.subscription]);
	useEffect(() => {
		setTimeout(() => {
			setIsError("");
		}, 8000);
	}, [isError]);

	const handleChange = (e) => {
		sethorasContrato(e.target.value);
	};

	const handleHorasContrato = () => {

		setlistaHContrato([...listaHContrato, listaHContrato.length + 1]);
	};
	const handleHorasContratoMinus = () => {
		console.log("elimino");
		listaHContrato.pop();
		setlistaHContrato([...listaHContrato]);
	};
	
	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = new FormData(form.current);
		const timestamp = Date.now();
		const createdAt = new Date(timestamp);
		const total = parseInt(formData.get("colaboradores"));
		const nombreEquipo = formData.get("nombreEquipo").replace(/ /g, "");

		if (formData.get("horasContrato") === "si" ||infoLogin.userinfo?.subscription === "Incrédulo") {
			
			const anyTime = parseInt(formData.get("jornadaCompleta"));
			const onlyAM = parseInt(formData.get("jornadaManana"));
			const onlyPM = parseInt(formData.get("jornadaTarde"));
			const onlyNight = parseInt(formData.get("jornadaNoche"));
			var sum = anyTime + onlyAM + onlyNight + onlyPM;
		}

		if (infoLogin.userinfo?.subscription === "Incrédulo") {
			var contrato = true;
			var horasMes = 207;
			var nColaboradores = total;
		} else {
			contrato = formData.get("horasContrato") === "si" ? true : false;
			horasMes = parseInt(formData.get("horasMes"));
			nColaboradores = parseInt(formData.get("colaboradores"));
		}

		if (contrato) {
			if (total > 0 && sum === total) {
				const data = {
					uid: infoLogin.uid,
					createdAt,
					idCuadro: timestamp,
					nombreEquipo,
					anyTime: parseInt(formData.get("jornadaCompleta")),
					hoursContract: {
						horasMes: horasMes,
						nColaboradores: nColaboradores,
					},
					onlyAM: parseInt(formData.get("jornadaManana")),
					onlyPM: parseInt(formData.get("jornadaTarde")),
					onlyNight: parseInt(formData.get("jornadaNoche")),
					sameHours:formData.get("horasContrato") === "si" || contrato === true ? true : false,
					total: parseInt(formData.get("colaboradores")),
					subs: infoLogin.userinfo?.subscription,
				};
				return (
					// addFeatures(e, data),
					saveTCuadros(
						editModal
							? { ...tCuadros, caracteristicas: data }
							: { caracteristicas: data },
					),
					setNextModal(editModal ? "confirm" : "flujo"),
					setEditModal(false)
				);
			} else {
				setIsError(
					`Por favor revisa los datos ingresados, ${
						sum > total
							? "te sobra algún colaborador asignado"
							: "te falta algún colaborador por asignar"
					} en contratación`,
				);
			}
		} else {
			const array = formData.getAll("cargaHoraria");
			const array2 = formData.getAll("cargaHorariaPersona");
			const arrayAnyTime = formData.getAll("jornadaCompleta");
			const arrayOnlyAM = formData.getAll("jornadaManana");
			const arrayOnlyPM = formData.getAll("jornadaTarde");
			const arrayOnlyNight = formData.getAll("jornadaNoche");
			let sumany =0
			let sumam =0
			let sumpm =0
			let sumnight=0
			let subtotal = 0;
			array2.forEach(function (a) {
				subtotal += parseInt(a);
			});
			arrayAnyTime.forEach(function (a) {
				sumany += parseInt(a);
			});
			arrayOnlyAM.forEach(function (a) {
				sumam += parseInt(a);
			});
			arrayOnlyPM.forEach(function (a) {
				sumpm += parseInt(a);
			});
			arrayOnlyNight.forEach(function (a) {
				sumnight += parseInt(a);
			});

			const sub = sumany+ sumam+sumpm+sumnight

			if (total === subtotal) {
				let myObj = {};

				for (let i = 0; i < array.length; i++) {
					let foo = "Contrato" + (i + 1)
						// let foo = "horasMes",
					let	bar = parseInt(array[i]);
					let bar2 = parseInt(array2[i]);
					let bar3 = parseInt(arrayAnyTime[i]);
					let bar4 = parseInt(arrayOnlyAM[i]);
					let bar5 = parseInt(arrayOnlyPM[i]);
					let bar6 = parseInt(arrayOnlyNight[i]);
					// let foo2 = "nColaboradoresHorasMes" + (i + 1),
					// let foo3 = "anyTime",

					myObj[foo] = {
						horasMes: bar,
						nColaboradores: bar2,
						anyTime: bar3,
						onlyAM: bar4,
						onlyPM: bar5,
						onlyNight: bar6,
					};
				}

				if (total === sub) {
					
					const data = {
						uid: infoLogin.uid,
						createdAt,
						idCuadro: timestamp,
						nombreEquipo,
						hoursContract: myObj,
						sameHours:formData.get("horasContrato") === "si" || contrato === true ? true : false,
						total: parseInt(formData.get("colaboradores")),
						subs: infoLogin.userinfo?.subscription,
						// anyTime: parseInt(formData.get("jornadaCompleta")),
						// onlyAM: parseInt(formData.get("jornadaManana")),
						// onlyPM: parseInt(formData.get("jornadaTarde")),
						// onlyNight: parseInt(formData.get("jornadaNoche")),
					};
					return (
						// addFeatures(e, data),
						saveTCuadros(
							editModal
								? { ...tCuadros, caracteristicas: data }
								: { caracteristicas: data },
						),
						setNextModal(editModal ? "confirm" : "flujo"),
						setEditModal(false)
					);
				}else {
					setIsError(
						`Por favor revisa los datos ingresados, ${
							sum > total
								? "te sobra algún colaborador asignado"
								: "te falta algún colaborador por asignar"
						} en contratación`,
					);
				}

			} else {
				setIsError(
					`Por favor revisa los datos ingresados, ${
						sum > total
							? "te sobra algún colaborador asignado"
							: "te falta algún colaborador por asignar"
					} en contratación`,
				);
			}
		}
	};

	return (
		<form className='features' onSubmit={handleSubmit} ref={form}>
			<h2 className='features__title'>
				Hablanos de tu grupo de trabajo
				<RiCloseCircleLine
					className='features__closeIcon'
					onClick={() => setNextModal("")}
				/>
			</h2>

			<div className='features__input-group'>
				<label className='features__input-group__label'>
					Nombra a tu equipo
					<input
						type='text'
						id='nombreEquipo'
						name={"nombreEquipo"}
						className='nombreEquipo'
						required
					/>
				</label>
			</div>

			<div className='features__input-group'>
				<label className='features__input-group__label'>
					¿Cuál es el tamaño total (en personas) que necesita programar para este mes?
				</label>
				<select required className='features__input-group__input' name='colaboradores'>
					<option
						className='features__input-group__option'
						value={carDatos?.total ? carDatos?.total : 0}>
						{carDatos?.total ? carDatos?.total : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='features__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>

			{permiso && (
				<div className='features__input-group'>
					<label className='features__input-group__label'>
						La contratación de horas al mes es igual para todos?
					</label>
					<select
						required
						className='features__input-group__input'
						name='horasContrato'
						onChange={handleChange}>
						<option
							className='features__input-group__option'
							value={carDatos?.sameHours ? carDatos?.sameHours : ""}>
							{carDatos?.sameHours ? carDatos?.sameHours : "Seleccione ..."}
						</option>
						<option className='features__input-group__option' value={"si"}>
							Si
						</option>
						<option className='features__input-group__option' value={"no"}>
							No
						</option>
					</select>
				</div>
			)}

			{horasContrato === "si" && (
				<>
					<div className='features__input-group'>
						<label className='features__input-group__label'>
							¿Cuántas horas al mes tienen contratadas?
							<input
								type='number'
								id='horasMes'
								name='horasMes'
								className='cargaHoraria'
								required
								min='0'
								max='360'
								maxLength={3}
								defaultValue={
									carDatos?.sameHours ? carDatos?.hoursContract?.horasMes : 207
								}
							/>
						</label>
					</div>

					<div className='features__input-group'>
						<label className='features__input-group__label'>
							¿Cuántos trabajan en cualquier jornada, es decir, mañana, tarde, corridos y
							noches?
						</label>
						<select
							required
							className='features__input-group__input'
							name='jornadaCompleta'>
							<option
								className='features__input-group__option'
								value={carDatos?.anyTime ? carDatos?.anyTime : 0}>
								{carDatos?.anyTime ? carDatos?.anyTime : 0}
							</option>
							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='features__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div className='features__input-group'>
						<label className='features__input-group__label'>
							¿Cuántos solo trabajan en las mañanas?
						</label>
						<select
							required
							className='features__input-group__input'
							name='jornadaManana'>
							<option
								className='features__input-group__option'
								value={carDatos?.onlyAM ? carDatos?.onlyAM : 0}>
								{carDatos?.onlyAM ? carDatos?.onlyAM : 0}
							</option>
							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='features__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div className='features__input-group'>
						<label className='features__input-group__label'>
							¿Cuántos solo trabajan en las tardes?
						</label>
						<select required className='features__input-group__input' name='jornadaTarde'>
							<option
								className='features__input-group__option'
								value={carDatos?.onlyPM ? carDatos?.onlyPM : 0}>
								{carDatos?.onlyPM ? carDatos?.onlyPM : 0}
							</option>
							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='features__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div className='features__input-group'>
						<label className='features__input-group__label'>
							¿Cuántos solo trabajan en las noches?
						</label>
						<select required className='features__input-group__input' name='jornadaNoche'>
							<option
								className='features__input-group__option'
								value={carDatos?.onlyNight ? carDatos?.onlyNight : 0}>
								{carDatos?.onlyNight ? carDatos?.onlyNight : 0}
							</option>
							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='features__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
				</>
			)}

			{horasContrato === "no" && (
				<>
					<label className='features__input-group__label addColaborador'>
						<span className='features__input-group__text'>
							Eliminar Contratación
							<BiMinusCircle
								className='restriction__addIcon'
								onClick={handleHorasContratoMinus}
							/>
						</span>
						<span className='features__input-group__text'>
							Agregar otra(s) Contratación
							<BiPlusCircle className='features__addIcon' onClick={handleHorasContrato} />
						</span>
					</label>
					{listaHContrato.map((doc, index) => {
						return (
							<div
								className='features__input-group diferenciaHoraria'
								key={index}
								id={doc}>
								<label className={"features__input-group__label Pregunta"}>
									Carga horaria mensual
									<input
										type='number'
										id='cargaHoraria'
										name={`cargaHoraria`}
										className='cargaHoraria'
										required
										min='0'
										max='360'
										maxLength={3}
										defaultValue={207}
									/>
								</label>
								<label
									className={`features__input-group__label Pregunta ${
										isError !== "" && "features__error"
									}`}>
									Número de personas con esa carga horaria
									<select
										required
										className='features__input-group__input'
										name={`cargaHorariaPersona`}>
										<option className='features__input-group__option' value={0}>
											0
										</option>
										{listNcolaborators.map((item, index) => {
											return (
												<option
													key={index}
													className='features__input-group__option'
													value={`${item}`}>
													{item}
												</option>
											);
										})}
									</select>
								</label>

								<div className='features__input-group'>
									<label className='features__input-group__label'>
										¿Cuántos trabajan en cualquier jornada, es decir, mañana, tarde,
										corridos y noches?
									</label>
									<select
										required
										className='features__input-group__input'
										name='jornadaCompleta'>
										<option
											className='features__input-group__option'
											value={carDatos?.anyTime ? carDatos?.anyTime : 0}>
											{carDatos?.anyTime ? carDatos?.anyTime : 0}
										</option>
										{listNcolaborators.map((item, index) => {
											return (
												<option
													key={index}
													className='features__input-group__option'
													value={`${item}`}>
													{item}
												</option>
											);
										})}
									</select>
								</div>
								<div className='features__input-group'>
									<label className='features__input-group__label'>
										¿Cuántos solo trabajan en las mañanas?
									</label>
									<select
										required
										className='features__input-group__input'
										name='jornadaManana'>
										<option
											className='features__input-group__option'
											value={carDatos?.onlyAM ? carDatos?.onlyAM : 0}>
											{carDatos?.onlyAM ? carDatos?.onlyAM : 0}
										</option>
										{listNcolaborators.map((item, index) => {
											return (
												<option
													key={index}
													className='features__input-group__option'
													value={`${item}`}>
													{item}
												</option>
											);
										})}
									</select>
								</div>
								<div className='features__input-group'>
									<label className='features__input-group__label'>
										¿Cuántos solo trabajan en las tardes?
									</label>
									<select
										required
										className='features__input-group__input'
										name='jornadaTarde'>
										<option
											className='features__input-group__option'
											value={carDatos?.onlyPM ? carDatos?.onlyPM : 0}>
											{carDatos?.onlyPM ? carDatos?.onlyPM : 0}
										</option>
										{listNcolaborators.map((item, index) => {
											return (
												<option
													key={index}
													className='features__input-group__option'
													value={`${item}`}>
													{item}
												</option>
											);
										})}
									</select>
								</div>
								<div className='features__input-group endBlock'>
									<label className='features__input-group__label'>
										¿Cuántos solo trabajan en las noches?
									</label>
									<select
										required
										className='features__input-group__input'
										name='jornadaNoche'>
										<option
											className='features__input-group__option'
											value={carDatos?.onlyNight ? carDatos?.onlyNight : 0}>
											{carDatos?.onlyNight ? carDatos?.onlyNight : 0}
										</option>
										{listNcolaborators.map((item, index) => {
											return (
												<option
													key={index}
													className='features__input-group__option'
													value={`${item}`}>
													{item}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						);
					})}
				</>
			)}
			{isError !== "" && <div className='msg__error__workflow'>{isError}</div>}
			<div className='features__btn'>
				<button className='button-primary'>Continuar</button>
			</div>
		</form>
	);
}

import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../Context/AppContext";

export default function Modaluserinfo() {
	const { setOpenModalUser, userSecondaryInfo, setIsError, infoLogin } =
		useContext(AppContext);

	const form = useRef(null);

	const onSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(form.current);
		const userData = {
			uid: infoLogin.uid,
			birthDate: formData.get("birthDate"),
			profession: formData.get("profession"),
			gender: formData.get("gender"),
			company: formData.get("company"),
			employes: formData.get("employes"),
			numberEmployes: formData.get("numberEmployes"),
			country: formData.get("country"),
			typeCare: formData.get("care"),
			complex: formData.get("complex"),
			patients: formData.get("patient"),
		};
		userSecondaryInfo(userData);
	};

	return (
		<>
			<h2 className='userInfo__modal__title'>Editar Informacion Personal</h2>

			<form
				className='userInfo__modal__form'
				id='userInfo__modal__form'
				onSubmit={onSubmit}
				ref={form}>
				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='userDate'>
						Fecha de Nacimiento
					</label>
					<input
						className='userInfo__modal__input input__list'
						type='date'
						name='birthDate'
						id='userDate'

					/>
				</div>

				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='userGender'>
						Sexo
					</label>
					<select
						className='userInfo__modal__input input__list'
						name='gender'
						id='userGender'
						// value={infoLogin.userinfo?.secondaryInfo?.gender?infoLogin.userinfo?.secondaryInfo?.gender:""}

					>
						<option value='Hombre'>Hombre</option>
						<option value='Mujer'>Mujer</option>
						<option value='Prefiero no decirlo'>Prefiero no decirlo</option>
					</select>
				</div>

				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='userProfession'>
						Profesión
					</label>
					<input
						className='userInfo__modal__input'
						type='text'
						name='profession'
						id='userProfession'
						placeholder='Ejm: Internista, Neurologo'
						// value={infoLogin.userinfo?.secondaryInfo?.profession?infoLogin.userinfo?.secondaryInfo?.profession:""}

					/>
				</div>

				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='userCountry'>
						Escribe tu Nacionalidad
					</label>
					<input
						className='userInfo__modal__input'
						type='text'
						name='country'
						id='userCountry'
						placeholder='Colombia'
						// value={infoLogin.userinfo?.secondaryInfo?.country?infoLogin.userinfo?.secondaryInfo?.country:""}

					/>
				</div>

				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='usercompany'>
						Tipo Institución donde labora
					</label>
					<input
						className='userInfo__modal__input'
						type='text'
						name='company'
						id='usercompany'
						placeholder='ejem: público, privado'
						// value={infoLogin.userinfo?.secondaryInfo?.company?infoLogin.userinfo?.secondaryInfo?.company:""}

					/>
				</div>

				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='usercompany'>
						Personal a Cargo
					</label>
					<input
						className='userInfo__modal__input'
						type='text'
						name='employes'
						id='useremployes'
						placeholder=''
						// value={infoLogin.userinfo?.secondaryInfo?.employes?infoLogin.userinfo?.secondaryInfo?.employes:""}

					/>
				</div>
				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='numberEmployes'>
						Cantidad de Colaboradores
					</label>
					<select
						className='userInfo__modal__input input__list'
						name='numberEmployes'
						id='usernumberEmployes'
						// value={infoLogin.userinfo?.secondaryInfo?.numberEmployes?infoLogin.userinfo?.secondaryInfo?.numberEmployes:""}

					>
						<option value='< 10'>{"< 10"}</option>
						<option value='10-50'>10-50</option>
						<option value='51-100'>51-100</option>
						<option value='101-500'>101-500</option>
						<option value='501-1000'>501-1000</option>
						<option value='>1000'>{">1000"}</option>
					</select>
				</div>
				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='care'>
						Atención
					</label>
					<select
						className='userInfo__modal__input input__list'
						name='care'
						id='usercare'
						// value={infoLogin.userinfo?.secondaryInfo?.typeCare?infoLogin.userinfo?.secondaryInfo?.typeCare:""}

					>
						<option value='Ambulatoria'>Ambulatoria</option>
						<option value='Hospitalaria'>Hospitalaria</option>
						<option value='Ambas'>Ambas</option>
					</select>
				</div>
				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='complex'>
						Nivel de complejidad
					</label>
					<select
						className='userInfo__modal__input input__list'
						name='complex'
						id='userComplex'
						// value={infoLogin.userinfo?.secondaryInfo?.complex?infoLogin.userinfo?.secondaryInfo?.complex:""}

					>
						<option value='Alto'>Alto</option>
						<option value='Medio'>Medio</option>
						<option value='Bajo'>Bajo</option>
					</select>
				</div>
				<div className='userInfo__modal__w50'>
					<label className='userInfo__modal__label' for='patient'>
						Pacientes atendidos por mes
					</label>
					<input
						className='userInfo__modal__input'
						type='text'
						name='patient'
						id='patient'
						placeholder=''
						// value={infoLogin.userinfo?.secondaryInfo?.patients?infoLogin.userinfo?.secondaryInfo?.patients:""}
					/>
				</div>

				<div className='userInfo__modal__w100 userInfo__modal__boxBtn'>
					<button
						type='submit'
						value='Guardar'
						className='button-primary'
						id='SocialBtnSave'>
						Guardar
						<i className='fas fa-paper-plane'></i>
					</button>
					<button
						className='button-secondary userInfo__modal__Btn'
						onClick={() => {
							setOpenModalUser(false);
							setIsError("");
						}}>
						Cancelar
					</button>
				</div>
			</form>
		</>
	);
}

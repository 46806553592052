import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { RiCloseCircleLine } from "react-icons/ri";
import { AppContext } from "../Context/AppContext";

function Modals({ children }) {
	const { setOpenModal, setIsError } = useContext(AppContext);

	return ReactDOM.createPortal(
		<section className='modal'>
			<article className='modal__content'>
				<RiCloseCircleLine
					className='modal__closeIcon'
					onClick={() => {
						setOpenModal(false)
						setIsError("")
					}}
				/>
				{children}

				<button className='button-primary' onClick={() =>
				{
				setOpenModal(false)
				setIsError("")
				}	
				}>
					Aceptar
				</button>
			</article>
		</section>,
		document.getElementById("modals"),
	);
}

export { Modals };

import React, { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { BiLogOut } from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import { RiUserSettingsLine } from "react-icons/ri";
import { MdOutlineSupportAgent, MdTableView } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

export default function UserLIst() {
	const { userLogOut, setOpenMenu, setSubmenu} = useContext(AppContext);
	const navigate = useNavigate()
	const handleLogOut = (e) => {
		e.preventDefault();
		userLogOut();
		navigate("/")
	};


	return (
		<>
			<li className='submenu__list'>
				<Link to='/useracount' className='submenu__list__link'>
					<RiUserSettingsLine className='submenu__icon' />
					Mi perfil
				</Link>
			</li>
			<li className='submenu__list'>
				<Link
					to='/dasensyusercuadroturnos'
					className='submenu__list__link'
					onClick={(e) => {
						setSubmenu("");
						setOpenMenu(false);
					}}>
					<MdTableView className='submenu__icon' />
					Mis Cuadros de Turnos
				</Link>
			</li>
			{/* <li className='submenu__list'>
				<Link to='#' className='submenu__list__link'>
					<RiUserAddLine className='submenu__icon' />
					Mi suscripción
				</Link>
			</li> */}
			<li className='submenu__list'>
				<Link to='/contactus' className='submenu__list__link'
				onClick={(e) => {
						setSubmenu("");
						setOpenMenu(false);
					}}>
					<MdOutlineSupportAgent className='submenu__icon' />
					Soporte
				</Link>
			</li>
			<li className='submenu__list'>
				<Link to='/faq' className='submenu__list__link'
				onClick={(e) => {
						setSubmenu("");
						setOpenMenu(false);
					}}>
					<BsQuestionCircle className='submenu__icon' />
					Preguntas
				</Link>
			</li>
			<li
				className='submenu__list'
				onClick={(e) => {
					handleLogOut(e);
					setOpenMenu(false);
					setSubmenu("")
				}}>
				<BiLogOut className='submenu__icon' />
				Log out
			</li>
		</>
	);
}

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import previaTCuadro from "../assets/img/dataScience.png";
import { getStorage, ref, getDownloadURL, list } from "firebase/storage";
import Loading from "../components/Loading";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import loadImg from "../assets/icons/icononly_transparent_nobuffer.png";


var meses = [
	"Enero",
	"Febrero",
	"Marzo",
	"Abril",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre",
];

export default function UserTCuadros() {
	const {
		getDatosPagos,
		infoLogin,
		estadoPago,
		tCuadros,
		sendMailCuadro,
		updateDasPlanCount,
		authlogsesion
	} = useContext(AppContext);
	const location = useLocation();
	const [estadoCuadro, setEstadoCuadro] = useState("Procesando");
	const [estadoDownload, setEstadoDownload] = useState(false);
	const [cuadroList, setCuadroList] = useState([]);
	const [downloadUrl, setDownloadUrl] = useState(null);
	const [Url, setUrl] = useState(null);

	const [load, setLoad] = useState(false);
	const [message, setMessage] = useState("");
	let cargaCuadro = 0;
	const [mes, setMes] = useState();
	const storage = getStorage();
	const navigate = useNavigate();

	const getCuadros = async () => {
		cargaCuadro = cargaCuadro + 1;
		const q = query(
			collection(db, "dasCreate"),
			where("uid", "==", infoLogin.uid),
			where("pagado", "==", true),
		);

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (doc) => {
			// doc.data() is never undefined for query doc snapshots
			const carpeta = doc.data().carpeta;
			// console.log(doc.id, " => ", doc.data());
			const userUid = infoLogin.uid;
			const gsReference = ref(
				storage,`gs://dasensy-94a04.appspot.com/tcuadro/${userUid}/${carpeta}/cuadro.xlsx`,
			);
			const url = await getDownloadURL(gsReference);

			cuadroList.push({ data:doc.data(),url });
		});
		setTimeout(() => {
			setEstadoDownload(true);
		}, 3000);
	};

	useEffect(() => {
		const mm = tCuadros.dasCreate?.startMonth;
		const datoMes = meses[mm - 1];
		setMes(datoMes);
		const data = {
			uid: infoLogin.uid
		}
		authlogsesion(data)
	}, []);

	useEffect(() => {
		if (location.search !== "") {
			//Creamos la instancia
			const urlParams = new URLSearchParams(location.search);

			//Accedemos a los valores
			const id = urlParams.get("id");

			getDatosPagos(id);
			navigate("/dasensyusercuadroturnos");
		}

		const getLink = async () => {
			const q = query(
				collection(db, "dasCreate"),
				where("uid", "==", infoLogin.uid),
				where("carpeta", "==", tCuadros.dasCreate?.carpeta),
				where("pagado", "==", true),
			);
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				// console.log("Obteniendo link");
				handleDownload(tCuadros.dasCreate?.carpeta, "actual");
			});
		};
		getLink();
	}, [location]);

	useEffect(() => {
		if (estadoPago === false) {
			updateDasPlanCount(false);
			navigate("/dasensycuadroturnospreview");
		}
	}, [estadoPago]);

	useEffect(() => {
		if (cargaCuadro < 1) {
			getCuadros();
		}
	}, []);

	const handleDownload = async (data, origen) => {
		const userUid = infoLogin.uid;
		const gsReference = ref(
			storage,
			`gs://dasensy-94a04.appspot.com/tcuadro/${userUid}/${data}/cuadro.xlsx`,
		);
		if (origen === "actual") {
			getDownloadURL(gsReference)
				.then((url) => {
					// `url` is the download URL
					setEstadoCuadro("Finalizado");
					setDownloadUrl(url);
				})
				.catch((error) => {
					setEstadoCuadro("Procesando");
				});
		} else {
			getDownloadURL(gsReference).then((url) => {
				// `url` is the download URL
			});
		}
	};

	const handleSendMail = (url) => {
		setMessage("Enviando correo");
		setLoad(true);
		const data = {
			firstName: infoLogin.userinfo?.firstName,
			email: infoLogin.userinfo?.email,
			file: url,
			subs: infoLogin.userinfo?.subscription,
		};
		sendMailCuadro(data);
		setTimeout(() => {
			setMessage("");
			setLoad(false);
		}, 2000);
	};

	return (
		<>
			<main className='userTcuadros'>
				<h1 className='userTcuadros__title'>Estado Cuadro de Turnos Actual</h1>
				<section className='cuadroActual'>
					<div className='cuadroActual__imgContainer'>
						<img
							src={previaTCuadro}
							className='cuadroActual__img'
							alt='Cuadro Turnos'></img>
					</div>
					<div className='cuadroActual__info'>
						<h3 className='cuadroActual__subtitle'>Detalles Generales de tu cuadro</h3>
						<label className='cuadroActual__info__label'>
							Mes <span className='cuadroActual__info__labelDato'>{mes}</span>
						</label>
						<label className='cuadroActual__info__label'>
							Colaboradores asignados{" "}
							<span className='cuadroActual__info__labelDato'>
								{tCuadros.caracteristicas?.total}
							</span>
						</label>
						<label className='cuadroActual__info__label'>
							Horas Contratadas iguales?{" "}
							<span className='cuadroActual__info__labelDato'>
								{tCuadros.caracteristicas?.sameHours ? "Si" : "No"}
							</span>
						</label>
					</div>
					<div className='cuadroActual__controls'>
						<button className='button-primary cuadroActual__btn'>
							{estadoCuadro}
						</button>
						{estadoCuadro === "Finalizado" && (
							<>
								<a
									href={downloadUrl}
									className='button-primary cuadroActual__btn'
									onClick={() => handleDownload(tCuadros.dasCreate?.carpeta, "actual")}
									onMouseEnter={() =>
										handleDownload(tCuadros.dasCreate?.carpeta, "actual")
									}>
									Descargar
								</a>
								<button
									className='button-secondary cuadroActual__btnEmail'
									onClick={()=>handleSendMail(downloadUrl)}>
									Enviar al correo
								</button>
							</>
						)}
					</div>
				</section>

				<section className='userTcuadros__historic'>
					<h1 className='userTcuadros__title'>Mis cuadros históricos</h1>

					{estadoDownload === true ? (
						<>
							{cuadroList.map((cuadro, index) => {
								return (
									<article className='cuadroActual historic' key={index}>
										<div className='cuadroActual__imgContainer historic__imgContainer'>
											<img
												src={previaTCuadro}
												className='cuadroActual__img'
												alt='Cuadro Turnos'></img>
										</div>
										<div className='cuadroActual__info historic__info'>
											<h3 className='cuadroActual__subtitle'>
												Detalles Generales de tu cuadro
											</h3>
											<label className='cuadroActual__info__label historic__label'>
												Nombre del equipo{" "}
												<span className='cuadroActual__info__labelDato historic__label'>
													{cuadro.data?.idGrupo}
												</span>
											</label>
											<label className='cuadroActual__info__label historic__label'>
												Mes{" "}
												<span className='cuadroActual__info__labelDato historic__label'>
													{meses[cuadro.data?.mmi-1]}
												</span>
											</label>
											<label className='cuadroActual__info__label historic__label'>
												Colaboradores asignados{" "}
												<span className='cuadroActual__info__labelDato historic__label'>
													{cuadro.data?.colaboradores}
												</span>
											</label>
											<label className='cuadroActual__info__label historic__label'>
												Horas Contratadas iguales?{" "}
												<span className='cuadroActual__info__labelDato historic__label'>
													{cuadro.data?.sameHours ? "Si" : "No"}
												</span>
											</label>
										</div>
										<div className='cuadroActual__controls'>
											<button
												className='button-primary cuadroActual__btn'
												onClick={getCuadros}>
												Finalizado
											</button>

											<>
												<a
													href={cuadro.url}
													className='button-primary cuadroActual__btn'
													// onClick={() => handleDownload(cuadro.data?.carpeta, "historico")}
												>
													Descargar
												</a>
												<button
													className='button-secondary cuadroActual__btnEmail'
													onClick={()=>handleSendMail(cuadro.url)}>
													Enviar al correo
												</button>
											</>
										</div>
									</article>
								);
							})}
						</>
					) : (
						<img
							src={loadImg}
							alt='Vista previa de cuadro'
							className='loading__icon load'></img>
					)}
				</section>
			</main>
			{load && <Loading msg={message} />}
		</>
	);
}

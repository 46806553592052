import React, { useState, useContext } from "react";
import { AppContext } from "../Context/AppContext";
import Politics from "./Politics";

export default function FAQList() {
	const { currentList, setOpenModal, setModal } = useContext(AppContext);
	const [seeAns, setSeeAns] = useState(false);
	const [current, setCurrent] = useState(false);

	const handleClick = (i) => {
		setSeeAns(!seeAns);
		setCurrent(i);
	};

	return (
		<ul className='faq__list'>
			{currentList.map((faq, index) => {
				return (
					<li className='faq__item' key={index}>
						<div
							className={`faq__header ${index === current && "faq__header__active"} `}
							onClick={() => handleClick(index)}>
							<span className='faq__count'>{faq.count}</span>
							<h3 className='faq__question'>{faq.Question}</h3>
						</div>

						<p className={`faq__ans ${index === current && "faq__ans__appear"} `}>
							{faq.Ans}

							{faq.link === "Politics" ? (
								<span
									className='faq__info__link'
									onClick={() => {
										setOpenModal(true);
										setModal(<Politics />);
									}}>
									Politicas de uso Dasensy SAS
								</span>
							) : (
								faq.link === "wompi" && (
									<a
										rel='noreferrer'
										href='https://wompi.co/politica-de-privacidad/'
										className='faq__info__link'
										target='_blank'>
										Politicas de Wompi
									</a>
								)
							)}
						</p>
					</li>
				);
			})}
		</ul>
	);
}

import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../Context/AppContext";

export default function PasarelaPago(props) {
	const { infoLogin, openFactura, setOpenFactura, datosFactura, saveDatosFactura, redirect} =
		useContext(AppContext);
	const valorP = parseInt(props.valor);
	const reference = infoLogin.datosFactura?.reference;
	const wompiKey = process.env.REACT_APP_DASENSY_CUADROTURNOS_WOMPI_APIKEY_TEST
	// const key = process.env.REACT_APP_DASENSY_CUADROTURNOS_WOMPI_APIKEY
	// const hashBuffer = infoLogin.datosFactura.hashBuffer;
	const [phone, setPhone] = useState("");
	const handleOnChange = (event) => {
		setPhone(event.target.value);
	};
	const form = useRef(null);

	const handleSubmit = () => {

		const formData = new FormData(form.current);
		const direccion = formData.get("shipping-address:address-line-1");
		const razonSocial = formData.get("customer-data:full-name");
		const doc = formData.get("customer-data:legal-id");
		const email = formData.get("customer-data:email");
		const phoneNumber = formData.get("customer-data:phone-number");
		const tipodoc = formData.get("customer-data:legal-id-type");
		saveDatosFactura({
			...datosFactura,
			uid: infoLogin.uid,
			cantidad: 1,
			colombiano: true,
			razonSocial,
			tipodoc,
			doc,
			email,
			phoneNumber,
			direccion,
		});
	};

	return (
		<form
			action='https://checkout.wompi.co/p/'
			method='GET'
			className='wompi__form'
			ref={form}>
			{/* <!-- OBLIGATORIOS --> */}
			<input
				type='hidden'
				name='public-key'
				value={wompiKey}
				
			/>
			<input type='hidden' name='currency' value='COP' />
			<input type='hidden' name='amount-in-cents' value={`${valorP * 100}`} />
			<input type='hidden' name='reference' value={reference} />
			{/* <!-- OPCIONALES --> */}
			<div className='wompi__input__group'>
				<span className='wompi__input__label'>Nombre o Razon Social</span>
				<input
					require
					className='wompi__input'
					type='text'
					name='customer-data:full-name'
					placeholder='Nombres o Razon Social'
				/>
			</div>
			<div className='wompi__input__group'>
				<span className='wompi__input__label'>Tipo documento</span>
				<select name='customer-data:legal-id-type' className='wompi__input'>
					<option value='' disabled=''>
						Tipo
					</option>
					<option value='CC'>CC - Cédula de Ciudadanía</option>
					<option value='CE'>CE - Cédula de Extranjería</option>
					<option value='NIT'>NIT - Número de Identificación Tributaria</option>
					<option value='PP'>PP - Pasaporte</option>
					<option value='TI'>TI - Tarjeta de Identidad</option>
					<option value='DNI'>DNI - Documento Nacional de Identidad</option>
					<option value='RG'>RG - Carteira de Identidade / Registro Geral</option>
					<option value='OTHER'>Otro</option>
				</select>
			</div>
			<div className='wompi__input__group'>
				<span className='wompi__input__label'>Doc. Identidad</span>
				<input
					require
					className='wompi__input'
					type='text'
					name='customer-data:legal-id'
					placeholder='No. Documento'
				/>
			</div>
			<div className='wompi__input__group'>
				<span className='wompi__input__label'>Correo</span>
				<input
					className='wompi__input'
					type='email'
					name='customer-data:email'
					placeholder='Correo'
				/>
			</div>
			<div className='wompi__input__group'>
				<span className='wompi__input__label'>Dirección facturación</span>
				<input
					require
					className='wompi__input'
					type='text'
					name='shipping-address:address-line-1'
					placeholder='Dir. Facturación'
				/>
			</div>
			<div className='wompi__input__group'>
				<span className='wompi__input__label'>Telefono</span>
				<input
					require
					className='wompi__input'
					type='text'
					name='customer-data:phone-number'
					placeholder='ingresa tu tel'
					onChange={handleOnChange}
				/>
			</div>
			{/* <input require className="wompi__input" type="hidden" name="signature:integrity" value={hashBuffer}/> */}
			<input
				require
				className='wompi__input'
				type='hidden'
				name='redirect-url'
				// value='http://localhost:3000/dasensyusercuadroturnos'
				// value={`http://localhost:3000/${redirect}`}
				value={`https://dasensy.com/${redirect}`}
				// value={`https://dasensy-dev.firebaseapp.com/${redirect}`}
			/>
			<input
				require
				className='wompi__input'
				type='hidden'
				name='tax-in-cents:vat'
				value={`${valorP * 0.19 * 100}`}
			/>
			<input
				className='wompi__input'
				type='hidden'
				name='shipping-address:phone-number'
				value={phone}
			/>
			{/* <span className="wompi__input__label">País de envío</span>
      <span className="wompi__input__label">Ciudad de envío</span>
      <span className="wompi__input__label">Barrio</span> */}
			<input
				className='wompi__input'
				type='hidden'
				name='shipping-address:country'
				value={"CO"}
			/>
			<input
				className='wompi__input'
				type='hidden'
				name='shipping-address:city'
				value={"MED"}
			/>
			<input
				className='wompi__input'
				type='hidden'
				name='shipping-address:region'
				value={"MED"}
			/>
			{/* <input className="wompi__input" type="hidden" name="tax-in-cents:consumption" value="IMPOCONSUMO_EN_CENTAVOS" /> */}
			{/* <input className="wompi__input" type="hidden" name="customer-data:phone-number" value="NUMERO_DE_TELEFONO_DEL_PAGADOR" /> */}
			{/* <input className="wompi__input" type="hidden" name="customer-data:legal-id-type" value="TIPO_DEL_DOCUMENTO_DE_IDENTIDAD_DEL_PAGADOR" /> */}
			<div className='wompi__form__controls'>
				<button
					type='button'
					className='button-secondary wompi__btn'
					onClick={() => setOpenFactura(!openFactura)}>
					Cancelar
				</button>
				<button
					type='submit'
					className='button-primary wompi__btn'
					onClick={handleSubmit}>
					Pagar
				</button>
			</div>
		</form>
	);
}

import React from 'react'
import portadaImg from '../assets/img/consulting.png'

export default function Portada() {
    return (
        <React.Fragment>
            <div className="user__portada">

                <img src={portadaImg} className="user__portadaImg" alt="Portada Default"/>

                {/* <div className="user__uploadPortada">
                    <label for="savePortada" className="user__uploadBoxPortada">
                    <i className="fas fa-camera user__iconPortada"></i>
                    <span className="user__textPortada">Cambiar Imagen</span>
                    </label>
                    <form
                    action="/user/avatar"
                    method="post"
                    encType="multipart/form-data"
                    >
                    <input
                        type="file"
                        name="savePortada"
                        id="savePortada"
                        // onChange="form.submit()"
                        />
                    </form>
                </div> */}

            </div>
        </React.Fragment>
    )
}

import React, { useState, useEffect, useContext } from "react";
import { plansData } from "../assets/TiposPlanesData";
import { BsCheck2Circle, BsInfoCircleFill } from "react-icons/bs";
import { AppContext } from "../Context/AppContext";

export default function PreviewPlanList() {
	const { handleTRM, TRM, setFree, infoLogin } = useContext(AppContext);

	const IVA = 0.19;
	useEffect(() => {
		handleTRM();
	}, []);

	const handleChange = (plan) => {
		plan !== "Gratis" ? setFree(true) : setFree(false);
	}
	return plansData.map((plan) => {
		return (
			<>
				<div
					className={`preview__input-group ${
						(plan.precio !== "Gratis" || plan.precio !== 9.99) &&
						(infoLogin.userinfo?.subscription === "Urgencia" ||
							infoLogin.userinfo?.subscription === "Incrédulo")
							? null
							: "hidden"
					}`}>
					<label className='preview__input-group__label'>
						<input
							type='radio'
							name='planPago'
							className='preview__input-group__input'
							value={plan.precio}
							required
							onClick={()=>handleChange(plan.precio)}></input>
						<span className='preview__input-group__label__info'>
							<span className='preview__input-group__label__infoData'>
								{`${plan.precio}`}
								<span className='asterisco'>*</span>{" "}
								{plan.precio !== "Gratis" && plan.precio !== "Contáctanos"
									? " USD"
									: null}{" "}
								{plan.precio * TRM[0] ? (
									<div className='controls'>
										<BsInfoCircleFill className='price__infoIcon' />
										<div className='info'>
											<small className='info__value'>
												{new Intl.NumberFormat("de-DE").format(
													Math.round(plan.precio * TRM[0] + plan.precio * TRM[0] * IVA),
												)}
												{" COP"}
											</small>
											<small className='info__value small'>IVA incluido</small>
										</div>
									</div>
								) : null}
							</span>
							<small>No. Cuadros: {plan.cuadros}</small>
						</span>
					</label>
				</div>
			</>
		);
	});
}

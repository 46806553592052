export const faqData = {
    flujoTrabajo: [
        {
            count: "01",
            Question: "¿Cuáles son los días festivos?",
            Ans: "El sistema programa como festivos los sábados, domingos y feriados en Colombia",
        },
        {
            count: "02",
            Question: "¿Puedo personalizar la duración de los turnos?",
            Ans: "No. En el momento la duración en horas de los turnos es fija: 12 horas para las noches y los corridos, 10 horas para los turnos largos, 8 horas para tiempo completo, 6 horas para ronda en la mañana o en la tarde y 4 horas para medio tiempo.",
        },
        {
            count: "03",
            Question: "¿A qué se refiere \"Eventualidades\"?",
            Ans:"Durante el desarrollo de un cuadro de turnos se presentan condiciones en las cuales un colaborador trabaja según las necesidades del servicio. A esto se refiere nuestro sistema con Eventualidades, aquellos colaboradores que tendrán que estar disponibles ante una eventualidad. Por el momento, solo se consideran aquellas en fin de semana.",
        },
    ],
	privacidad: [
		{
			count: "04",
			Question: "¿Que hacen con mis datos?",
            Ans: "Sabemos lo valioso que son sus datos. Puede consultar nuestra política de privacidad en: ",
            link:"Politics",
		},
		{
			count: "05",
			Question: "¿Cuánto tiempo conservan mis cuadros de turnos? ",
            Ans: "Una vez se ha generado su cuadro de turnos en formato Excel, cuenta con 90 días calendario para descargarlo. Posterior a esto será borrado de nuestro sistema"
        },
	],
	grupo: [
		{
			count: "06",
			Question: "¿Para que es el nombre del grupo de trabajo?",
            Ans: "Algunos usuario tienen la necesidad de programar cuadro de turnos a varios grupos de personas. El nombre del grupo de trabajo tiene como finalidad reconocer cada uno de estos equipos para su registro y los nuestros.",
        },
		{
			count: "07",
			Question: "¿A que se refieren con contratación igual?",
            Ans: "Es usual que los profesionales de la salud en una institución tengan horas de contratación diferentes (algunos medio tiempo, otros tiempo completo, otros por horas). Se refiere contratación igual si todos los colaboradores o empleados que van a ser programados tienen un mismo tipo de contrato (por ejemplo, todos tienen contrato de tiempo completo).",
        },
	],
	pago: [
		{
			count: "08",
			Question: "¿Mis pagos son seguros?",
            Ans: "El sistema de pago electrónico en convenio está soportado por uno de los Bancos más grandes en Colombia (Bancolombia). Esta pasarela es segura y sus datos son protegidos según sus politicas que pueden consultar en: ",
            link:"wompi",
        },
		{
			count: "09",
			Question: "¿Me dan factura?",
            Ans:"Estamos obligados a facturación electrónica, después de su compra esta será enviada a su correo electrónico en máximo 24 horas.",
        },
	],
};

import React, {useContext, useState} from "react";
import { faqData } from "../assets/FAQData";
import { AppContext } from "../Context/AppContext";

export default function FAQMenu() {
    const { setCurrentList } = useContext(AppContext);
    const [selectList,setSelectList]= useState("flujoTrabajo");
    const handleClick = (faq) => {
        if (faq === "flujoTrabajo") {
            setCurrentList(faqData.flujoTrabajo)
            setSelectList("flujoTrabajo")
            
        }
        if (faq === "grupo") {
            setCurrentList(faqData.grupo)
            setSelectList("grupo")
        }
        if (faq === "privacidad") {
            setCurrentList(faqData.privacidad)
            setSelectList("privacidad")
        }
        if (faq === "pago") {
            setCurrentList(faqData.pago)
            setSelectList("pago")
        }
    }
	return (
		<ul className="faq__menu">
			<li className={`faq__menuList ${selectList === "flujoTrabajo" && "faq__menuList__active"} `} onClick={()=>handleClick("flujoTrabajo")}>Flujo de trabajo</li>
			<li className={`faq__menuList ${selectList === "privacidad" && "faq__menuList__active"} `} onClick={()=>handleClick("privacidad")}>Privacidad de la información</li>
			<li className={`faq__menuList ${selectList === "grupo" && "faq__menuList__active"} `} onClick={()=>handleClick("grupo")}>Grupo de trabajo</li>
			<li className={`faq__menuList ${selectList === "pago" && "faq__menuList__active"} `} onClick={()=>handleClick("pago")}>Pago</li>
		</ul>
	);
}

import React, { useContext, useEffect, useState } from "react";
import { IoShareSocial } from "react-icons/io5";
import { MdOutlineFacebook } from "react-icons/md";
import { BiMessageSquareEdit } from "react-icons/bi";
import { RiTwitterFill, RiLinkedinBoxFill, RiInstagramLine } from "react-icons/ri";
import { AppContext } from "../Context/AppContext";
import ModalsUserAccount from "../containers/ModalsUserAccount";
import Modaluserinfo from "./Modaluserinfo";
import ModalUserSocial from "./ModalUserSocial";
import Subscription from "./Subscription";
// import ModaluserSocial from '../modals/ModaluserSocial';

export default function UserInfo() {
	const { infoLogin, openModalUser, setOpenModalUser } = useContext(AppContext);
	const [social, setSocial] = useState(false);
	const [info, setInfo] = useState(false);

	useEffect(() => {
		if (openModalUser === false) {
			setSocial(false);
			setInfo(false);
		}
	}, [openModalUser]);

	return (
		<div className='user__info'>
			<div className='user__personalInfo'>
				<h2 className='user__name'>
					{infoLogin.userinfo !== ""
						? `${infoLogin.userinfo.firstName}  ${infoLogin.userinfo.lastName}`
						: "Nombre Usuario"}
				</h2>
			</div>

			<div
				className='user__edit'
				id='desplegarModalEdit'
				onClick={() => {
					setOpenModalUser(true);
					setInfo(true);
					setSocial(false);
				}}>
				<BiMessageSquareEdit className='user__iconEdit' />
				<span className='user__textEdit'>Editar Información</span>
			</div>

			<div className='user__infoAditional'>
				<div className='user__Fields w50'>
					<h3 className='user__titleField'>Fecha de nacimiento:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.birthDate
							: "fecha de nacimiento"}
					</small>
				</div>

				<div className='user__Fields w50'>
					<h3 className='user__titleField'>Sexo:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.gender
							: "Genero"}
					</small>
				</div>
				<div className='user__Fields w50'>
					<h3 className='user__titleField'>Nacionalidad:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.country
							: "Genero"}
					</small>
				</div>

				<div className='user__Fields w100'>
					<h3 className='user__titleField'>Correo:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== "" ? infoLogin.userinfo.email : "Correo"}
					</small>
				</div>

				<div className='user__Fields w100'>
					<h3 className='user__titleField'>Profesión:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.profession
							: "Profesión"}
					</small>
				</div>

				<div className='user__Fields w100'>
					<h3 className='user__titleField'>Tipo Institución donde labora:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.company
							: "Tipo Institución"}
					</small>
				</div>

				<div className='user__Fields w50'>
					<h3 className='user__titleField'>Tipo Personal a Cargo:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.employes
							: "Personal a Cargo"}
					</small>
				</div>
				<div className='user__Fields w50'>
					<h3 className='user__titleField'>Cantidad de Colaboradores</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.numberEmployes
							: "Colaboradores a Cargo"}
					</small>
				</div>

				<div className='user__Fields w50'>
					<h3 className='user__titleField'>Tipo de Atención:</h3>
					<small className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.typeCare
							: "Tipo de Atención"}
					</small>
				</div>

				<div className='user__Fields w100'>
					<h3 className='user__titleField'>Pacientes atendidos por mes</h3>
					<p className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.patients
							: "Pacientes por mes?"}
					</p>
				</div>
				<div className='user__Fields w100'>
					<h3 className='user__titleField'>Nivel de complejidad:</h3>
					<p className='user__valueField'>
						{infoLogin.userinfo !== ""
							? infoLogin.userinfo.secondaryInfo?.complex
							: "Nivel de complejidad"}
					</p>
				</div>
			</div>

			<Subscription/>

			<div className='user__social'>
				<h3 className='user__title w100'>
					<span className='user__title__content'>
						<IoShareSocial className='title__SocialIcon' />
						Mis Redes Sociales
					</span>
					<div
						className='social__edit'
						onClick={() => {
							setOpenModalUser(true);
							setSocial(true);
						}}>
						<BiMessageSquareEdit className='user__iconEdit' />
					</div>
				</h3>

				<a
					target='_blank'
					href={infoLogin.userinfo.sociales ? infoLogin.userinfo.sociales?.facebook : "#"}
					className={`user__linkSocial ${
						!infoLogin.userinfo.sociales?.facebook ? "disabled" : null
					} `}
					rel='noreferrer'>
					<MdOutlineFacebook className='menu__social__iconPerfil facebook' />
					<span className='user__textSocial'>Ir al perfil</span>
				</a>
				<a
					target='_blank'
					href={
						infoLogin.userinfo.sociales ? infoLogin.userinfo.sociales?.instagram : "#"
					}
					className={`user__linkSocial ${
						!infoLogin.userinfo.sociales?.instragram ? "disabled" : null
					} `}
					rel='noreferrer'>
					<RiInstagramLine className='menu__social__iconPerfil instagram' />
					<span className='user__textSocial'>Ir al perfil</span>
				</a>

				<a
					target='_blank'
					href={infoLogin.userinfo.sociales ? infoLogin.userinfo.sociales?.twitter : "#"}
					className={`user__linkSocial ${
						!infoLogin.userinfo.sociales?.twitter ? "disabled" : null
					} `}
					rel='noreferrer'>
					<RiTwitterFill className='menu__social__iconPerfil twitter' />
					<span className='user__textSocial'>Ir al perfil</span>
				</a>

				<a
					target='_blank'
					href={infoLogin.userinfo.sociales ? infoLogin.userinfo.sociales?.linkedin : "#"}
					className={`user__linkSocial ${
						!infoLogin.userinfo.sociales?.linkedin ? "disabled" : null
					} `}
					rel='noreferrer'>
					<RiLinkedinBoxFill className='menu__social__iconPerfil linkedin' />
					<span className='user__textSocial'>Ir al perfil</span>
				</a>
			</div>

			{openModalUser && (
				<ModalsUserAccount>
					{ info === true ?
					<Modaluserinfo />
                    :
                    social===true ? 
                        <ModalUserSocial/>
                    :null
                }
				</ModalsUserAccount>
			)}
		</div>
	);
}

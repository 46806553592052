import React, { useContext, useRef, useState } from "react";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import { AppContext } from "../Context/AppContext";
import FormTerms from "./FormTerms";

export default function SignUp() {
	const { userSignUp, openSignUp, setOpenSignUp, isError } = useContext(AppContext);

	const form = useRef(null);
	const [seepass, setSeepass] = useState(false);
	const [validError, setvalidError] = useState(false);
	const [valid, setvalid] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");

	const onChangePassword = (event) => {
		setPassword(event.target.value);
	};
	const onChangePassword2 = (event) => {
		setPassword2(event.target.value);
	};

	const expresiones = {
		firstname: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
		lastnames: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
		username: /^[a-zA-Z0-9_.+-]{4,16}$/,
		password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,16}$/,
		email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	};

	const validarformulario = (e) => {
		switch (e.target.name) {
			case "firstname":
				validacion(expresiones.firstname, e.target, "firstname");

				break;

			case "lastname":
				validacion(expresiones.lastnames, e.target, "lastname");

				break;
			case "email":
				validacion(expresiones.email, e.target, "email");

				break;
			case "password":
				validacion(expresiones.password, e.target, "password");

				break;

			default:
				break;
		}
	};

	const validacion = (expresion, input, campo) => {
		if (expresion.test(input.value)) {
			setvalidError(false);
			setvalid("");
		} else {
			setvalidError(true);
			setvalid(campo);
		}
	};

	const handleSubmit = (e) => {
		const formData = new FormData(form.current);
		const email = formData.get("email");
		const data = {
			firstName: formData.get("firstName"),
			lastName: formData.get("lastName"),
			email: formData.get("email"),
			password: formData.get("password"),
			checkTerms: formData.get("check-terms") && true,
		};
		formData.get("check-terms")
			? userSignUp(e, data, email)
			: alert("Por favor acepta los Terminos y Politicas de uso");
	};

	return (
		<div className='modalRegistro' id='modalRegistro'>
			<form className='modalRegistro__form' onSubmit={handleSubmit} ref={form}>
				<div className='form__input-box'>
					<label className='form__label'>
						<i className='form__icon fas fa-user '></i>
						Nombres
					</label>
					<input
						type='text'
						className='form__input'
						required
						id='firstname'
						name='firstName'
						onKeyUp={validarformulario}
						maxLength={30}
						max={100}
					/>
					{validError && valid === "firstname" ? (
						<p className='form__input-error'>El nombre solo puede contener letras.</p>
					) : null}
				</div>
				<div className='form__input-box'>
					<label className='form__label'>
						<i className='form__icon fas fa-user '></i>
						Apellidos
					</label>
					<input
						type='text'
						className='form__input'
						required
						id='lastname'
						name='lastName'
						onKeyUp={validarformulario}
						maxLength={30}
						max={100}
					/>
					{validError && valid === "lastname" ? (
						<p className='form__input-error'>
							Los apellidos solo pueden contener letras.
						</p>
					) : null}
				</div>
				<div className='form__input-box'>
					<label className='form__label'>
						<i className='form__icon fas fa-user '></i>
						Correo
					</label>
					<input
						type='email'
						className='form__input'
						required
						id='email'
						name='email'
						onKeyUp={validarformulario}
						maxLength={30}
						max={150}
					/>
					{validError && valid === "email" ? (
						<p className='form__input-error'>
							El email debe tener @ y solo puede contener letras, numeros, puntos, guiones
							y guion bajo.
						</p>
					) : null}
				</div>

				<div className='form__input-box'>
					<label className='form__label'>
						<i className='form__icon fas fa-lock'></i>
						Contraseña
					</label>
					<input
						type={seepass ? "text" : "password"}
						className='form__input'
						required
						id='password'
						name='password'
						onKeyUp={validarformulario}
						value={password}
						onChange={onChangePassword}
					/>
					{!seepass ? (
						<ImEye
							className='login__password__seeIcon'
							onClick={() => {
								setSeepass(!seepass);
							}}
						/>
					) : (
						<ImEyeBlocked
							className='login__password__seeIcon'
							onClick={() => {
								setSeepass(!seepass);
							}}
						/>
					)}
					{validError && valid === "password" ? (
						<p className='form__input-error'>
							La contraseña tiene que ser de 8 a 16 dígitos, contener una letra minuscula,
							una letra Mayuscula y un numero.
						</p>
					) : null}
				</div>
				<div className='form__input-box'>
					<label className='form__label'>
						<i className='form__icon fas fa-lock'></i>
						Corrobora tu Contraseña
					</label>
					<input
						type={seepass ? "text" : "password"}
						className='form__input'
						required
						id='password2'
						name='password2'
						value={password2}
						onChange={onChangePassword2}
					/>
					{!seepass ? (
						<ImEye
							className='login__password__seeIcon'
							onClick={() => {
								setSeepass(!seepass);
							}}
						/>
					) : (
						<ImEyeBlocked
							className='login__password__seeIcon'
							onClick={() => {
								setSeepass(!seepass);
							}}
						/>
					)}
					{password2 !== password ? (
						<p className='form__input-error'>Las contraseñas no coinciden.</p>
					) : null}
				</div>
				{isError !== "" && <div className='msg__error'>{isError}</div>}

				<FormTerms />

				<div className='btn-group'>
					<input type='submit' className='button form__btn' value='Registrarme' />
					<input
						type='button'
						className='button form__btn form__btn--cancel'
						value='Cancelar'
						onClick={() => setOpenSignUp(!openSignUp)}
					/>
				</div>
			</form>
		</div>
	);
}

import serviceImg from "../../assets/img/service.png";
import dataScienceImg from "../../assets/img/dataScience.png";
import consultingImg from "../../assets/img/consulting.png";
export const SliderData = [
	{
		service: "Cuadro de turnos",
		image: serviceImg,
		info: "A través de nuestro sistema de inteligencia artificial construiremos un cuadro de turnos según tus reglas y necesidades en minutos. Haz clic abajo para registrarte y comenzar",
		link: "/dasensycuadroturnos",
		button: "Ir a crear mi cuadro de turnos",
	},
	{
		service: "Consultoría en informática en salud",
		image: dataScienceImg,
		info: "La transformación tecnológica es indispensable hoy en día. Con nuestros especialistas recibe consultoría en transformación tecnologíca, evaluación de soluciones en telesalud, creación de software y otras herramientas para la transformación digital en tu servicio de atención en salud",
		link: "https://forms.gle/ZLSCzoXxnbks6mRB6",
		button: "Solicitar consultoría",
	},
	{
		service: "Ciencias de datos",
		image: consultingImg,
		info: "Con el uso de tecnologías en la nube acompañamos el proceso de captura, transformación y presentación de datos de su empresa para que puedan ser información, conocimiento y sabiduria en el cumplimiento de la misión y visión de su compañía.",
		link: "https://forms.gle/ZLSCzoXxnbks6mRB6",
		button: "Solicitar servicio de ciencia de datos",
	},
];

import React, { useContext, useEffect, useRef, useState } from "react";
import { RiCloseCircleLine, RiTimeFill } from "react-icons/ri";
import { AppContext } from "../Context/AppContext";
import { FaRunning } from "react-icons/fa";
import { RiMoonClearFill } from "react-icons/ri";
import { GiBlackKnightHelm } from "react-icons/gi";
import {
	BsFillSunriseFill,
	BsSunFill,
	BsFillClockFill,
	BsFillPersonBadgeFill,
} from "react-icons/bs";

const list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const listIncredulo = [0, 1, 2, 3, 4, 5];

export default function FlujoTrabajo() {
	const {
		setNextModal,
		tCuadros,
		saveTCuadros,
		infoLogin,
		isError,
		setIsError,
		editModal,
		setEditModal,
		// addWorkFlow
	} = useContext(AppContext);
	const form = useRef(null);
	const flowDatos = tCuadros.flujoTrabajo;

	const [medicoDisponible, setMedicoDisponible] = useState();
	const [isSame, setIsSame] = useState(false);
	const [listNcolaborators, setlistNcolaborators] = useState([]);

	useEffect(() => {
		infoLogin.userinfo?.subscription === "Incrédulo"
			? setlistNcolaborators(listIncredulo)
			: setlistNcolaborators(list);
	}, [infoLogin.userinfo?.subscription]);

	const handleChange = (e) => {
		setMedicoDisponible(e.target.value);
	};

	useEffect(() => {
		setTimeout(() => {
			setIsError("");
		}, 8000);
	}, [isError]);

	const handleSubmit = (e) => {
		e.preventDefault();
		// const idCuadro = tCuadros.caracteristicas?.idCuadro;
		const createdAt = tCuadros.caracteristicas?.createdAt;
		const nombreEquipo = tCuadros.caracteristicas?.nombreEquipo;
		const formData = new FormData(form.current);
		const numberCorridosHabil = parseInt(formData.get("corridosHabil"));
		const numberFullTimeHabil = parseInt(formData.get("completaHabil"));
		const numberMananaHabil = parseInt(formData.get("mananaHabil"));
		const numberTardeHabil = parseInt(formData.get("tardeHabil"));
		const numberNocheHabil = parseInt(formData.get("nocheHabil"));
		const numberTenHourHabil = parseInt(formData.get("tenHourHabil"));
		const numberPartTimeHabil = parseInt(formData.get("medioTiempoHabil"));

		if (isSame === false) {
			var numberCorridosHoliday = parseInt(formData.get("corridosWkd"));
			var numberFullTimeHoliday = parseInt(formData.get("completaWkd"));
			var numberMananaHoliday = parseInt(formData.get("mananaWkd"));
			var numberTardeHoliday = parseInt(formData.get("tardeWkd"));
			var numberNocheHoliday = parseInt(formData.get("nocheWkd"));
			var numberTenHourHoliday = parseInt(formData.get("tenHourWkd"));
			var numberPartTimeHoliday = parseInt(formData.get("medioTiempoWkd"));
		} else {
			numberCorridosHoliday = numberCorridosHabil;
			numberFullTimeHoliday = numberFullTimeHabil;
			numberMananaHoliday = numberMananaHabil;
			numberTardeHoliday = numberTardeHabil;
			numberNocheHoliday = numberNocheHabil;
			numberTenHourHoliday = numberTenHourHabil;
			numberPartTimeHoliday = numberPartTimeHabil;
		}

		const numberOnCallHoliday =
			formData.get("personaOnCall") === "si" ? parseInt(formData.get("onCall")) : 0;
		// const sum = numberCorridosHabil + numberCorridosHoliday + numberFullTimeHabil + numberFullTimeHoliday + numberMananaHabil + numberMananaHoliday + numberNocheHabil + numberNocheHoliday + numberOnCallHoliday + numberPartTimeHabil + numberPartTimeHoliday + numberTardeHabil + numberTardeHoliday
		const sum =
			numberCorridosHabil +
			numberFullTimeHabil +
			numberMananaHabil +
			numberNocheHabil +
			numberPartTimeHabil +
			numberTenHourHabil +
			numberTardeHabil;

		if (tCuadros.caracteristicas?.total >= sum) {
			const data = {
				uid: infoLogin.uid,
				createdAt,
				idCuadro: tCuadros.caracteristicas?.idCuadro,
				nombreEquipo,
				numberCorridosHabil,
				numberCorridosHoliday,
				numberFullTimeHabil,
				numberFullTimeHoliday,
				numberMananaHabil,
				numberMananaHoliday,
				numberTardeHabil,
				numberTardeHoliday,
				numberNocheHabil,
				numberNocheHoliday,
				numberOnCallHoliday,
				numberPartTimeHabil,
				numberPartTimeHoliday,
				numberTenHourHabil,
				numberTenHourHoliday,
				sameNumberHoliday: formData.get("sameHabil") ? true : false,
				subs: infoLogin.userinfo?.subscription
			};
			saveTCuadros({ ...tCuadros, flujoTrabajo: data });
			// addWorkFlow(e, data)
			setNextModal(editModal ? "confirm" : "restricciones");
			setEditModal(false);
		} else {
			setIsError(
				`Por favor revisa los datos ingresados, ${
					sum > tCuadros.caracteristicas?.total
						? "te sobra algún colaborador asignado"
						: "te falta algún colaborador por asignar"
				} en contratación`,
			);
		}
	};

	return (
		<form className='flujo' ref={form} onSubmit={handleSubmit}>
			<h2 className='flujo__title'>
				Ahora conozcamos el flujo de trabajo
				<RiCloseCircleLine
					className='flujo__closeIcon'
					onClick={() => setNextModal("")}
				/>
			</h2>

			<h3 className='flujo__subtitle'>En dias Hábiles</h3>
			<label className='flujo__label'>¿Cuántas personas debo:</label>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<FaRunning className='flujo__Icon' />
					asignar a corridos (12 horas)?
				</label>
				<select required className='flujo__input-group__input' name='corridosHabil'>
					<option
						className='flujo__input-group__option'
						value={flowDatos?.numberCorridosHabil ? flowDatos?.numberCorridosHabil : 0}>
						{flowDatos?.numberCorridosHabil ? flowDatos?.numberCorridosHabil : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='flujo__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>

			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<RiMoonClearFill className='flujo__Icon' />
					asignar a la noche (12 horas)?
				</label>
				<select required className='flujo__input-group__input' name='nocheHabil'>
					<option
						className='flujo__input-group__option'
						value={flowDatos?.numberNocheHabil ? flowDatos?.numberNocheHabil : 0}>
						{flowDatos?.numberNocheHabil ? flowDatos?.numberNocheHabil : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='flujo__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>

			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<BsFillPersonBadgeFill className='flujo__Icon' />
					asignar turno de 10 horas?
				</label>
				<select required className='flujo__input-group__input' name='tenHourHabil'>
					<option
						className='flujo__input-group__option'
						value={flowDatos?.numberTenHourHabil ? flowDatos?.numberTenHourHabil : 0}>
						{flowDatos?.numberTenHourHabil ? flowDatos?.numberTenHourHabil : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='flujo__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>

			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<BsFillClockFill className='flujo__Icon' />
					asignar una jornada completa (8 horas)?
				</label>
				<select required className='flujo__input-group__input' name='completaHabil'>
					<option
						className='flujo__input-group__option'
						value={flowDatos?.numberFullTimeHabil ? flowDatos?.numberFullTimeHabil : 0}>
						{flowDatos?.numberFullTimeHabil ? flowDatos?.numberFullTimeHabil : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='flujo__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>

			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<BsFillSunriseFill className='flujo__Icon' />
					asignar media jornada en la mañana (6 horas)?
				</label>
				<select required className='flujo__input-group__input' name='mananaHabil'>
					<option
						className='flujo__input-group__option'
						value={flowDatos?.numberMananaHabil ? flowDatos?.numberMananaHabil : 0}>
						{flowDatos?.numberMananaHabil ? flowDatos?.numberMananaHabil : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='flujo__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<BsSunFill className='flujo__Icon' />
					asignar media jornada en la tarde (6 horas)?
				</label>
				<select required className='flujo__input-group__input' name='tardeHabil'>
					<option
						className='flujo__input-group__option'
						value={flowDatos?.numberTardeHabil ? flowDatos?.numberTardeHabil : 0}>
						{flowDatos?.numberTardeHabil ? flowDatos?.numberTardeHabil : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='flujo__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<RiTimeFill className='flujo__Icon' />
					asignar medio tiempo en la mañana (4 horas)?
				</label>
				<select required className='flujo__input-group__input' name='medioTiempoHabil'>
					<option
						className='flujo__input-group__option'
						value={flowDatos?.numberPartTimeHabil ? flowDatos?.numberPartTimeHabil : 0}>
						{flowDatos?.numberPartTimeHabil ? flowDatos?.numberPartTimeHabil : 0}
					</option>

					{listNcolaborators.map((item, index) => {
						return (
							<option
								key={index}
								className='flujo__input-group__option'
								value={`${item}`}>
								{item}
							</option>
						);
					})}
				</select>
			</div>

			{/* Flujo de trabajo en fines de semana */}

			<h3 className='flujo__subtitle'>En fin de semana o festivos</h3>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					Es la misma configuración que en días habiles?
					<input
						className='flujo__input-group__checkbox'
						type='checkbox'
						name='sameHabil'
						onClick={() => setIsSame(!isSame)}></input>
				</label>
			</div>

			{!isSame && (
				<>
					<label className='flujo__label'>¿Cuántas personas debo:</label>
					<div className='flujo__input-group'>
						<label className='flujo__input-group__label'>
							<FaRunning className='flujo__Icon' />
							asignar a corridos (12 horas)?
						</label>
						<select required className='flujo__input-group__input' name='corridosWkd'>
							<option
								className='flujo__input-group__option'
								value={
									flowDatos?.numberCorridosHoliday ? flowDatos?.numberCorridosHoliday : 0
								}>
								{flowDatos?.numberCorridosHoliday ? flowDatos?.numberCorridosHoliday : 0}
							</option>

							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='flujo__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div className='flujo__input-group'>
						<label className='flujo__input-group__label'>
							<RiMoonClearFill className='flujo__Icon' />
							asignar a la noche (12 horas)?
						</label>
						<select required className='flujo__input-group__input' name='nocheWkd'>
							<option
								className='flujo__input-group__option'
								value={flowDatos?.numberNocheHoliday ? flowDatos?.numberNocheHoliday : 0}>
								{flowDatos?.numberNocheHoliday ? flowDatos?.numberNocheHoliday : 0}
							</option>

							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='flujo__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>

					<div className='flujo__input-group'>
						<label className='flujo__input-group__label'>
							<BsFillPersonBadgeFill className='flujo__Icon' />
							asignar a turno de 10 horas?
						</label>
						<select required className='flujo__input-group__input' name='tenHourWkd'>
							<option
								className='flujo__input-group__option'
								value={
									flowDatos?.numberTenHourHoliday ? flowDatos?.numberTenHourHoliday : 0
								}>
								{flowDatos?.numberTenHourHoliday ? flowDatos?.numberTenHourHoliday : 0}
							</option>

							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='flujo__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>

					<div className='flujo__input-group'>
						<label className='flujo__input-group__label'>
							<BsFillClockFill className='flujo__Icon' />
							asignar una jornada completa (8 horas)?
						</label>
						<select required className='flujo__input-group__input' name='completaWkd'>
							<option
								className='flujo__input-group__option'
								value={
									flowDatos?.numberFullTimeHoliday ? flowDatos?.numberFullTimeHoliday : 0
								}>
								{flowDatos?.numberFullTimeHoliday ? flowDatos?.numberFullTimeHoliday : 0}
							</option>

							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='flujo__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>

					<div className='flujo__input-group'>
						<label className='flujo__input-group__label'>
							<BsFillSunriseFill className='flujo__Icon' />
							asignar media jornada en la mañana (6 horas)?
						</label>
						<select required className='flujo__input-group__input' name='mananaWkd'>
							<option
								className='flujo__input-group__option'
								value={
									flowDatos?.numberMananaHoliday ? flowDatos?.numberMananaHoliday : 0
								}>
								{flowDatos?.numberMananaHoliday ? flowDatos?.numberMananaHoliday : 0}
							</option>

							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='flujo__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div className='flujo__input-group'>
						<label className='flujo__input-group__label'>
							<BsSunFill className='flujo__Icon' />
							asignar media jornada en la tarde (6 horas)?
						</label>
						<select required className='flujo__input-group__input' name='tardeWkd'>
							<option
								className='flujo__input-group__option'
								value={flowDatos?.numberTardeHoliday ? flowDatos?.numberTardeHoliday : 0}>
								{flowDatos?.numberTardeHoliday ? flowDatos?.numberMananaHoliday : 0}
							</option>

							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='flujo__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<div className='flujo__input-group'>
						<label className='flujo__input-group__label'>
							<RiTimeFill className='flujo__Icon' />
							asignar medio tiempo en la mañana (4 horas)?
						</label>
						<select required className='flujo__input-group__input' name='medioTiempoWkd'>
							<option
								className='flujo__input-group__option'
								value={
									flowDatos?.numberPartTimeHoliday ? flowDatos?.numberPartTimeHoliday : 0
								}>
								{flowDatos?.numberPartTimeHoliday ? flowDatos?.numberPartTimeHoliday : 0}
							</option>

							{listNcolaborators.map((item, index) => {
								return (
									<option
										key={index}
										className='flujo__input-group__option'
										value={`${item}`}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
				</>
			)}

			{/* Flujo de trabajo en caso de medico disponibles */}

			<h3 className='flujo__subtitle'>Eventualidades</h3>

			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					Deben haber colaboradores disponibles en fin de semana o festivos?
				</label>
				<select
					required
					className='flujo__input-group__input'
					name='personaOnCall'
					onChange={handleChange}>
					<option className='flujo__input-group__option' value={""}>
						Seleccione ...
					</option>
					<option className='flujo__input-group__option' value={"si"}>
						Si
					</option>
					<option className='flujo__input-group__option' value={"no"}>
						No
					</option>
				</select>
			</div>

			{medicoDisponible === "si" && (
				<div className='flujo__input-group'>
					<label className='flujo__input-group__label'>
						<GiBlackKnightHelm className='flujo__Icon' />
						¿Cuántos Colaboradores deben estar en disponibles?
						<input
							type='number'
							id='onCall'
							name='onCall'
							className='flujo__input-group__input'
							required
							min='0'
							max='360'
							maxLength={3}
							defaultValue={
								flowDatos?.numberOnCallHoliday ? flowDatos?.numberOnCallHoliday : 0
							}
						/>
					</label>
				</div>
			)}
			{isError !== "" && <div className='msg__error__workflow'>{isError}</div>}
			<div className='flujo__btn'>
				{!editModal && (
					<button
						className='button-secondary separacionBtn'
						onClick={() => setNextModal("caracteristicas")}>
						Atras
					</button>
				)}
				<button className='button-primary'>Continuar</button>
			</div>
		</form>
	);
}

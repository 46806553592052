import React, { useContext, useEffect, useState } from "react";
import NavBar from "./NavBar";
import Footer from "../components/Footer";
import { AppContext } from "../Context/AppContext";
import { Modals } from "./Modals";
import Loading from "../components/Loading";
import { useLocation } from "react-router-dom";

export default function Layout({ children }) {
	const { openModal, modal } = useContext(AppContext);
	const location = useLocation();
	const [load, setLoad] = useState(true);

	useEffect(() => {
		setLoad(true);
		const timer = Math.round(Math.random() * 500);
		setTimeout(() => {
			setLoad(false);
		}, timer);
	}, [location]);
	return (
		<div className='Layout'>
			<NavBar />
			{children}
			<Footer />
			{load && <Loading />}
			{openModal && <Modals>{modal}</Modals>}
		</div>
	);
}

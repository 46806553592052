import React, { useContext, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import NavList from "./NavList";
import NavListAboutUs from "./NavListAboutUs";
import { BiLogIn } from "react-icons/bi";

import Login from "../Login";
import { AppContext } from "../../Context/AppContext";
import SignUp from "../SignUp";

import Welcome from "../Welcome";
import Submenu from "./Submenu";
import ResetPassword from "../ResetPassword";

export default function NavItem({ open }) {
	const {
		login,
		setLogin,
		setOpenMenu,
		openSignUp,
		infoLogin,
		welcomeMsg,
		setWelcomeMsg,
		logConfig,
		setSubmenu,
		submenu,resetPassword
	} = useContext(AppContext);

	window.addEventListener("scroll", () => {
		setSubmenu("");
	});

	useEffect(() => {
		infoLogin.uid && setWelcomeMsg(true);
	}, [infoLogin.uid]);

	const location = useLocation();

	const path = ["/","/aboutus", "/ourplans", "/useracount"]
	const [Mode, setMode]= useState("")

	useEffect(() => {
		if (path.includes(location.pathname)) {
			setMode("")
		} else {
			setMode("menu__list__linkDark")
		}
		
	}, [location]);

	return (
		<>
			<ul className={`${open ? "menu__item" : "menu__close"} menu__largeScreen`}>
				{location.pathname !== "/aboutus" ? <NavList /> : <NavListAboutUs />}

				{!infoLogin.uid ? (
					<li
						className='menu__list'
						onClick={() => {
							setLogin(!login);
							setOpenMenu(false);
						}}>
						<div className={`menu__list__link ${Mode}`}>
							<span className='login__label'>Ingresa</span>
							<BiLogIn className='login__icon' />
						</div>
					</li>
				) : (
					<li className='menu__list'>
						<div className={`menu__list__link ${Mode}`} onClick={() => setSubmenu("userAcount")}>
							<span className='login__label'>
								Hola,
								{` ${
									infoLogin.userinfo?.firstName
										? infoLogin.userinfo?.firstName
										: infoLogin.userinfo?.email
								}`}
							</span>
						</div>
						{submenu === "userAcount" ? <Submenu /> : null}
					</li>
				)}
			</ul>
			{login && <Login />}
			{resetPassword && <ResetPassword />}
			{openSignUp && <SignUp />}
			{welcomeMsg && logConfig.startMsg === false && <Welcome />}
		</>
	);
}

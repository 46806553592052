import React, { useContext, useRef, useState, useEffect } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { AppContext } from "../Context/AppContext";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file calendar
import "react-date-range/dist/theme/default.css"; // theme css file calendar
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi";

const listClasificacion = [
	"Vacaciones",
	"Licencia",
	"Incapacidad",
	"Educación",
	"Ocio",
	"Personal",
	"Otro",
];
export default function Restricciones() {
	const myObj = {};
	const myObj2 = {};
	const {
		setNextModal,
		infoLogin,
		saveTCuadros,
		tCuadros,
		editModal,
		isError,
		setIsError,
		// addRestrictions
	} = useContext(AppContext);

	const [fechaTCuadro, setFechaTCuadro] = useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 30),
			key: "selection",
		},
	]);
	const [fechaTCuadro2, setFechaTCuadro2] = useState({
		selection1: {
			startDate: new Date(),
			endDate: new Date(),
			key: "selection1",
		},
	});
	const [fechaTCuadro3, setFechaTCuadro3] = useState({
		selection1: {
			startDate: new Date(),
			endDate: new Date(),
			key: "selection1",
		},
	});

	const form = useRef(null);

	const [fecharestringida, setfecharestringida] = useState();
	const [listasignDates, setlistasignDates] = useState([1]);
	const [listrestrictedDates, setlistrestrictedDates] = useState([1]);
	const [primercheck, setprimercheck] = useState(false);
	const [segundocheck, setsegundocheck] = useState(false);
	const [tercercheck, settercercheck] = useState(false);

	const handleOnChangeA = (data) => {
		const index = Object.keys(data).toString().charAt(9);
		const index10 = Object.keys(data).toString().charAt(10);
		const i = listasignDates.length >= 10 ? index + index10 : index;
		console.log(i);
		let foo = "selection" + i;
		const startDate = data[foo].startDate;
		const endDate = data[foo].endDate;
		const key = "selection" + i;
		myObj[foo] = { startDate, endDate, key };
		const select = myObj;
		setFechaTCuadro2({ ...fechaTCuadro2, ...select });
	};

	const handleAsignDates = () => {
		setlistasignDates([...listasignDates, listasignDates.length + 1]);
		let foo = "selection" + (listasignDates.length + 1);
		const key = "selection" + (listasignDates.length + 1);

		myObj[foo] = {
			startDate: new Date(),
			endDate: new Date(),
			key: key,
		};

		setFechaTCuadro2({ ...fechaTCuadro2, ...myObj });
	};

	const handleAsignDatesMinus = () => {
		listasignDates.pop();

		let foo = "selection" + (listasignDates.length + 1);

		delete fechaTCuadro2[foo];

		setFechaTCuadro2({ ...fechaTCuadro2, ...myObj });
	};

	const handleOnChangeR = (data) => {
		const formData = new FormData(form.current);
		const index = Object.keys(data).toString().charAt(9);
		const index10 = Object.keys(data).toString().charAt(10);
		const i = listasignDates.length >= 10 ? index + index10 : index;
		const Motivo = formData.getAll("motivoR")[index - 1];
		const assignEqualTo = formData.getAll("assignEqualTo")[index - 1];
		let foo = "selection" + i;
		const startDate = data[foo].startDate;
		const endDate = data[foo].endDate;
		const key = "selection" + i;
		myObj[foo] = { startDate, endDate, key, assignEqualTo, Motivo };
		const select = myObj;
		setFechaTCuadro3({ ...fechaTCuadro3, ...select });
	};

	const handleRestrictedDatesMinus = () => {
		listrestrictedDates.pop();

		let foo = "selection" + (listrestrictedDates.length + 1);

		delete fechaTCuadro3[foo];

		setFechaTCuadro3({ ...fechaTCuadro3, ...myObj2 });
	};

	const handleRestrictedDates = () => {
		setlistrestrictedDates([...listrestrictedDates, listrestrictedDates.length + 1]);
		let foo = "selection" + (listrestrictedDates.length + 1);
		const key = "selection" + (listrestrictedDates.length + 1);

		myObj2[foo] = {
			startDate: new Date(),
			endDate: new Date(),
			key: key,
		};

		setFechaTCuadro3({ ...fechaTCuadro3, ...myObj2 });
	};

	const handleChange = (e) => {
		setfecharestringida(e.target.value);
	};

	useEffect(() => {
		setTimeout(() => {
			setIsError("");
		}, 9999);
	}, [isError]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const createdAt = tCuadros.caracteristicas?.createdAt;
		const nombreEquipo = tCuadros.caracteristicas?.nombreEquipo;
		const formData = new FormData(form.current);
		const rest = formData.get("restrictedDates");
		const restrictweekend = formData.get("restrictweekend") === "on" ? true : false;
		const restrictNightshift = primercheck
			? parseInt(formData.get("restrictNightshift"))
			: "No";
		const restricthours = segundocheck ? parseInt(formData.get("restricthours")) : "No";
		const restrictcorridos = tercercheck
			? parseInt(formData.get("restrictcorridos"))
			: "No";

		if (rest === "si") {
			var data = {
				uid: infoLogin.uid,
				createdAt,
				idCuadro: tCuadros.caracteristicas?.idCuadro,
				nombreEquipo,
				fechaTCuadro: fechaTCuadro[0],
				assignDates: fechaTCuadro2,
				restrictedDates: fechaTCuadro3,
				subs: infoLogin.userinfo?.subscription,
				restrictweekend,
				restrictcorridos: tercercheck
					? parseInt(formData.get("restrictcorridos"))
					: false,
				restricthours: segundocheck ? parseInt(formData.get("restricthours")) : false,
				restrictNightshift: primercheck
					? parseInt(formData.get("restrictNightshift"))
					: false,
			};
		} else {
			data = {
				uid: infoLogin.uid,
				createdAt,
				idCuadro: tCuadros.caracteristicas?.idCuadro,
				nombreEquipo,
				fechaTCuadro: fechaTCuadro[0],
				assignDates: {},
				restrictedDates: {},
				subs: infoLogin.userinfo?.subscription,
				restrictweekend,
				restrictcorridos: tercercheck
					? parseInt(formData.get("restrictcorridos"))
					: false,
				restricthours: segundocheck ? parseInt(formData.get("restricthours")) : false,
				restrictNightshift: primercheck
					? parseInt(formData.get("restrictNightshift"))
					: false,
			};
		}

		if (primercheck && tercercheck) {
			if (
				tCuadros.flujoTrabajo?.numberNocheHabil * 7 <=
					tCuadros.caracteristicas?.total * restrictNightshift &&
				tCuadros.flujoTrabajo?.numberCorridosHabil * 7 <=
					tCuadros.caracteristicas?.total * restrictcorridos
			) {
				saveTCuadros({ ...tCuadros, restricciones: data });
				setNextModal("confirm");
			} else {
				setIsError(
					`Por favor revisa los datos ingresados en las restricciones generales, 
					con la configuración actual no es posible crear el cuadro`,
				);
			}
		} else if (primercheck && !tercercheck) {
			if (
				tCuadros.flujoTrabajo?.numberNocheHabil * 7 <=
				tCuadros.caracteristicas?.total * restrictNightshift
			) {
				saveTCuadros({ ...tCuadros, restricciones: data });
				setNextModal("confirm");
			} else {
				setIsError(
					`Por favor revisa los datos ingresados en las restricciones generales, 
					con la configuración actual no es posible crear el cuadro`,
				);
			}
		} else if (!primercheck && tercercheck) {
			if (
				tCuadros.flujoTrabajo?.numberCorridosHabil * 7 <=
				tCuadros.caracteristicas?.total * restrictcorridos
			) {
				saveTCuadros({ ...tCuadros, restricciones: data });
				setNextModal("confirm");
			} else {
				setIsError(
					`Por favor revisa los datos ingresados en las restricciones generales, 
					con la configuración actual no es posible crear el cuadro`,
				);
			}
		} else {
			saveTCuadros({ ...tCuadros, restricciones: data });
			setNextModal("confirm");
		}
	};

	return (
		<form className='restriction' onSubmit={handleSubmit} ref={form}>
			<h2 className='restriction__title'>
				Que Restricciones tienen?
				<RiCloseCircleLine
					className='restriction__closeIcon'
					onClick={() => setNextModal("")}
				/>
			</h2>
			<h3 className='restriction__subtitle'>
				Elige la fecha de inicio y fin del Cuadro de Turno
			</h3>
			<div className='restriction__input-group calendar__group'>
				<DateRange
					onChange={(item) => setFechaTCuadro([item.selection])}
					showSelectionPreview={true}
					editableDateInputs={true}
					moveRangeOnFirstSelection={false}
					dragSelectionEnabled={true}
					months={1}
					scroll={{ enabled: true }}
					ranges={fechaTCuadro}
					direction='vertical'
					className='calendar1'
					rangeColors={["#3CB8B4"]}
				/>
			</div>
			{infoLogin.userinfo?.subscription !== "Incrédulo" && (
				<>
					<h3 className='restriction__subtitle'>Eventualidades</h3>

					<div className='restriction__input-group'>
						<label className='restriction__input-group__label'>
							Definir fechas específicas para programar el cuadro de turnos?
						</label>
						<select
							required
							className='restriction__input-group__input'
							name='restrictedDates'
							onChange={handleChange}>
							<option className='restriction__input-group__option' value={""}>
								Seleccione ...
							</option>
							<option className='restriction__input-group__option' value={"si"}>
								Si
							</option>
							<option className='restriction__input-group__option' value={"no"}>
								No
							</option>
						</select>
					</div>
				</>
			)}

			{fecharestringida === "si" && (
				<>
					<h4 className='restriction__subtitle'>
						Asignar estas fechas (Programar fechas)
					</h4>
					<label className='restriction__input-group__label addRestriction'>
						<span className='restriction__input-group__text'>
							Eliminar Asignación
							<BiMinusCircle
								className='restriction__addIcon'
								onClick={() => handleAsignDatesMinus()}
							/>
						</span>
						<span className='restriction__input-group__text'>
							Agregar Asignación
							<BiPlusCircle className='restriction__addIcon' onClick={handleAsignDates} />
						</span>
					</label>

					<div className='restriction__input-group asignDates'>
						<label className='restriction__input-group__label colab'>Colaborador</label>
						<label className='restriction__input-group__label fechasR'>
							Fechas a Programar
						</label>

						<div className='restriction__input-group calendar__group2'>
							<div className='calendar__CollistA'>
								{listasignDates.map((item, index) => {
									return <label className='calendar__label'>A{index + 1}</label>;
								})}
							</div>
							<div
								className='calendar__container'
								style={{ height: `${50 * (listasignDates.length + 1)}px` }}>
								<DateRange
									onChange={(item) => handleOnChangeA(item)}
									showSelectionPreview={true}
									editableDateInputs={true}
									moveRangeOnFirstSelection={false}
									retainEndDateOnFirstSelection={false}
									dragSelectionEnabled={true}
									months={1}
									scroll={{ enabled: true }}
									ranges={Object.values(fechaTCuadro2)}
									direction='vertical'
									className='calendar'
									rangeColors={[
										"#3CB8B4",
										"#3d91ff",
										"#d63031",
										"#41DD9B",
										"#fed14c",
										"#e41749",
										"#013a63",
										"#e47b19",
										"#900048",
										"#283149",
									]}
								/>
							</div>
						</div>
					</div>

					<h4 className='restriction__subtitle'>
						Respetar estas fechas (Restringir fechas)
					</h4>
					<label className='restriction__input-group__label addRestriction'>
						<span className='restriction__input-group__text'>
							Eliminar Restricción
							<BiMinusCircle
								className='restriction__addIcon'
								onClick={() => handleRestrictedDatesMinus()}
							/>
						</span>
						<span className='restriction__input-group__text'>
							Agregar Restricción
							<BiPlusCircle
								className='restriction__addIcon'
								onClick={handleRestrictedDates}
							/>
						</span>
					</label>
					<div className='restriction__input-group restrictedDates'>
						<>
							<div className='restriction__title-group'>
								<label className='restriction__input-group__label colab'>Colab.</label>
								<label className='restriction__input-group__label Motivo equalTo'>
									Igual a
								</label>
								<label className='restriction__input-group__label Motivo'>Motivo</label>

								<label className='restriction__input-group__label fechasR'>
									Fechas a Restringir
								</label>
							</div>
							<div className='restriction__input-group calendar__group3'>
								<div className='calendar__CollistR'>
									{listrestrictedDates.map((item, index) => {
										return (
											<>
												<label className='calendar__label'>R{index + 1}</label>
												<select
													required
													className='restriction__input-group__input select'
													name={`assignEqualTo`}>
													<option
														key={index}
														className='restriction__input-group__option'
														value={"No"}>
														No
													</option>
													{listasignDates.map((item, index) => {
														return (
															<option
																key={index}
																className='restriction__input-group__option'
																value={`A${index + 1}`}>
																A{index + 1}
															</option>
														);
													})}
												</select>
												<select
													required
													className='restriction__input-group__input select'
													name={`motivoR`}>
													{listClasificacion.map((item, index) => {
														return (
															<option
																key={index}
																className='restriction__input-group__option'
																value={`${item}`}>
																{item}
															</option>
														);
													})}
												</select>
											</>
										);
									})}
								</div>

								<div
									className='calendar__container'
									style={{ height: `${50 * (listrestrictedDates.length + 1)}px` }}>
									<DateRange
										onChange={(item) => handleOnChangeR(item)}
										showSelectionPreview={true}
										editableDateInputs={true}
										moveRangeOnFirstSelection={false}
										dragSelectionEnabled={true}
										months={1}
										scroll={{ enabled: true }}
										ranges={Object.values(fechaTCuadro3)}
										direction='vertical'
										className='calendar'
										rangeColors={[
											"#3CB8B4",
											"#3d91ff",
											"#d63031",
											"#41DD9B",
											"#fed14c",
											"#e41749",
											"#013a63",
											"#e47b19",
											"#900048",
											"#283149",
										]}
									/>
								</div>
							</div>
						</>
					</div>
				</>
			)}

			<h3 className='restriction__subtitle'>Restricciones Generales</h3>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					Programar dos fines de semana consecutivos.
					<input
						className='flujo__input-group__checkbox'
						type='checkbox'
						name='restrictweekend'></input>
				</label>
			</div>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<input
						className='restriction__input-group__checkbox'
						type='checkbox'
						onClick={() => setprimercheck(!primercheck)}></input>
					Limitar a {/* {primercheck && */}
					<input
						className='restriction__input-group__general'
						type='number'
						name='restrictNightshift'
						placeholder='0'
						disabled={primercheck === true ? false : true}
						required></input>
					{/* } */}
					turnos nocturnos a la semana por colaborador.
				</label>
			</div>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<input
						className='restriction__input-group__checkbox'
						type='checkbox'
						onClick={() => setsegundocheck(!segundocheck)}></input>
					Limitar a
					<input
						className='restriction__input-group__general'
						type='number'
						name='restricthours'
						placeholder='0'
						disabled={segundocheck === true ? false : true}
						required></input>
					horas a la semana por colaborador.
				</label>
			</div>
			<div className='flujo__input-group'>
				<label className='flujo__input-group__label'>
					<input
						className='restriction__input-group__checkbox'
						type='checkbox'
						onClick={() => settercercheck(!tercercheck)}></input>
					Evitar más de
					<input
						className='restriction__input-group__general'
						type='number'
						name='restrictcorridos'
						placeholder='0'
						disabled={tercercheck === true ? false : true}
						required></input>
					turnos de 12 horas diurnas a la semana.
				</label>
			</div>
			{isError !== "" && <div className='msg__error__restrict'>{isError}</div>}

			<div className='restriction__btn'>
				{!editModal && (
					<button
						className='button-secondary separacionBtn'
						onClick={() => setNextModal("flujo")}>
						Atras
					</button>
				)}
				<button className='button-primary'>Continuar</button>
			</div>
		</form>
	);
}
